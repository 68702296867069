<template>
	<router-link :to="`/projects/${getLink(project?.id)}`" class="card">
		<div v-if="background" class="background">
			<img :src="background" />
		</div>
		<div
			v-else-if="project.tasks.length == 0"
			class="tasks-wrapper no-tasks"
		>
			<p class="desc">Здесь будет выводиться список задач на сегодня</p>
		</div>
		<div v-else class="tasks-wrapper">
			<ul class="tasks">
				<li
					v-for="task of project.tasks"
					:key="task"
					:class="
						color(
							moment(task.date_from).format('DD.MM.YYYY'),
							moment(task.date_to).format('DD.MM.YYYY'),
							format
						)
					"
					class="task"
				>
					{{ task.title }}
				</li>
			</ul>
		</div>
		<div class="info">
			<ProjectDates
				:date-from="project.date_from"
				:date-to="project.date_to"
			/>
			<ProjectMembers
				v-if="project.users && project.users !== null"
				:members="project.users"
				:reverse="true"
			/>
		</div>
		<div class="card-bottom">
			<p class="project-name">{{ project.name }}</p>
			<FavoriteButton
				:is-favorite="project.is_favorite"
				@favorites-action="favoritesAction"
			/>
		</div>
	</router-link>
</template>

<script>
import ProjectMembers from '@/common/components/project/ProjectMembers'
import ProjectDates from '@/common/components/project/ProjectDates'
import FavoriteButton from '@/common/components/parts/FavoriteButton'
import { dateClass } from '@/common/helpers'
import moment from 'moment'
export default {
	components: {
		ProjectMembers,
		ProjectDates,
		FavoriteButton,
	},
	props: {
		project: {
			type: Object,
			default: null,
		},
		background: {
			type: String || Boolean,
			default: false,
		},
	},
	emits: ['filter'],
	data: () => {
		return {
			moment: moment,
			format: 'DD.MM.YYYY',
			color: dateClass,
		}
	},
	computed: {
		taskClass() {
			return dateClass(
				this.project.dateFrom,
				this.project.dateTo,
				this.format
			)
		},
	},
	methods: {
		favoritesAction() {
			this.$emit('filter', this.project?.id)
		},
		getLink(id) {
			// id = id.toString()
			// return btoa(id)
			return id
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.background {
	width: calc(100% + 2.4rem);
	margin-left: -1.2rem;
	margin-top: -1.2rem;
	overflow: hidden;
	padding-top: 50%;
	position: relative;
	margin-bottom: auto;
	min-height: 17.6rem;
	margin-bottom: 1.2rem;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.swiper-slide {
	.card:hover {
		transform: none;
		box-shadow: unset;
	}
}
.card {
	overflow: hidden;
	border-radius: 1.6rem;
	border: 1px solid $grey-border;
	padding: 1.2rem;
	min-height: 25.6rem;
	display: flex;
	flex-direction: column;
	background: $white;
	position: relative;
	z-index: 1;
	border: 1px solid #e9e9e9;
	// transition: 0.15s;
	// transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	//will-change: transform;
	&:hover {
		//will-change: transform;
		transform: scale(1.015) translate(-0.4rem, -0.4rem);
		box-shadow: 0.4rem 0.4rem 1rem rgba(0, 0, 0, 0.05);
	}

	transition: 0.3s ease-out;
	// &:hover{
	//     transform: scale(1.025) translate(-8px, -8px);
	//     box-shadow: 8px 8px 10px rgba(0, 0, 0, .05);
	// }
}

.add-project {
	justify-content: center;
	align-items: center;
	text-align: center;

	.desc {
		color: $dark-grey;
		font-size: 1.6rem;
		line-height: 2rem;
	}
}

.tasks-wrapper {
	flex-grow: 2;
	padding-left: 2rem;
	padding-top: 2rem;
	padding-bottom: 1.6rem;
	position: relative;
	max-height: 17.6rem;
	min-height: 17.6rem;
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		pointer-events: none;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 6.4rem;
		z-index: 1;
		background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.75) 100%
			),
			linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
	}

	&.no-tasks {
		padding-top: 0.8rem;
		padding-left: 0.8rem;
	}

	.desc {
		color: $grey2;
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
}

.tasks {
	overflow-y: scroll;
	max-height: 13.6rem;
	padding-bottom: 2rem;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-thumb {
		border-color: transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb:hover {
		border-color: transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-width: 0;
	}

	&::-moz-scrollbar {
		width: 0;
	}
}

.task {
	position: relative;
	padding-left: 2rem;
	color: $black;
	font-size: 1.4rem;
	line-height: 1.8rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(:last-of-type) {
		margin-bottom: 1.6rem;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0.2rem;
		left: 0;
		width: 1.2rem;
		height: 1.2rem;
		border-radius: 100%;
		border: 1px solid transparent;
	}

	&.red::before {
		border-color: $red-border;
		background-color: $red-back;
	}
	&.yellow::before {
		border-color: $yellow-border;
		background-color: $yellow-back;
	}
	&.green::before {
		border-color: $green-border;
		background-color: $green-back;
	}
	&.grey::before {
		border-color: $dark-grey;
		background-color: $grey;
	}
}

.info,
.card-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.project-name {
	font-size: 1.6rem;
	line-height: 2rem;
	color: black;
	padding-right: 1.6rem;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.info {
	margin-bottom: 1.4rem;
	padding: 0;
	&::before {
		display: none;
	}
}

.preview {
	.desc {
		font-size: 1.2rem;
	}

	.tasks-wrapper {
		min-height: auto;
	}

	.card {
		min-height: 23.6rem;
	}

	.background {
		min-height: auto;
		//margin-bottom: auto;
	}
}
</style>
