<template>
	<button
		:class="isFavorite ? 'active' : ''"
		aria-label="В избранное"
		type="button"
		class="favorites"
		@click.prevent="$emit('favoritesAction')"
	>
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 1.66675L12.575 6.88341L18.3334 7.72508L14.1667 11.7834L15.15 17.5167L10 14.8084L4.85002 17.5167L5.83335 11.7834L1.66669 7.72508L7.42502 6.88341L10 1.66675Z"
				stroke="#C4C4C4"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</button>
</template>

<script>
export default {
	props: {
		isFavorite: Boolean,
	},
	emits: ['favoritesAction'],
}
</script>

<style lang="scss" scoped>
.favorites {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 2rem;
	position: relative;
	z-index: 2;

	path {
		width: 100%;
		height: auto;
	}

	&:hover:not(.active) {
		path {
			stroke: #a3a3a3;
		}
	}

	&.active {
		path {
			stroke: #ffd359;
			fill: #ffd359;
		}
	}
}
</style>
