<template>
	<a href="#" class="data-wrapper" @click="editProfile">
		<div class="avatar">
			<div
				:style="
					user !== null
						? `background: ${
								getRandomEmoji(user.email, user.id).gradient
						  }`
						: ''
				"
				class="image-wrap"
			>
				<span v-if="user == null" class="plug">?</span>
				<img v-else-if="userAvatar" :src="userAvatar" alt="Аватар" />
				<div
					v-else
					style="
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					{{ getRandomEmoji(user.email, user.id).emoji }}
				</div>
				<!-- <img v-else src="@/assets/img/icons/avatar.svg" class="default" alt="Аватар"> -->
			</div>
		</div>
		<div v-if="!onlyAvatar" class="name">{{ userName }}</div>
	</a>
	<router-link
		v-if="user == null && !onlyAvatar"
		to="/"
		style="margin-left: 2.25rem"
		class="button green"
		>Войти</router-link
	>
</template>

<script>
import { SET_ENTITY } from '@/store/mutation-types'
import { getRandomEmoji } from '@/common/helpers'
import { mapState } from 'vuex'

export default {
	props: {
		onlyAvatar: Boolean,
	},
	data: () => {
		return {
			getRandomEmoji: getRandomEmoji,
		}
	},
	computed: {
		...mapState('Auth', ['user']),
		userName() {
			return this.user == null
				? 'Anonymus'
				: this.user.name
				? this.user.name
				: this.user.email
		},
		userAvatar() {
			return this.user?.image ? this.user.image : false
		},
	},
	methods: {
		editProfile() {
			if (this.user == null) {
				this.$router.push('/')
				return
			}
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'activeModal', value: 'profile' },
				{ root: true }
			)
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: true },
				{ root: true }
			)
		},
	},
}
</script>

<style lang="scss" scoped>
.data-wrapper {
	display: flex;
	align-items: center;
}
.avatar {
	width: 3.2rem;
	height: 3.2rem;
	min-width: 3.2rem;
	min-height: 3.2rem;
	overflow: hidden;
	border-radius: 100%;
	border: 2px solid #00d47d;
	padding: 2px;
	position: relative;
}

.name {
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: #282828;
	margin-left: 0.8rem;
}

.image-wrap {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 100%;
	position: relative;
	background-color: #f8f8f8;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #00d47d;
	font-size: 1.6rem;
	font-weight: bold;

	img:not(.default) {
		width: 100%;
		height: 100%;
		min-width: 100%;
		min-height: 100%;
	}

	.default {
		width: 1.8rem;
		max-width: 1.8rem;
	}
}
</style>
