import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/LoginPage'
import Reset from '@/views/ResetPage'
import Privacy from '@/views/PolicyPage'
import { getCookie } from '@/common/helpers'
import TaskList from '@/views/TaskListPage'
import Dashboard from '@/views/ProjectsPage'
import SingleProject from '@/views/SingleProject'
import AppLayoutMain from '@/layouts/AppLayoutMain'
import AppLayoutText from '@/layouts/AppLayoutText'
import AppLayoutSingle from '@/layouts/AppLayoutSingle'

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
	},
	{
		path: '/reset',
		name: 'Reset',
		component: Reset,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			layout: AppLayoutMain,
		},
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: Privacy,
		meta: {
			layout: AppLayoutText,
		},
	},
	{
		path: `/projects/:projectId`,
		name: 'SingleProject',
		component: SingleProject,
		meta: {
			layout: AppLayoutSingle,
		},
	},
	{
		path: `/:token`,
		name: 'ProjectByToken',
		component: SingleProject,
		meta: {
			layout: AppLayoutSingle,
		},
	},
	{
		path: `/projects/:projectId/task-list`,
		name: 'TaskListPage',
		component: TaskList,
		meta: {
			layout: AppLayoutMain,
		},
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
})

router.beforeEach(async (to) => {
	let accessData = getCookie('access_data')
	if (to.name == 'Login' && accessData !== undefined && accessData.token) {
		return '/dashboard'
	}
	if (to.name == 'Reset' && !to.query.token) {
		return '/'
	}
	if (
		to.name == 'Dashboard' &&
		(accessData == undefined || accessData == '""')
	) {
		return '/'
	}
})

export default router
