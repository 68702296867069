<template>
	<div class="dropdown-wrapper">
		<input
			v-model="searchQuery"
			:placeholder="searchPlaceholder"
			type="text"
			:class="showDropdown ? 'open' : ''"
			class="input dropdown-input"
			@click.stop.prevent="showDropdown = !showDropdown"
		/>
		<ul v-show="showDropdown" class="dropdown-container">
			<li
				v-for="option in filteredOptions"
				:key="option.value"
				:class="selectedOption === option.value ? 'active' : ''"
				class="option"
				@click="changeOption(option.value ? option : null)"
			>
				{{ option.label }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			type: Array,
			default: null,
		},
		searchPlaceholder: {
			type: String,
			default: null,
		},
		defaultValue: {
			type: [String, Number],
			default: null,
		},
		clear: Boolean,
	},
	emits: ['changeOption'],
	data: () => {
		return {
			showDropdown: false,
			selectedOption: '',
			searchQuery: '',
		}
	},
	computed: {
		filteredOptions() {
			const results = this.searchQuery
				? this.options.filter((el) =>
						el.label
							.toLowerCase()
							.includes(this.searchQuery.toLowerCase())
				  )
				: [...this.options]
			return results
		},
	},
	watch: {
		options(val) {
			if (val?.length && this.defaultValue) {
				const option = this.options.find(
					(el) => el.value === this.defaultValue
				)
				this.selectedOption = option ? option.label : ''
				this.searchQuery = option.label
			}
		},
	},
	mounted() {
		document.addEventListener('click', this.handleClickaway)
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleClickaway)
	},
	methods: {
		changeOption(option) {
			if (option) {
				this.selectedOption = option.label
				this.searchQuery = option.label
				this.$emit('changeOption', option.value)
			} else {
				this.selectedOption = ''
				this.$emit('changeOption', null)
			}

			setTimeout(() => {
				this.showDropdown = false
			}, 50)

			if (this.clear) this.searchQuery = ''
		},
		handleClickaway(e) {
			if (!this.$el.contains(e.target)) {
				this.showDropdown = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.dropdown-input-inner {
	display: block;
	background: transparent;
	cursor: pointer;
	padding: 1rem 1.2rem;
	width: 95%;
	font-size: 1.2rem;
	border: 1px solid #c4c4c4;
	border-radius: 8px;
	margin: 1rem auto;
}
.dropdown-wrapper {
	position: relative;
}
.dropdown-container {
	position: absolute;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 0.8rem;
	background-color: $white;
	box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);
	z-index: 5;

	overflow: auto;
	max-height: 20rem;
}

.dropdown-input {
	position: relative;
	font-size: 1.2rem;
	height: 4rem;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 1.2rem;
		width: 1.6rem;
		height: 1.6rem;
		background: url('~@/assets/img/icons/arrow-down.svg') center center
			no-repeat;
		transform: translateY(-50%);
		transition: 0.2s ease-out;
	}

	&.open::before {
		transform: translateY(-50%) rotate(180deg);
	}
}

.option {
	width: 100%;
	text-align: left;
	padding: 1.2rem 1.6rem;
	color: black;
	font-size: 1.4rem;
	line-height: 2rem;
	cursor: pointer;
	&:hover:not(.active) {
		background-color: $grey;
	}

	&:not(:last-of-type) {
		border-bottom: 1px solid $grey;
	}

	&.active {
		background-color: $grey-hover2;
	}
}
</style>
