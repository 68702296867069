<template>
	<InputTitle v-model="title" />
	<InputRow :label="'Описание'">
		<textarea
			v-model="description"
			class="input"
			type="text"
			rows="3"
			placeholder="Описание проекта"
		/>
	</InputRow>
	<RelatedDates
		:start="startDate"
		:end="endDate"
		@set-start="setStart"
		@set-end="setEnd"
		@set-duration="setDuration"
		@set-period="setPeriod"
	/>
	<div class="input-row">
		<span class="label">Цветовая схема проекта</span>
		<div class="colors-wrapper">
			<label
				v-for="(value, name) of scheme"
				:key="name"
				:class="currentColor == name ? 'active' : ''"
				class="radio-label"
			>
				<div
					:style="`background-color:${value[2]}`"
					class="radio-item"
				></div>
				<input
					v-model="currentColor"
					:value="name"
					name="color-scheme"
					type="radio"
				/>
			</label>
		</div>
	</div>
	<InputRow :label="'Обложка проекта'">
		<div class="preview">
			<button
				type="button"
				class="delete"
				@click=";(deleteBack = true), (background = false)"
			>
				<img
					src="@/assets/img/icons/delete.svg"
					class="icon"
					alt="Удалить"
				/>
			</button>
			<div class="card-wrap">
				<ProjectCard
					:background="
						background
							? background
							: single && !deleteBack
							? single.background
							: false
					"
					:project="project"
					@filter="filterArray"
				/>
			</div>
			<button type="button" class="add" @click="triggerFile">
				<img
					src="@/assets/img/icons/edit-white.svg"
					class="icon"
					alt="Редактировать"
				/>
			</button>
		</div>
		<input ref="cardBack" type="file" hidden @change="changeImage" />
	</InputRow>
	<button
		:class="loading ? 'loading' : ''"
		type="button"
		class="button green"
		@click="update ? updateProject() : addProject()"
	>
		Сохранить
	</button>
	<button
		v-if="update"
		style="margin-top: 1.6rem; margin-bottom: 1.6rem"
		:class="loading ? 'loading' : ''"
		type="button"
		class="button grey"
		@click="duplicateProject"
	>
		Дублировать
	</button>
	<button
		v-if="update"
		style="margin-top: auto"
		:class="loading ? 'loading' : ''"
		type="button"
		class="button red"
		@click="showNotice = true"
	>
		Удалить
	</button>
	<div v-if="showNotice" class="notice">
		Вы действительно хотите удалить этот проект?
		<div class="buttons">
			<button type="button" class="button red" @click="deleteProject">
				Да
			</button>
			<button
				type="button"
				class="button grey"
				@click="showNotice = false"
			>
				Нет
			</button>
		</div>
	</div>
</template>

<script>
import RelatedDates from '@/common/components/parts/RelatedDates'
import InputTitle from '@/common/components/inputs/InputTitle'
import InputRow from '@/common/components/inputs/InputRow'
import ProjectCard from '@/modules/dashboard/ProjectCard'
import colors from '@/static/color-scheme.json'
import moment from 'moment'
import { ref } from 'vue'
import { mapState } from 'vuex'

export default {
	components: {
		InputTitle,
		InputRow,
		RelatedDates,
		ProjectCard,
	},
	props: {
		update: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['closeModal'],
	data: () => {
		return {
			showNotice: false,
			startDate: ref(new Date()),
			endDate: moment().add(1, 'months'),
			title: '',
			description: '',
			stopCalculate: false,
			currentPeriod: '',
			durationValue: '',
			scheme: colors,
			currentColor: 'pink',
			loading: false,
			successNotice: '',
			background: false,
			deleteBack: false,
		}
	},
	computed: {
		...mapState('Projects', ['errorProject', 'created', 'single']),
		...mapState('Auth', ['user']),
		project() {
			return {
				tasks: [],
				date_from: this.startDate,
				date_to: this.endDate,
				users: [this.user],
				name: this.title == '' ? 'Название проекта' : this.title,
			}
		},
	},

	watch: {
		errorProject(newVal) {
			newVal
				? () => (
						(this.loading = false),
						this.$toast.error(
							`Произошла ошибка, повторите попытку позже`
						)
				  )
				: ''
		},
	},

	created() {
		if (this.update) {
			this.startDate = this.single.date_from
			this.endDate = this.single.date_to
			this.title = this.single.name
			this.description = this.single.description
				? this.single.description
				: ''
			this.currentColor = this.single.color
		}
	},

	unmounted() {
		this.background = false
	},
	methods: {
		addProject() {
			this.loading = true
			let body = new FormData()
			if (this.background) {
				body.append('background', this.$refs.cardBack.files[0])
				this.deleteBack = false
			}
			body.append('name', this.title !== '' ? this.title : 'Новый проект')
			body.append('description', this.description)
			body.append('date_from', moment(this.startDate).format())
			body.append('date_to', moment(this.endDate).format())
			body.append(
				'minute_count',
				this.calculateMinutes(this.currentPeriod, this.durationValue)
			)
			body.append('color', this.currentColor)

			this.successNotice = 'Проект создан успешно!'
			this.$store
				.dispatch('Projects/addProject', body)
				.then(() => {
					this.loading = false
					this.$toast.success(this.successNotice)
					this.$emit('closeModal', true)
					document.querySelector('body').style.overflow = 'unset'
				})
				.catch(() => {
					this.loading = false
					this.$toast.error('Что-то пошло не так')
				})
		},
		updateProject() {
			this.loading = true
			let data = {}
			data.id = this.$route.params.projectId
				? this.$route.params.projectId
				: this.single.id

			if (this.background) {
				let bodyBackground = new FormData()
				bodyBackground.append('file', this.$refs.cardBack.files[0])
				let backData = {
					id: this.$route.params.projectId
						? this.$route.params.projectId
						: this.single.id,
					data: bodyBackground,
				}
				this.$store
					.dispatch('Projects/uploadBackground', backData)
					.catch(() => {
						this.$toast.error(
							'Обложка проекта не обновлена. Слишком тяжёлая картинка'
						)
					})
				this.deleteBack = false
			}

			if (this.deleteBack)
				this.$store.dispatch(
					'Projects/deleteBackground',
					this.$route.params.projectId
						? this.$route.params.projectId
						: this.single.id
				)

			data.body = {
				name: this.title !== '' ? this.title : 'Новый проект',
				description: this.description,
				date_from: this.startDate,
				date_to: this.endDate,
				minute_count: this.calculateMinutes(
					this.currentPeriod,
					this.durationValue
				),
				color: this.currentColor,
			}
			this.successNotice = 'Проект обновлён успешно!'
			this.$store
				.dispatch('Projects/updateProject', data)
				.then(() => {
					this.loading = false
					this.$toast.success(this.successNotice)
					this.$emit('closeModal', true)
					document.querySelector('body').style.overflow = 'unset'
					this.$store.dispatch(
						'Projects/getSingleProject',
						this.$route.params.projectId
							? this.$route.params.projectId
							: this.single.id
					)
				})
				.catch(() => {
					this.loading = false
					this.$toast.error('Что-то пошло не так')
				})
		},
		duplicateProject() {
			let id = this.$route.params.projectId
				? this.$route.params.projectId
				: this.single.id
			this.successNotice = 'Проект продублирован'
			this.$store.dispatch('Projects/duplicateProject', id).then(() => {
				this.$toast.success(this.successNotice)
				this.$emit('closeModal', true)
				document.querySelector('body').style.overflow = 'unset'
			})
		},
		deleteProject() {
			let id = this.$route.params.projectId
				? this.$route.params.projectId
				: this.single.id
			this.successNotice = 'Проект удалён'
			this.$store.dispatch('Projects/deleteProject', id).then(() => {
				this.$toast.success(this.successNotice)
				this.$emit('closeModal', true)
				document.querySelector('body').style.overflow = 'unset'
			})
		},
		setStart(val) {
			this.startDate = val
		},
		setEnd(val) {
			this.endDate = val
		},
		setDuration(val) {
			this.durationValue = val
		},
		setPeriod(val) {
			this.currentPeriod = val
		},

		calculateMinutes(period, count) {
			switch (period) {
				case 'workDays':
					return Number(count) * 1440
				case 'hours':
					return Number(count) * 60
				default:
					return Number(count) * 1440
			}
		},
		triggerFile() {
			this.$refs.cardBack.value = ''
			this.$refs.cardBack.click()
		},
		changeImage() {
			let file = this.$refs.cardBack.files[0]
			const FILE_TYPES = ['gif', 'jpg', 'jpeg', 'png']
			const fileName = file.name.toLowerCase()
			const matches = FILE_TYPES.some((it) => fileName.endsWith(it))
			if (matches) {
				const reader = new FileReader()

				reader.addEventListener('load', () => {
					this.background = reader.result
				})
				reader.readAsDataURL(file)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';

.buttons {
	display: flex;
	margin-top: 1.6rem;
	gap: 1.6rem;
	// justify-content: flex-start;
	// .button{

	// }
}
.notice {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: $white;
	padding: 2.4rem;
	font-size: 2rem;
	line-height: 2.4rem;
	color: $black;
	border-radius: 2rem;
}

.input-group {
	display: grid;
	grid-gap: 1.6rem;
	grid-template-columns: repeat(2, 1fr);
}

.radio-label {
	width: 2.8rem;
	height: 2.8rem;
	min-width: 2.8rem;
	border: 2px solid $grey;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
	transition: 0.1s ease-out;

	&.active {
		border-color: $green;
	}

	input {
		opacity: 0;
		position: absolute;
		pointer-events: none;
	}
}

.radio-item {
	width: 2rem;
	height: 2rem;
	min-width: 2rem;
	border-radius: 100%;
	pointer-events: none;
}

.colors-wrapper {
	display: flex;
	gap: 0.4rem;
	flex-wrap: wrap;
	margin-top: 0.4rem;
}

.preview {
	position: relative;
	overflow: hidden;
	border-radius: 1.6rem;

	.card-wrap {
		pointer-events: none;
	}

	&:hover {
		.delete,
		.add {
			opacity: 1;
			pointer-events: all;
		}

		.delete {
			top: 0;
			z-index: 2;
		}

		.add {
			bottom: 0;
		}
	}
}

.delete,
.add {
	pointer-events: none;
	transition: 0.3s ease-out;
	position: absolute;
	left: 0;
	width: 100%;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.3);
	z-index: 1;

	img {
		height: 1.6rem;
	}
}

.delete {
	top: -100%;
}

.add {
	bottom: -100%;
}
</style>
