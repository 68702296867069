<template>
	<div :class="{ reverse: reverse, vertical: vertical }" class="members">
		<div
			v-for="(member, index) of members.length - translateMember.rest"
			v-show="index <= 3"
			:key="index"
			:style="`transform: ${
				!vertical ? translateMember.percents[index] : 'none'
			}; background: ${
				getRandomEmoji(
					members[index]?.user?.email
						? members[index]?.user?.email
						: members[index]?.email
						? members[index]?.email
						: 'anonymus',
					members[index]?.user?.id
						? members[index]?.user?.id
						: members[index]?.id
						? members[index]?.id
						: 0
				).gradient
			};`"
			class="member"
		>
			<div
				v-if="
					members[index]?.user?.image == null &&
					members[index]?.image == null
				"
				style="
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				"
			>
				{{
					getRandomEmoji(
						members[index]?.user?.email
							? members[index]?.user?.email
							: members[index]?.email
							? members[index]?.email
							: 'anonymus',
						members[index]?.user?.id
							? members[index]?.user?.id
							: members[index]?.id
							? members[index]?.id
							: 0
					).emoji
				}}
			</div>
			<!-- <img v-if="members[index]?.user?.image == null && members[index]?.image == null" src="@/assets/img/icons/avatar.svg"> -->
			<img
				v-else
				:src="members[index]?.user?.image || members[index]?.image"
			/>
		</div>
		<div
			v-if="translateMember.rest > 1"
			:style="`transform: ${
				!vertical
					? translateMember.percents[
							translateMember.percents.length - 1
					  ]
					: 'none'
			}`"
			class="member rest"
			@click="showMembers = !showMembers"
		>
			+{{ translateMember.rest }}
		</div>
		<div
			v-if="translateMember.rest > 1 && showMembers"
			class="members-popup"
		>
			<div
				v-for="(member, index) of members"
				:key="index"
				class="member"
				:style="`background: ${
					getRandomEmoji(
						members[index]?.user?.email
							? members[index]?.user?.email
							: members[index]?.email
							? members[index]?.email
							: 'anonymus',
						members[index]?.user?.id
							? members[index]?.user?.id
							: members[index]?.id
							? members[index]?.id
							: 0
					).gradient
				}`"
			>
				<div
					v-if="
						members[index]?.user?.image == null &&
						members[index]?.image == null
					"
					style="height: 100%; display: flex; justify-content: center; align-items: center;s"
				>
					{{
						getRandomEmoji(
							members[index]?.user?.email
								? members[index]?.user?.email
								: members[index]?.email
								? members[index]?.email
								: 'anonymus',
							members[index]?.user?.id
								? members[index]?.user?.id
								: members[index]?.id
								? members[index]?.id
								: 0
						).emoji
					}}
				</div>
				<!-- <img v-if="member?.user?.image !== null" :src="member?.user?.image" alt=""> -->
				<img
					v-else
					:src="members[index]?.user?.image || members[index]?.image"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { getRandomEmoji } from '@/common/helpers'
export default {
	props: {
		members: {
			type: Array,
			default: null,
		},
		reverse: Boolean,
		vertical: Boolean,
	},
	data: () => {
		return {
			translateStep: 30,
			showMembers: false,
			getRandomEmoji: getRandomEmoji,
		}
	},
	computed: {
		translateMember() {
			let members = {}
			let percents = []
			let rest = this.members.length > 4 ? 1 : 0
			this.members.forEach((el, i) => {
				i <= 3
					? percents.push(
							`translateX(${this.reverse ? '' : '-'}${
								i * this.translateStep
							}%)`
					  )
					: (rest += 1)
			})

			members['percents'] = this.reverse ? percents.reverse() : percents
			members['rest'] = rest

			return members
		},
	},
	mounted() {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.members {
	display: flex;
	justify-content: flex-start;
	width: 11.5rem;
	position: relative;

	&.reverse {
		justify-content: flex-end;
	}

	&.vertical {
		flex-direction: column;

		.member {
			margin: 0.4rem 0;
		}
	}
}

.member {
	overflow: hidden;
	position: relative;
	border: 2px solid $white;
	border-radius: 100%;
	width: 2.8rem;
	height: 2.8rem;
	min-width: 2.8rem;
	background-color: $grey;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		// width: 55%;
		// height: 55%;
	}

	&.rest {
		background-color: $light-green;
		color: $green;
		font-size: 1rem;
		line-height: 1.2rem;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.members-popup {
	position: absolute;
	top: calc(100% + 1.6rem);
	left: 0;
	z-index: 2;
	pointer-events: none;
	background-color: $white;
	padding: 0.8rem;
	border-radius: 0.8rem;
	box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 0.2rem;

	&::before {
		position: absolute;
		content: '';
		width: 1rem;
		height: 1rem;
		left: 50%;
		bottom: calc(100% - 0.5rem);
		background-color: $white;
		transform: translateX(-50%) rotate(45deg);
		z-index: 3;
	}
}
</style>
