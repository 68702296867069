<template>
	<!-- <transition> -->
	<div class="modal-side">
		<div class="modal-head">
			<p class="title">{{ title }}</p>
			<button class="close" type="button" @click="closeModal">
				<img src="@/assets/img/icons/plus.svg" />
			</button>
		</div>
		<div class="modal-content">
			<slot />
		</div>
	</div>
	<!-- </transition> -->
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: null,
		},
	},
	emits: ['closeModal'],
	methods: {
		closeModal() {
			this.$emit('closeModal', true)
			document.querySelector('body').style.overflow = 'unset'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.modal-side {
	height: 100%;
	width: 40rem;
	position: fixed;
	right: 0;
	top: 0;
	background-color: $white;
	z-index: 25;
	overflow: auto;
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&::-webkit-scrollbar {
		width: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		border-color: transparent;
		background-color: $dark-grey;
	}

	&::-webkit-scrollbar-thumb:hover {
		border-color: transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-width: 0;
	}

	&::-moz-scrollbar {
		width: 0.2rem;
	}
}

.modal-head {
	background-color: $grey;
	padding: 1.6rem 1.2rem 1.4rem 3.6rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.close {
	width: 3.2rem;
	height: 3.2rem;
	transform: rotate(45deg);
	img {
		width: 100%;
		height: 100%;
	}
}

.title {
	font-size: 1.8rem;
	line-height: 2.3rem;
	font-weight: 500;
	color: $black;
}
</style>
