<template>
	<component :is="layout">
		<slot />
	</component>
</template>

<script>
import { shallowRef } from 'vue'
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'

export default {
	name: 'AppLayout',
	data() {
		return {
			defaultLayout: shallowRef(AppLayoutDefault),
		}
	},
	computed: {
		layout() {
			const layout = this.$route.meta.layout
				? this.$route.meta.layout
				: this.defaultLayout

			return layout
		},
	},
}
</script>
