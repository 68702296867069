import modules from '@/store/modules/'
import Vuex from 'vuex'
import { SET_ENTITY, ADD_ENTITY } from '@/store/mutation-types'

export const mutations = {
	[SET_ENTITY](state, { module, entity, value }) {
		module ? (state[module][entity] = value) : (state[entity] = value)
	},
	[ADD_ENTITY](state, { module, entity, value }) {
		module ? state[module][entity].push(value) : state[entity].push(value)
	},
}

export default new Vuex.Store({
	mutations,
	modules,
})
