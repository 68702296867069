<template>
	<div class="modal">
		<div class="modal-head">
			<div class="modal-title">Настройки доступа</div>
			<button class="close" type="button" @click="closeModal">
				<img src="@/assets/img/icons/plus.svg" />
			</button>
		</div>
		<div class="modal-content">
			<div class="first-row">
				<div class="group-wrapper">
					<InputRow :label="'Email'" :class="emailClass">
						<input
							v-model="email"
							class="input"
							type="text"
							placeholder="E-mail"
							@change="
								validateFields({ email: email }, validations)
							"
						/>
					</InputRow>
					<div class="choose-role">
						<button
							type="button"
							class="choose-button"
							@click="openDropdown"
						>
							{{ currentUserChoise.name }}
							<img
								src="@/assets/img/icons/chevron-down.svg"
								alt=""
							/>
						</button>
						<div class="choose-dropdown">
							<button
								v-for="choise of userChoises"
								:key="choise.slug"
								:class="
									choise.slug == currentUserChoise.slug
										? 'active'
										: ''
								"
								type="button"
								class="role"
								@click="changeAccess($event, choise.slug)"
							>
								{{ choise.name }}
							</button>
						</div>
					</div>
				</div>
				<button
					:style="'margin-bottom: 1.6rem;'"
					type="button"
					class="button green"
					@click="sendInvite"
				>
					Отправить
				</button>
			</div>
			<div class="row-wrapper">
				<InputCheckbox :checked="checked" @change-check="editToken">
					Любой по ссылке
				</InputCheckbox>
				<div
					:style="!checked ? 'opacity: .4; pointer-events: none' : ''"
					class="choose-role"
				>
					<button
						type="button"
						class="choose-button"
						@click="openDropdown"
					>
						{{ currentChoise.name }}
						<img src="@/assets/img/icons/chevron-down.svg" alt="" />
					</button>
					<div class="choose-dropdown">
						<button
							v-for="choise of choises"
							:key="choise.slug"
							:class="
								choise.slug == currentChoise.slug
									? 'active'
									: ''
							"
							type="button"
							class="role"
							@click="changeChoise($event, choise.slug)"
						>
							{{ choise.name }}
						</button>
					</div>
				</div>
				<button
					:style="
						!checked ? 'color: #afafaf; pointer-events: none' : ''
					"
					type="button"
					class="button grey"
					@click="copyUrl"
				>
					Копировать
				</button>
			</div>
			<div class="members">
				<div class="members-title">Участники</div>
				<div class="members-list">
					<div
						v-for="user of users"
						:key="user.id"
						class="members-item"
					>
						<div class="member">
							<div class="avatar">
								<div
									class="image-wrap"
									:style="`background: ${
										getRandomEmoji(
											user?.user?.email
												? user?.user?.email
												: user?.email
												? user?.email
												: 'anonymus',
											user?.user?.id
												? user?.user?.id
												: user?.id
												? user?.id
												: 0
										).gradient
									};`"
								>
									<img
										v-if="user.user.image"
										:src="user.user.image"
										alt="Аватар"
									/>
									<div
										v-else
										style="
											height: 100%;
											display: flex;
											justify-content: center;
											align-items: center;
										"
									>
										{{
											getRandomEmoji(
												user?.user?.email
													? user?.user?.email
													: user?.email
													? user?.email
													: 'anonymus',
												user?.user?.id
													? user?.user?.id
													: user?.id
													? user?.id
													: 0
											).emoji
										}}
									</div>
									<!-- <img v-else src="@/assets/img/icons/avatar.svg" class="default" alt="Аватар"> -->
								</div>
							</div>
							<div class="name">
								{{
									user.user.name
										? user.user.name
										: user.user.email
								}}
							</div>
						</div>
						<div class="choose-role">
							<button
								:style="
									user.role == 'owner'
										? 'pointer-events: none;'
										: ''
								"
								type="button"
								class="choose-button"
								@click="openDropdown"
							>
								{{ getRole(user.role) }}
								<img
									v-if="user.role !== 'owner'"
									src="@/assets/img/icons/chevron-down.svg"
									alt=""
								/>
							</button>
							<div
								v-if="user.role !== 'owner'"
								class="choose-dropdown"
							>
								<button
									v-for="choise of memberChoises"
									v-show="choise.slug !== 'owner'"
									:key="choise.slug"
									:style="
										choise.slug == 'delete'
											? 'color: #FA5858;'
											: ''
									"
									:class="
										choise.slug == user.role ? 'active' : ''
									"
									type="button"
									class="role"
									@click="
										updateAccess($event, user, choise.slug)
									"
								>
									{{ choise.name }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InputCheckbox from '@/common/components/inputs/InputCheckbox'
import InputRow from '@/common/components/inputs/InputRow'
import validator from '@/common/mixins/validation/auth'
import { getRandomEmoji } from '@/common/helpers'
import { mapState } from 'vuex'
export default {
	components: {
		InputCheckbox,
		InputRow,
	},
	mixins: [validator],
	emits: ['closeModal'],
	data: () => {
		return {
			email: '',
			checked: '',
			currentChoise: '',
			currentUserChoise: '',
			chooseOpen: false,
			getRandomEmoji: getRandomEmoji,
			chooseUserOpen: false,
			chooseMemberOpen: false,
			validations: {
				email: {
					error: '',
					rules: ['email', 'required'],
				},
			},
			choises: [
				{
					slug: 'viewer',
					name: 'Может смотреть',
				},
				{
					slug: 'editor',
					name: 'Может редактировать',
				},
				{
					slug: 'commenter',
					name: 'Может комментировать',
				},
			],
			userChoises: [
				{
					slug: 'viewer',
					name: 'Просмотр',
				},
				{
					slug: 'editor',
					name: 'Редактирование',
				},
				{
					slug: 'commenter',
					name: 'Комментирование',
				},
			],
			memberChoises: [
				{
					slug: 'viewer',
					name: 'Гость',
				},
				{
					slug: 'editor',
					name: 'Редактор',
				},
				{
					slug: 'commenter',
					name: 'Комментатор',
				},
				{
					slug: 'owner',
					name: 'Владелец',
				},
				{
					slug: 'delete',
					name: 'Удалить',
				},
			],
		}
	},
	computed: {
		...mapState('Projects', ['users', 'token']),
		emailClass() {
			return this.validations.email.error !== '' ? 'error' : ''
		},
	},
	created() {
		this.currentChoise = this.choises.find(
			(el) => el.slug == this.token.role
		)
		this.currentUserChoise = this.userChoises[0]
		this.checked = this.token.is_active
	},
	methods: {
		closeModal() {
			this.$emit('closeModal', true)
			document.querySelector('body').style.overflow = 'unset'
		},
		editToken(val) {
			this.checked = val
			let data = {}
			data['id'] = this.token?.id
			data['project_id'] = this.$route.params.projectId
			if (
				val == this.token.is_active &&
				this.currentChoise.slug == this.token.role
			) {
				console.log(this.token, val, this.currentChoise.slug)
				return
			}
			this.$store.dispatch('Projects/refreshToken', data).then(() => {
				data['body'] = {
					is_active: val,
					role: this.currentChoise.slug,
				}
				this.$store.dispatch('Projects/editToken', data)
			})
		},
		changeChoise(evt, slug) {
			this.chooseOpen = false
			this.currentChoise = this.choises.find((el) => el.slug == slug)
			let dropdown = evt.target.closest('.choose-dropdown')
			dropdown.classList.remove('open')
			this.editToken(this.checked)
		},
		openDropdown(evt) {
			let target = evt.target
			let dropdown = target
				.closest('.choose-role')
				.querySelector('.choose-dropdown')
			let opened = document.querySelectorAll('.choose-dropdown.open')

			if (opened.length > 0) {
				opened.forEach((el) => {
					if (el !== dropdown) el.classList.remove('open')
					console.log(el.classList)
				})
			}

			dropdown.classList.contains('open')
				? dropdown.classList.remove('open')
				: dropdown.classList.add('open')
		},
		getRole(slug) {
			let role = this.memberChoises.find((el) => el.slug == slug)
			return role.name
		},
		copyUrl() {
			const el = document.createElement('textarea')
			el.value = `https://uchetka.app/${this.token.token}`
			document.body.appendChild(el)
			el.select()
			document.execCommand('copy')
			document.body.removeChild(el)
			this.$toast.success('Скопировано!')
		},
		sendInvite() {
			if (
				!this.validateFields(
					{
						email: this.email,
					},
					this.validations
				)
			) {
				this.$toast.error(this.validations.email.error)
				return
			}

			let data = {
				project_id: this.$route.params.projectId,
				body: {
					email: this.email,
					role: this.currentUserChoise.slug,
				},
			}

			this.$store.dispatch('Projects/sendInvite', data).then(() => {
				this.$store.dispatch(
					'Projects/getUsers',
					this.$route.params.projectId
				)
				this.email = ''
			})
		},

		updateAccess(evt, user, role) {
			if (role == 'delete') {
				this.$store
					.dispatch('Projects/deleteUser', user.id)
					.then(() => {
						this.$store.dispatch(
							'Projects/getUsers',
							this.$route.params.projectId
						)
					})

				this.chooseOpen = false
				let dropdown = evt.target.closest('.choose-dropdown')
				dropdown.classList.remove('open')

				return
			}
			let data = {
				project_id: this.$route.params.projectId,
				body: {
					user_id: user.user.id,
					role: role,
				},
			}

			this.chooseOpen = false
			let dropdown = evt.target.closest('.choose-dropdown')
			dropdown.classList.remove('open')
			this.$store.dispatch('Projects/updateAccess', data).then(() => {
				this.$store.dispatch(
					'Projects/getUsers',
					this.$route.params.projectId
				)
			})
		},

		changeAccess(evt, slug) {
			this.chooseOpen = false
			this.currentUserChoise = this.userChoises.find(
				(el) => el.slug == slug
			)
			let dropdown = evt.target.closest('.choose-dropdown')
			dropdown.classList.remove('open')
			//this.editToken(this.checked)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.modal {
	width: 60rem;
	z-index: 21;
	padding-bottom: 4rem;
}

.close {
	width: 3.2rem;
	height: 3.2rem;
	transform: rotate(45deg);
	img {
		width: 100%;
		height: 100%;
	}
}

.first-row {
	display: flex;
	align-items: flex-end;

	.button {
		margin-left: 1.6rem;
		width: max-content;
		min-width: 12rem;
	}
}

.group-wrapper {
	position: relative;
	flex-grow: 1;

	.choose-role {
		position: absolute;
		right: 1.6rem;
		top: 3.2rem;
	}

	.choose-button {
		text-transform: capitalize;
	}
}

.row-wrapper {
	display: flex;
	align-items: center;
	margin-top: 2rem;

	.button {
		margin-left: auto;
		width: max-content;
	}

	.choose-button {
		text-transform: lowercase;
	}
}

.choose-role {
	position: relative;
	margin-left: 0.5rem;
}

.choose-button {
	display: flex;
	align-items: center;

	img {
		margin-left: 0.5rem;
		height: 2rem;
	}
}

.choose-dropdown {
	display: none;
	position: absolute;
	z-index: 2;
	top: calc(100% + 1rem);
	left: 0;
	background-color: $white;
	flex-direction: column;
	align-items: flex-start;
	width: max-content;
	border-radius: 0.8rem;
	overflow: hidden;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);

	&.open {
		display: flex;
	}
}

.role {
	padding: 1rem;
	background-color: $white;
	width: 100%;
	text-align: left;

	&:not(:last-of-type) {
		border-bottom: 1px solid $grey;
	}

	&.active {
		color: $green;
	}

	&:hover {
		background-color: $grey;
	}

	&:active {
		background-color: $grey-hover2;
	}
}

.members {
	margin-top: 3.6rem;
}

.members-title {
	color: $dark-grey;
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

.members-item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:not(:last-of-type) {
		margin-bottom: 0.8rem;
	}

	.choose-dropdown {
		left: unset;
		right: 0;
		max-height: 8rem;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0.2rem;
		}

		&::-webkit-scrollbar-thumb {
			border-color: transparent;
			background-color: $dark-grey;
		}

		&::-webkit-scrollbar-thumb:hover {
			border-color: transparent;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			border-width: 0;
		}

		&::-moz-scrollbar {
			width: 0.2rem;
		}
	}
}

.member {
	display: flex;
	align-items: center;
}

.avatar {
	width: 3.2rem;
	height: 3.2rem;
	min-width: 3.2rem;
	min-height: 3.2rem;
	overflow: hidden;
	border-radius: 100%;
	border: 2px solid #00d47d;
	padding: 2px;
	position: relative;
}

.name {
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: #282828;
	margin-left: 0.8rem;
}

.image-wrap {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 100%;
	position: relative;
	background-color: #f8f8f8;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #00d47d;
	font-size: 1.6rem;
	font-weight: bold;

	img:not(.default) {
		width: 100%;
		height: 100%;
		min-width: 100%;
		min-height: 100%;
	}

	.default {
		width: 1.8rem;
		max-width: 1.8rem;
	}
}
</style>
