/* eslint-disable */

import {
	GET_ME_URL,
	UPDATE_PASSWORD_URL,
	AVATAR_URL,
	BACKGROUND_URL,
} from '@/common/constants'
import { getCookie } from '@/common/helpers'
import { SET_ENTITY } from '@/store/mutation-types'
import axios from 'axios'
import router from '@/router/index'

export default {
	namespaced: true,
	state: {
		user: null,
	},

	actions: {
		async getMe({ commit }) {
			await axios.get(GET_ME_URL).then((response) => {
				commit(
					SET_ENTITY,
					{
						module: 'User',
						entity: 'user',
						value: response.data.data,
					},
					{ root: true }
				)
				commit(
					SET_ENTITY,
					{
						module: 'Auth',
						entity: 'user',
						value: response.data.data,
					},
					{ root: true }
				)
			})
		},

		async deleteMe({ commit }) {
			await axios.delete(GET_ME_URL).then((response) => {
				commit(
					SET_ENTITY,
					{ module: 'User', entity: 'user', value: null },
					{ root: true }
				)
				document.cookie = `access_data=""`
				localStorage.clear()
				router.push('/')
			})
		},

		async updateProfile({ commit }, data) {
			console.log(data)
			await axios.post(GET_ME_URL, data).then((response) => {
				console.log(response)
			})
		},

		async uploadAvatar({ commit }, data) {
			await axios
				.post(AVATAR_URL, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					console.log(response)
				})
		},

		async deleteAvatar() {
			await axios.delete(AVATAR_URL).then((response) => {
				console.log(response)
			})
		},

		async uploadBackground({ commit }, data) {
			await axios
				.post(BACKGROUND_URL, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					console.log(response)
				})
		},

		async deleteBackground() {
			await axios.delete(BACKGROUND_URL).then((response) => {
				console.log(response)
			})
		},

		async updatePassword({ commit }, data) {
			await axios.patch(UPDATE_PASSWORD_URL, data).then((response) => {
				console.log(response)
			})
		},
	},
}
