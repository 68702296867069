<template>
	<div class="add-task">
		<div class="add-task-title">Настройки задачи</div>
		<div class="add-task-wrapper">
			<InputRow :label="'Заголовок'">
				<input
					v-model="form.title"
					class="input"
					type="text"
					placeholder="Введите заголовок задачи"
				/>
			</InputRow>
			<InputRow :label="'Описание'">
				<textarea
					ref="description"
					v-model="form.description"
					:style="`height: ${
						form.description == '' ? '4rem' : getHeight('textarea')
					};`"
					class="input"
					placeholder="Введите описание задачи"
					@input="inputDescription($event)"
				/>
			</InputRow>
			<RelatedDates
				:start="form.date_from"
				:end="form.date_to"
				:global-start="projectDates.start"
				:global-end="projectDates.end"
				@set-start="setStart"
				@set-end="setEnd"
				@set-duration="setDuration"
				@set-period="setPeriod"
			/>

			<InputRow :label="'Приоритет'">
				<InputDropdown
					:options="priorityOptions"
					:selected-option="form.priority.toString()"
					type="priority"
					@change-option="(val) => (form.priority = val)"
				/>
			</InputRow>

			<div class="input-row">
				<InputRow :label="'Исполнители'">
					<div class="task-users">
						<div v-for="user of users" :key="user?.id" class="user">
							<div class="user-image">
								<div
									class="image-wrap"
									:style="`background: ${
										getRandomEmoji(
											user?.email
												? user.email
												: 'anonymus',
											user?.id ? user.id : 0
										).gradient
									};`"
								>
									<div v-if="user && user?.image == null">
										{{
											getRandomEmoji(
												user?.email
													? user.email
													: 'anonymus',
												user?.id ? user.id : 0
											).emoji
										}}
									</div>
									<img v-else :src="user?.image" />
								</div>
							</div>
							<div class="user-name">
								{{ user.name ? user.name : user.email }}
							</div>
							<button
								type="button"
								class="delete-user"
								@mousedown="deleteUser(user.id)"
							>
								<img
									src="@/assets/img/icons/delete.svg"
									class="icon"
									alt="Удалить"
								/>
							</button>
						</div>
					</div>
					<button
						v-show="!addUsers"
						type="button"
						class="add-users"
						@click="addUsers = true"
					>
						<svg
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								width="28"
								height="28"
								rx="14"
								fill="#F8F8F8"
							/>
							<circle
								cx="14"
								cy="14"
								r="13"
								stroke="#00D47D"
								stroke-width="2"
							/>
							<circle cx="14" cy="14" r="10" fill="white" />
							<path
								d="M14 9.91663V18.0833"
								stroke="#00D47D"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M9.91699 14H18.0837"
								stroke="#00D47D"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>
					<InputSearch
						v-show="addUsers"
						:options="projectUsersOptions"
						:clear="true"
						search-placeholder="Выберите или найдите исполнителя"
						@change-option="addUserInTask"
					/>
				</InputRow>
			</div>

			<InputRow :label="'Главная задача'">
				<InputSearch
					:options="tasksOptions"
					:default-value="parent_id"
					search-placeholder="Выберите или найдите задачу"
					@change-option="(val) => (parent_id = val)"
				/>
			</InputRow>

			<button
				type="button"
				class="button green"
				:class="loading ? 'loading' : ''"
				@click="taskHandler"
			>
				Сохранить
			</button>

			<button
				v-if="taskData"
				type="button"
				class="button grey"
				:class="loading ? 'loading' : ''"
				@click="duplicateTask"
			>
				Дублировать задачу
			</button>

			<button
				v-if="taskData"
				type="button"
				class="button red"
				:class="loading ? 'loading' : ''"
				@click="dellTask"
			>
				Удалить задачу
			</button>
		</div>
	</div>
</template>

<script>
import RelatedDates from '@/common/components/parts/RelatedDates'
import InputDropdown from '@/common/components/inputs/InputDropdown'
import InputSearch from '@/common/components/inputs/InputSearch'
import InputRow from '@/common/components/inputs/InputRow'
import validator from '@/common/mixins/validation/auth'
import { SET_ENTITY } from '@/store/mutation-types'
import { getRandomEmoji } from '@/common/helpers'
import { mapState } from 'vuex'
import moment from 'moment'
import { ref } from 'vue'

export default {
	name: 'TaskForm',
	components: {
		InputRow,
		RelatedDates,
		InputDropdown,
		InputSearch,
	},
	mixins: [validator],
	props: {
		isChange: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['closeModal'],
	data() {
		return {
			addUsers: false,
			getRandomEmoji: getRandomEmoji,
			form: {
				title: '',
				date_from: ref(new Date()),
				date_to: ref(new Date()),
				description: '',
				minute_count: '',
				status: 'new',
				priority: 0,
			},
			parent_id: null,
			users: [],
			timeType: 'minutes',
			time: '',
			timeOptions: {
				minutes: 'минуты',
				hours: 'часы',
				days: 'дни',
			},
			priorityOptions: {
				0: `<span style='color: #00D47D; display: flex; align-items: baseline;'><svg style='width: 1.2rem; height: 1rem;' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M3.5 11V1L8.5 3.5L3.5 6'
                                stroke='#00D47D' stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                            />
                        </svg>Низкий</span>`,
				1: `<span style='color: #F0B000; display: flex; align-items: baseline;'><svg style='width: 1.2rem; height: 1rem;' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M3.5 11V1L8.5 3.5L3.5 6'
                                stroke='#F0B000' stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                            />
                        </svg>Средний</span>`,
				2: `<span style='color: #FA5858; display: flex; align-items: baseline;'><svg style='width: 1.2rem; height: 1rem;' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M3.5 11V1L8.5 3.5L3.5 6'
                                stroke='#FA5858' stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                            />
                        </svg>Высокий</span>`,
			},
			loading: false,
			duration: '',
			currentPeriod: '',
			projectDates: {
				start: null,
				end: null,
			},
		}
	},
	computed: {
		...mapState('Projects', ['single']),
		tasksOptions() {
			return this.$store.getters['Tasks/tasksOptions']
		},
		projectUsers() {
			return this.$store.state.Projects.projectUsers
		},
		projectUsersOptions() {
			return this.$store.getters['Projects/projectUsersOptions']
		},
		taskData() {
			return this.$store.state.Tasks.changedTask
		},
	},
	watch: {
		timeType() {
			this.time = ''
			this.form.minute_count = ''
		},
		time(val) {
			switch (this.timeType) {
				case 'hours':
					this.form.minute_count = val * 60
					break
				case 'days':
					this.form.minute_count = val * 1440
					break
				default:
					this.form.minute_count = val
			}
		},
		taskData(val) {
			if (val) this.fillData()
		},
	},
	mounted() {
		console.log(this.$refs)
	},
	created() {
		this.$store.dispatch(
			'Tasks/getTasksForSelect',
			this.$route.params.projectId
				? this.$route.params.projectId
				: this.single.id
		)
		this.$store.dispatch(
			'Projects/getProjectUsers',
			this.$route.params.projectId
				? this.$route.params.projectId
				: this.single.id
		)
		if (this.taskData) this.fillData()

		this.projectDates = {
			start: moment(this.single.date_from).toDate() || null,
			end: moment(this.single.date_to).toDate() || null,
		}
	},
	unmounted() {
		this.$store.commit(
			SET_ENTITY,
			{
				module: 'Tasks',
				entity: 'changedTask',
				value: null,
			},
			{
				root: true,
			}
		)
	},
	methods: {
		getHeight(ref) {
			console.log(this.$refs['description'], ref)
			setTimeout(function () {
				console.log(this.$refs, ref)
			}, 1000)
			//el.style.cssText = 'height:' + el.scrollHeight + 'px'
		},
		inputDescription(evt) {
			let target = evt.target
			setTimeout(function () {
				target.style.cssText = 'height:auto'
				target.style.cssText = 'height:' + target.scrollHeight + 'px'
			}, 0)
		},
		fillData() {
			for (const key in this.form) {
				this.form[key] = this.taskData[key]
			}
			this.time = this.taskData.minute_count
			this.users = this.taskData.users
			this.parent_id = this.taskData.parent_id
		},
		taskHandler() {
			this.form.title =
				this.form.title == '' ? 'Новая задача' : this.form.title
			this.form.minute_count = this.calculateMinutes(
				this.currentPeriod,
				this.duration
			)
			this.loading = true
			let newUsers = []
			Array.from(this.users).forEach((el) => {
				newUsers.push(el?.id)
			})
			const data = { ...this.form }
			data.users = newUsers
			data.project_id = this.$route.params.projectId
				? this.$route.params.projectId
				: this.single.id
			if (this.parent_id) data.parent_id = this.parent_id

			if (this.taskData) {
				data.order = this.taskData.order
				this.$store
					.dispatch('Tasks/updateTask', {
						taskId: this.taskData.id,
						data,
					})
					.then(() => {
						this.$toast.success(`Задача обновлена`)
						this.$store.dispatch('Tasks/getTasks', {
							projectId: data.project_id,
						})
						this.closeModal()
					})
					.catch(() => {
						this.$toast.error(`Ошибка`)
						this.loading = false
					})
			} else {
				this.$store
					.dispatch('Tasks/addTask', data)
					.then(() => {
						this.$toast.success(`Задача создана`)

						this.$store.dispatch('Tasks/getTasks', {
							projectId: data.project_id,
						})
						this.closeModal()

						this.$store.dispatch(
							'Tasks/getTasksForSelect',
							this.$route.params.projectId
								? this.$route.params.projectId
								: this.single.id
						)
					})
					.catch(() => {
						this.$toast.error(`Ошибка`)
						this.loading = false
					})
			}
		},
		duplicateTask() {
			this.$store
				.dispatch('Tasks/duplicateTask', this.taskData.id)
				.then(() => {
					this.$toast.success(`Задача продублирована`)
					this.$store.dispatch('Tasks/getTasks', {
						projectId: this.$route.params.projectId,
					})
					this.closeModal()
				})
				.catch(() => {
					this.$toast.error(`Ошибка`)
					this.loading = false
				})
		},
		dellTask() {
			this.$store
				.dispatch('Tasks/dellTask', this.taskData.id)
				.then(() => {
					this.$toast.success(`Задача удалена`)
					this.$store.dispatch('Tasks/getTasks', {
						projectId: this.$route.params.projectId,
					})
					this.closeModal()

					this.$store.dispatch(
						'Tasks/getTasksForSelect',
						this.$route.params.projectId
							? this.$route.params.projectId
							: this.single.id
					)
				})
				.catch(() => {
					this.$toast.error(`Ошибка`)
					this.loading = false
				})
		},
		addUserInTask(val) {
			const userData = this.projectUsers.find((el) => el.user.id === val)
			let users = [...this.users]
			if (!users.find((el) => el.id === val)) {
				users.push(userData.user)
			}
			this.users = users
			setTimeout(() => {
				this.addUsers = false
			}, 50)
		},
		closeModal() {
			document.querySelector('body').style.overflow = 'unset'
			this.$emit('closeModal', true)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'openModal',
					value: false,
				},
				{
					root: true,
				}
			)
		},
		setStart(val) {
			this.form.date_from = val
		},
		setEnd(val) {
			this.form.date_to = val
		},
		setDuration(val) {
			this.duration = val
		},
		setPeriod(val) {
			this.currentPeriod = val
		},
		calculateMinutes(period, count) {
			switch (period) {
				case 'workDays':
					return Number(count) * 1440
				case 'hours':
					return Number(count) * 60
				default:
					return Number(count) * 1440
			}
		},
		deleteUser(id) {
			this.users = this.users.filter((user) => user.id !== id)
		},
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';

.delete-user {
	pointer-events: all;
	margin-left: auto;
	width: 4rem;
	height: 3rem;
	padding: 0.5rem;
	padding-left: 1.5rem;
	max-width: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
	}
}

.add-task {
	height: 100vh;
	background-color: #fff;

	.add-task-title {
		width: 100%;
		padding: 1.7rem 0 1.4rem 3.6rem;
		background-color: $grey;
		color: #000;
		font-size: 1.8rem;
		font-weight: 500;
	}

	.add-task-wrapper {
		padding: 3.6rem;
		height: 95%;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0.6rem;
			margin-right: 2.2rem;
		}

		&::-webkit-scrollbar-thumb {
			border-color: transparent;
			background-color: $dark-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			border-color: transparent;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			border-width: 0;
		}

		&::-moz-scrollbar {
			width: 0.2rem;
		}
	}

	&__time-picker {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.6rem;
	}

	.button {
		margin-bottom: 1.6rem;
	}
}

.add-users svg {
	//margin-top: .8rem;
	width: 3.2rem;
	height: 3.2rem;
}

.task-users {
	margin-bottom: 0.8rem;
}

.user {
	display: flex;
	align-items: center;
	pointer-events: none;

	&:not(:last-of-type) {
		margin-bottom: 0.4rem;
	}
}

.user-image {
	width: 3.2rem;
	height: 3.2rem;
	min-width: 3.2rem;
	min-height: 3.2rem;
	overflow: hidden;
	border-radius: 100%;
	border: 2px solid #00d47d;
	padding: 2px;
	position: relative;
}

.image-wrap {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 100%;
	position: relative;
	background-color: #f8f8f8;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #00d47d;
	font-size: 1.6rem;
	font-weight: bold;

	img:not(.default) {
		width: 100%;
		height: 100%;
		min-width: 100%;
		min-height: 100%;
	}

	.default {
		width: 1.8rem;
		max-width: 1.8rem;
	}
}

.user-name {
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: #282828;
	margin-left: 0.8rem;
}
</style>
