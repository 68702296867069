<template>
	<InputRow :label="'Дата начала'">
		<InputDate
			v-model="startDate"
			:min="globalStart ? globalStart : null"
			:max="globalEnd ? globalEnd : null"
			:date="startDate"
		/>
	</InputRow>
	<InputRow :label="'Дата окончания'">
		<InputDate
			v-model="endDate"
			:min="startDate"
			:max="globalEnd ? globalEnd : ''"
			:date="endDate"
		/>
	</InputRow>
	<div class="input-group">
		<InputRow :label="'Продолжительность'">
			<input
				v-model="durationValue"
				class="input"
				type="text"
				@change="changeDuration"
			/>
		</InputRow>
		<InputDropdown
			style="margin-top: 2.2rem"
			:options="dropdownOptions"
			:selected-option="currentPeriod"
			@change-option="changePeriod"
		/>
	</div>
</template>

<script>
import InputDropdown from '@/common/components/inputs/InputDropdown'
import InputDate from '@/common/components/inputs/InputDate'
import InputRow from '@/common/components/inputs/InputRow'
import { declOfNum, getHolidays } from '@/common/helpers'
//import Holidays from 'date-holidays'
import 'moment-weekday-calc'
import moment from 'moment'
import { ref } from 'vue'

export default {
	components: {
		InputRow,
		InputDate,
		InputDropdown,
	},
	props: {
		start: {
			type: Date,
			default: null,
		},
		end: {
			type: Date || String,
			default: null,
		},
		globalStart: {
			type: Object || String,
			default: null,
		},
		globalEnd: {
			type: Object || String,
			default: null,
		},
	},
	emits: [
		'setStart',
		'setEnd',
		'setDuration',
		'setPeriod',
		'globalStart',
		'globalEnd',
	],
	data: () => {
		return {
			startDate: ref(new Date()),
			endDate: ref(new Date()),
			currentPeriod: '',
			durationValue: '',
			changedValue: '',
		}
	},
	computed: {
		dropdownOptions() {
			let options = {
				days: declOfNum(this.duration, [
					'Календарный день',
					'Календарных дня',
					'Календарных дней',
				]),
				workDays: declOfNum(this.duration, [
					'Рабочий день',
					'Рабочих дня',
					'Рабочих дней',
				]),
				hours: declOfNum(this.duration, [
					'Рабочий час',
					'Рабочих часа',
					'Рабочих часов',
				]),
			}
			return options
		},
		duration() {
			let totals = 0
			let hdArray = []
			getHolidays(new Date(this.startDate).getFullYear(), hdArray)
			hdArray.forEach((el, index) => {
				hdArray[index] = moment(el, 'DD MMMM YYYY')
			})

			let hours =
				Number(
					moment().isoWeekdayCalc(
						this.startDate,
						this.endDate,
						[1, 2, 3, 4, 5],
						hdArray
					)
				) * 8

			switch (this.currentPeriod) {
				case 'workDays':
					totals = moment().isoWeekdayCalc(
						this.startDate,
						this.endDate,
						[1, 2, 3, 4, 5],
						hdArray
					)
					break
				case 'hours':
					totals =
						Math.ceil(hours / 8) == Math.ceil(this.changedValue / 8)
							? this.changedValue
							: hours
					break
				default:
					totals = moment().isoWeekdayCalc(
						this.startDate,
						this.endDate,
						[1, 2, 3, 4, 5, 6, 7]
					)
					break
			}
			return totals
		},
	},
	watch: {
		duration() {
			this.durationValue = this.duration
		},
		startDate(val) {
			this.$emit('setStart', val)
		},
		endDate(val) {
			this.$emit('setEnd', val)
		},
		durationValue(val) {
			this.$emit('setDuration', val)
		},
		currentPeriod(val) {
			this.$emit('setPeriod', val)
		},
	},
	created() {
		this.startDate = this.start
		this.endDate = this.end
	},
	mounted() {
		this.currentPeriod = 'days'
		this.durationValue = this.duration
		this.changedValue = this.duration
	},
	methods: {
		changePeriod(period) {
			this.currentPeriod = period
		},
		changeDuration() {
			this.changedValue = this.durationValue
			let newVal = Number(this.durationValue)
			let days = Math.ceil(
				this.currentPeriod == 'hours' ? newVal / 8 : newVal
			)

			let hdArray = []
			getHolidays(new Date(this.startDate).getFullYear(), hdArray)
			hdArray.forEach((el, index) => {
				hdArray[index] = moment(el, 'DD MMMM YYYY')
			})

			let newEnd = moment(this.startDate).isoAddWeekdaysFromSet({
				workdays: days - 1,
				weekdays: [1, 2, 3, 4, 5],
				exclusions: hdArray,
			})

			switch (this.currentPeriod) {
				case 'workDays':
					this.endDate = newEnd
					break
				case 'hours':
					this.endDate = newEnd
					break
				default:
					this.endDate = moment(this.startDate).add(days - 1, 'days')
					break
			}
		},
	},
}
</script>

<style scoped>
.input-group {
	display: grid;
	grid-gap: 1.6rem;
	grid-template-columns: repeat(2, 1fr);
}
</style>
