<template>
	<Head>
		<title>Восстановление пароля</title>
	</Head>
	<AuthModal />
</template>

<script>
import AuthModal from '@/modules/auth/AuthModal'
import { Head } from '@vueuse/head'

export default {
	components: {
		AuthModal,
		Head,
	},
}
</script>
