/* eslint-disable */
import {
	CHANGE_TASK_ORDER_URL,
	ADD_TASK_URL,
	TASKS_BY_TOKEN_URL,
	ADD_TASK_COMMENTS_URL,
	getTasksUrl,
	getTasksForSelectUrl,
	updateTaskUrl,
	duplicateTaskUrl,
	deleteTaskUrl,
	getTaskCommentsUrl,
	ADD_TASK_PINS_URL,
	getPins,
} from '@/common/constants'
import { SET_ENTITY } from '@/store/mutation-types'
import moment from 'moment'
import axios from 'axios'

export default {
	namespaced: true,
	state: {
		tasksList: null,
		tasksListForSelect: null,
		noTasks: false,
		changedTask: null,
		openModal: false,
		modalTitle: null,
		filteredTasks: null,
		taskPins: [],
		openedTasks: [],
	},
	getters: {
		tasksOptions: (state) => {
			return state.tasksListForSelect
				? state.tasksListForSelect.map((el) => ({
						label: el.title,
						value: el.id,
				  }))
				: []
		},
	},
	actions: {
		async getTasks({ commit }, data) {
			const url = getTasksUrl(data.projectId)
			await axios
				.get(url, { params: data.params })
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Tasks',
							entity: 'tasksList',
							value: response.data.data,
						},
						{ root: true }
					)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async getTaskComments({ commit }, id) {
			const url = getTaskCommentsUrl(id)
			await axios
				.get(url)
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Tasks',
							entity: 'taskComments',
							value: response.data.data,
						},
						{ root: true }
					)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async addTaskPins({ commit }, data) {
			await axios
				.post(ADD_TASK_PINS_URL, data)
				.then((response) => {
					console.log(response)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async addTaskComments({ commit }, data) {
			await axios
				.post(ADD_TASK_COMMENTS_URL, data)
				.then((response) => {
					console.log(response)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async getPins({ commit }, id) {
			const url = getPins(id)
			await axios
				.get(url)
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Tasks',
							entity: 'taskPins',
							value: response.data.data,
						},
						{ root: true }
					)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async getTasksForSelect({ commit }, id) {
			const url = getTasksForSelectUrl(id)
			await axios
				.get(url)
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Tasks',
							entity: 'tasksListForSelect',
							value: response.data.data,
						},
						{ root: true }
					)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async updateTask({ dispatch }, data) {
			const url = updateTaskUrl(data.taskId)
			await axios
				.put(url, data.data)
				.then((response) => {
					if (data.data.parent_id) {
						axios
							.get(updateTaskUrl(data.data.parent_id))
							.then((resp) => {
								let parent = resp.data.data
								if (
									moment(data.data.date_to).isAfter(
										moment(parent.date_to),
										'day'
									) ||
									moment(data.data.date_from).isBefore(
										moment(parent.date_from),
										'day'
									)
								) {
									parent.date_to = moment(
										data.data.date_to
									).isAfter(moment(parent.date_to), 'day')
										? data.data.date_to
										: parent.date_to
									parent.date_from = moment(
										data.data.date_from
									).isBefore(moment(parent.date_from), 'day')
										? data.data.date_from
										: parent.date_from
									delete parent['users']
									let parentData = {
										taskId: parent.id,
										data: parent,
									}
									dispatch('updateTask', parentData)
								}
							})
					}

					axios.get(updateTaskUrl(data.taskId)).then((resp) => {
						let currentTask = resp.data.data
						if (currentTask.nested.length > 0) {
							let nested = currentTask.nested
							console.log(currentTask, nested)
							nested.forEach((el) => {
								if (
									moment(el.date_to).isAfter(
										moment(currentTask.date_to),
										'day'
									)
								) {
									el.date_to = currentTask.date_to
									el.date_from = moment(el.date_to).add(
										Number(el.minute_count) / -1440,
										'days'
									)
								}
								if (
									moment(el.date_from).isBefore(
										moment(currentTask.date_from),
										'day'
									)
								) {
									el.date_from = currentTask.date_from
									el.date_to = moment(el.date_from).add(
										Number(el.minute_count) / 1440,
										'days'
									)
								}

								delete el['users']
								let taskData = {
									taskId: el.id,
									data: el,
								}
								dispatch('updateTask', taskData)
							})
						}
					})

					return response
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
		async changeTaskOrder(ctx, data) {
			await axios
				.put(CHANGE_TASK_ORDER_URL, data)
				.then()
				.catch((error) => {
					console.log(error.response)
				})
		},
		async addTask({ dispatch }, data) {
			await axios
				.post(ADD_TASK_URL, data)
				.then((response) => {
					if (data.parent_id) {
						axios
							.get(updateTaskUrl(data.parent_id))
							.then((resp) => {
								let parent = resp.data.data
								if (
									moment(data.date_to).isAfter(
										moment(parent.date_to),
										'day'
									) ||
									moment(data.date_from).isBefore(
										moment(parent.date_from),
										'day'
									)
								) {
									parent.date_to = moment(
										data.date_to
									).isAfter(moment(parent.date_to), 'day')
										? data.date_to
										: parent.date_to
									parent.date_from = moment(
										data.date_from
									).isBefore(moment(parent.date_from), 'day')
										? data.date_from
										: parent.date_from
									delete parent['users']
									let parentData = {
										taskId: parent.id,
										data: parent,
									}
									dispatch('updateTask', parentData)
								}
								console.log(data.date_to, parent.date_to)
							})
					}
					return response
				})
				.catch(({ response }) => {
					throw response
				})
		},
		async duplicateTask(ctx, id) {
			const url = duplicateTaskUrl(id)
			await axios
				.post(url)
				.then((response) => {
					return response
				})
				.catch(({ response }) => {
					throw response
				})
		},
		async dellTask({ commit, state }, id) {
			const url = deleteTaskUrl(id)
			await axios.delete(url).then((response) => {
				if (state?.filteredTasks?.length) {
					let filtered = [...state.filteredTasks]
					console.log(filtered)
					let index = filtered.findIndex((el) => el.id == id)
					if (index !== -1) {
						filtered.splice(index, 1)
						commit(
							SET_ENTITY,
							{
								module: 'Tasks',
								entity: 'filteredTasks',
								value: filtered,
							},
							{ root: true }
						)
					}
				}
				return response
			})
			// .catch(({ response }) => {
			// 	console.log(response)
			// })
		},
		async getTasksByToken({ commit }, data) {
			await axios
				.get(TASKS_BY_TOKEN_URL, { params: data?.params })
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Tasks',
							entity: 'tasksList',
							value: response.data.data,
						},
						{ root: true }
					)
				})
				.catch((error) => {
					console.log(error.response)
				})
		},
	},
}
