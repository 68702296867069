<template>
	<div class="single-layout">
		<AppHeaderSingle />
		<slot />
	</div>
</template>

<script>
import AppHeaderSingle from '@/layouts/AppHeaderSingle'
export default {
	components: {
		AppHeaderSingle,
	},
}
</script>
