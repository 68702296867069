<template>
	<div class="task-list">
		<div class="switcher-wrap">
			<div class="task-list__time-switcher">
				<div class="switcher-button">
					<div
						class="switcher-button__item"
						:class="{
							'switcher-button__item--active':
								listType === 'todayTasks',
						}"
						@click="listType = 'todayTasks'"
					>
						Сегодня
					</div>
					<div
						class="switcher-button__item"
						:class="{
							'switcher-button__item--active':
								listType === 'allTasks',
						}"
						@click="listType = 'allTasks'"
					>
						Все задачи
					</div>
				</div>
			</div>
		</div>

		<div v-if="!gantt" class="task-list__header tasks-list-columns">
			<div
				v-for="item in columns"
				:key="item.code"
				class="task-list__header-column"
			>
				<div
					class="task-list__header-column-content"
					:class="{
						'task-list__header-column-content--active':
							sort_by === item.code,
					}"
					@click="changeSort(item.code)"
				>
					<div class="task-list__header-text">{{ item.name }}</div>
					<div
						class="task-list__header-sort-arrow"
						:class="{
							'task-list__header-sort-arrow--desc':
								sort_by === item.code && sort_dir === 'desc',
						}"
					>
						<img src="@/assets/img/icons/sort-arrow.svg" alt="" />
					</div>
				</div>
			</div>
		</div>

		<div v-else class="task-list__header tasks-list-columns">
			<div
				v-for="item in ganttColumns"
				:key="item.code"
				class="task-list__header-column"
			>
				<div class="task-list__header-column-content">
					<div class="task-list__header-text">{{ item.name }}</div>
				</div>
			</div>
		</div>

		<div
			v-if="!gantt && hasAccess"
			class="task-list__header add-task-header tasks-list-columns"
		>
			<div class="task-list__header-column add-task-header-first-column">
				<form class="task-list__header-column-content">
					<button
						type="submit"
						class="add-task-header__plus-icon"
						@click.prevent="addTask"
					>
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M6 2.5V9.5"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M2.5 6H9.5"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>
					<div class="add-task-header__task-priority">
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3.5 11V1L8.5 3.5L3.5 6"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
					<input
						v-model="newTaskTitle"
						type="text"
						placeholder="Новая задача"
						class="add-task-header__input"
					/>
				</form>
			</div>

			<!-- <div class="task-list__header-column">
                <div class="task-list__header-column-content">
                    <div class="add-task-header__plus-icon">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 2.5V9.5" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.5 6H9.5" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    Добавить
                </div>
            </div> -->

			<!-- <div class="task-list__header-column">
                <div class="task-list__header-column-content">
                    <ProjectDates 
                        date-from="04.10.2022"
                        date-to="04.10.2022"
                        color="grey"
                    />
                </div>
            </div> -->

			<!-- <div class="task-list__header-column">
                <div class="task-list__header-column-content">
                    <div v-if="!newTask.users.length" class="add-task-header__add-user">
                        <svg  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="14" fill="#F8F8F8"/>
                            <circle cx="14" cy="14" r="13" stroke="#00D47D" stroke-width="2"/>
                            <circle cx="14" cy="14" r="10" fill="white"/>
                            <path d="M14 9.91663V18.0833" stroke="#00D47D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.91699 14H18.0837" stroke="#00D47D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-else class="add-task-header__project-users">
                        <ProjectMembers
                            :members="newTask.users"
                        />
                    </div>
                </div>
            </div>

            <div class="task-list__header-column">
                <div class="task-list__header-column-content">
                    <div class="percent-status">
                        <div class="percent-status__number">0%</div>
                            <div class="percent-status__diagram">
                        </div>
                    </div>
                </div>
            </div>

            <div class="task-list__header-column">
                <div class="task-list__header-column-content">
                    0 Дней
                </div>
            </div> -->
		</div>
		<div v-if="taskList?.length" class="task-list-table">
			<draggable
				v-model="taskList"
				:animation="200"
				ghost-class="ghost"
				item-key="id"
				handle=".task-row__handle"
				:disabled="sort_by || !hasAccess ? true : false"
				@start="drag = true"
				@end="changeOrder"
			>
				<template #item="{ element }">
					<TaskRow
						:data="element"
						:level="0"
						:main-task-id="element.id"
						:use-sort="sort_by ? true : false"
						:opened-sub="gantt"
						:change-type="changeType"
					>
						<template #handle>
							<div class="task-row__handle">
								<img
									src="@/assets/img/icons/drag-handler.svg"
								/>
							</div>
						</template>
					</TaskRow>
				</template>
			</draggable>
		</div>
		<div v-else class="no-tasks">
			<p>
				Здесь будут отображаться задачи,<br />
				поставленные по проекту 🤔
			</p>
		</div>
	</div>
</template>

<script>
import { SET_ENTITY } from '@/store/mutation-types'
import TaskRow from '@/modules/taskList/TaskRow'
import { changeOrders } from '@/common/helpers'
import draggable from 'vuedraggable'
import * as momentTemp from 'moment'
const moment = momentTemp['default']
import { mapState } from 'vuex'
import { ref } from 'vue'

export default {
	name: 'TaskListTable',
	components: {
		TaskRow,
		draggable,
	},
	props: {
		gantt: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			moment,
			drag: false,
			sort_by: null,
			sort_dir: 'asc',
			newTask: {
				title: '',
				date_from: ref(new Date()),
				date_to: ref(new Date()),
				minute_count: '',
				status: 'new',
				priority: 0,
			},
			newTaskTitle: '',
			taskList: null,
			listType: null,
			changeType: false,

			columns: [
				{ name: 'Заголовок', code: 'title' },
				{ name: 'Комментарии', code: 'comments' },
				{ name: 'Срок проекта', code: 'date_to' },
				{ name: 'Исполнители', code: 'users' },
				{ name: 'Статус', code: 'status' },
				{ name: 'Время', code: 'minute_count' },
			],
			ganttColumns: [
				{ name: 'Заголовок', code: 'title' },
				{ name: 'Статус', code: 'status' },
				{ name: 'Время', code: 'minute_count' },
				{ name: 'Исполнители', code: 'users' },
			],
		}
	},
	computed: {
		...mapState('Auth', ['user']),
		...mapState('Projects', ['users', 'single']),
		...mapState('Tasks', ['filteredTasks', 'openedTasks']),
		hasAccess() {
			if (this.user == null) return false
			return this.single?.role == 'owner' || this.single?.role == 'editor'
				? true
				: false
		},
		tasksData() {
			return this.$store.state.Tasks.tasksList
		},
		requestParams() {
			const data = {
				projectId: this.$route.params.projectId,
			}
			if (this.sort_by) {
				data.params = {
					sort_by: `${this.sort_dir === 'desc' ? '-' : ''}${
						this.sort_by
					}`,
				}
			}
			return data
		},
	},
	watch: {
		tasksData(val) {
			if (val) {
				this.taskList = [...val]

				if (this.listType == 'todayTasks') {
					this.taskList = this.taskList.filter(
						(el) =>
							moment(el.date_from).isSameOrBefore(
								moment(),
								'day'
							) &&
							moment(el.date_to).isSameOrAfter(moment(), 'day')
					)
				}
			}
		},
		listType(val) {
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Tasks', entity: 'filteredTasks', value: [] },
				{ root: true }
			)

			this.changeType = true
			val === 'allTasks'
				? localStorage.setItem('tasksListFilter', val)
				: localStorage.removeItem('tasksListFilter')
			if (this.taskList) {
				if (val == 'todayTasks') {
					this.taskList = this.taskList.filter(
						(el) =>
							moment(el.date_from).isSameOrBefore(
								moment(),
								'day'
							) &&
							moment(el.date_to).isSameOrAfter(moment(), 'day')
					)
				} else {
					this.taskList = [...this.tasksData]
				}
			}
			setTimeout(() => {
				this.changeType = false
			}, 1000)
		},
		openedTasks() {
			this.setFilteredTasks(this.taskList)
		},
		taskList(val) {
			this.setFilteredTasks(val)
		},
	},
	created() {
		this.getTasks()
		this.listType = localStorage.getItem('tasksListFilter')
			? localStorage.getItem('tasksListFilter')
			: 'todayTasks'
	},
	methods: {
		setFilteredTasks(list) {
			let newArray = []
			const pushOpened = (array) => {
				array.forEach((task) => {
					newArray.push(task)
					if (this.openedTasks.includes(task.id)) {
						pushOpened(task.nested)
					}
				})
			}
			pushOpened(list)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'filteredTasks',
					value: newArray,
				},
				{ root: true }
			)
		},
		getTasks() {
			this.$route.params.token
				? this.$store.dispatch(
						'Tasks/getTasksByToken',
						this.requestParams
				  )
				: this.$store.dispatch('Tasks/getTasks', this.requestParams)
		},
		addTask() {
			if (this.newTaskTitle == '') return
			const data = { ...this.newTask }
			data.title = this.newTaskTitle
			data.minute_count = 480
			data.project_id = this.$route.params.projectId
			this.$store
				.dispatch('Tasks/addTask', data)
				.then(() => {
					this.$toast.success(`Задача создана`)
					this.$store.dispatch('Tasks/getTasks', {
						projectId: data.project_id,
					})
				})
				.catch(() => {
					this.$toast.error(`Ошибка`)
				})
			this.newTaskTitle = ''
		},
		changeSort(sort_by) {
			if (sort_by !== this.sort_by) {
				this.sort_by = sort_by
				this.sort_dir = 'asc'
			} else if (sort_by === this.sort_by && this.sort_dir === 'asc') {
				this.sort_dir = 'desc'
			} else {
				this.sort_by = null
				this.sort_dir = 'asc'
			}
			this.getTasks()
		},
		changeOrder(e) {
			if (!this.hasAccess) return
			if (e.oldIndex !== e.newIndex) {
				const data = changeOrders(this.taskList, e.oldIndex, e.newIndex)
				this.$store.dispatch('Tasks/changeTaskOrder', data)
			}
			this.drag = false
		},
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';
.switcher-wrap {
	display: flex;
	align-items: flex-start;
}
.switch-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $grey;
	border-radius: 1rem;
	padding: 0.4rem;
	width: max-content;
}

.active-button {
	position: absolute;
	background-color: $white;
	top: 0;
	left: 0;
	border-radius: 0.8rem;
	transition: 0.1s ease-out;
}

.switch-button {
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: $grey3;
	padding: 0.3rem 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
	transition: color 0.2s ease-out;

	path {
		transition: stroke 0.2s ease-out;
	}

	&.active {
		color: $black;

		path {
			stroke: $black;
		}
	}
}

.switch-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.4rem;
	height: 1.4rem;
	margin-right: 0.4rem;
}

.gantt-list {
	padding-bottom: 3.8rem;
	.add-task-header,
	.task-list__header-sort-arrow,
	.comments-column,
	.dates-column {
		display: none;
	}

	.tasks-list-columns {
		min-width: 55.5rem;
		grid-template-columns: 1fr 8rem 8rem 11rem;
	}

	.task-list {
		position: relative;
		padding-bottom: 3.8rem;
	}

	.task-list__time-switcher {
		// position: sticky;
		// top: 0;
	}
}

.task-list {
	min-height: calc(100vh - 20rem);
	padding-bottom: 5rem;
	display: flex;
	flex-direction: column;
}

.no-tasks {
	flex-grow: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #d2d2d2;
	font-size: 1.8rem;
	line-height: 2.5rem;
	text-align: center;
}

.task-list__time-switcher {
	margin-bottom: 1.7rem;
}

.task-list__header {
	width: 100%;
	margin-bottom: 2.2rem;
}

.task-list-table {
	margin-left: -1.6rem;
}

.task-list__header-column {
	padding-right: 2rem;
	color: $dark-grey;
}

.task-list__header-column-content {
	width: max-content;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	cursor: pointer;
	user-select: none;
	font-weight: 500;

	&--active {
		color: $green-active;
	}
}

.task-list__header-column-content {
	width: max-content;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	cursor: pointer;
	user-select: none;
	font-weight: 500;

	&--active {
		color: $green-active;
	}
}

.task-list__header-sort-arrow {
	margin-left: 0.2rem;

	&--desc {
		transform: rotate(180deg);
	}
}

.add-task-header {
	font-size: 1.4rem;
	margin-bottom: 1rem;

	.task-list__header-column-content {
		font-weight: 400;
	}
}

.add-task-header-first-column {
	padding-left: 1.5rem;

	.task-list__header-column-content,
	input {
		width: 100%;
	}
}

.add-task-header__add-user {
	width: 11.5rem;
}

.add-task-header__plus-icon {
	margin-right: 1.2rem;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.add-task-header__task-priority {
	margin-right: 0.8rem;
}

.add-task-header__input {
	border: none;
	font-size: 1.4rem;

	&::placeholder {
		color: $dark-grey;
	}
}
</style>
