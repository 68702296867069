<template>
	<div class="comment-group" @click="$emit('openComments')">
		<div class="comment-group-header">
			<div class="comment-group-author">
				Пин от
				<router-link to="/" class="comment-group-author-link">{{
					pin.user.name
				}}</router-link>
			</div>
			<p class="comment-group-date">{{ dateFormat(pin.date) }}</p>
		</div>

		<div class="comment-group-users">
			<div class="comment-group-users-list">
				<div
					v-for="commentator in pin.commentators"
					:key="commentator.id"
					class="comment-group-user"
				>
					<img
						v-if="commentator.image"
						:src="commentator.image"
						alt="people"
					/>

					<img
						v-else
						src="@/assets/img/icons/avatar.svg"
						class="comments-user-image-empty"
						alt="people"
					/>
				</div>
			</div>

			<img
				src="@/assets/img/icons/arrow-right-green.svg"
				alt="arrow"
				class="comment-group-users-arrow"
			/>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		pin: {
			type: Object,
			required: true,
		},
	},
	emits: ['openComments'],
	computed: {
		dateFormat() {
			return (date) => {
				return `${moment(date).date()} ${moment(date).format(
					'MMMM'
				)} ${moment(date).year()}`
			}
		},
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';

.comment-group {
	padding: 1rem 1.2rem;
	border-radius: 1.2rem;
	transition: 0.3s;
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 2rem;
	}

	&:hover {
		background-color: $grey;
	}
}

.comment-group-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment-group-author {
	font-size: 1.4rem;
	font-weight: 500;
}

.comment-group-author-link {
	color: $green;
	text-decoration: underline;
}

.comment-group-date {
	color: $dark-grey;
	font-size: 1.4rem;
	font-weight: 500;
}

.comment-group-users {
	margin-top: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comment-group-users-list {
	display: flex;
}

.comment-group-user {
	width: 2.8rem;
	height: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background-color: #333333;
	border: 0.2rem solid #04b1f4;

	&:not(:first-child) {
		margin-left: 0.4rem;
	}

	img {
		width: calc(100% - 0.2rem);
		height: calc(100% - 0.2rem);
		border-radius: 100%;
		object-fit: cover;
	}
}
</style>
