/* eslint-disable */
import {
	ADD_PROJECT_URL,
	GET_PROJECTS_URL,
	GET_FAVORITES_URL,
	GET_SEARCH_URL,
	GET_PROJECT_BY_TOKEN,
	DELETE_USER_URL,
	createFavoriteUrl,
	createUsersUrl,
	getProjectUsersUrl,
	GET_SINGLE_PROJECT,
	NOTIFICATIONS_URL,
	ADD_USER_TO_PROJECT_URL,
} from '@/common/constants'
import { SET_ENTITY } from '@/store/mutation-types'
import router from '@/router/index'
import axios from 'axios'

export default {
	namespaced: true,
	state: {
		projects: [],
		favorites: [],
		searchList: [],
		single: null,
		users: [],
		created: false,
		errorProject: false,
		activeTab: 'gantt',
		activeModal: '',
		openedModal: false,
		projectUsers: null,
		openedTabs: [],
		ganttScale: 50,
		token: null,
		notifications: [],
	},
	getters: {
		projectUsersOptions: (state) => {
			if (state.projectUsers) {
				const users = [...state.projectUsers]
				return users.map((el) => ({
					label: el.user.name == null ? el.user.email : el.user.name,
					value: el.user.id,
				}))
			}
			return []
		},
	},
	actions: {
		async addProject({ commit, dispatch }, data) {
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'errorProject', value: false },
				{ root: true }
			)
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'created', value: false },
				{ root: true }
			)
			await axios
				.post(ADD_PROJECT_URL, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					commit(
						SET_ENTITY,
						{ module: 'Projects', entity: 'created', value: true },
						{ root: true }
					)
					dispatch('getProjects')
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{
							module: 'Projects',
							entity: 'errorProject',
							value: true,
						},
						{ root: true }
					)
				})
		},

		async updateProject({ commit }, data) {
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'errorProject', value: false },
				{ root: true }
			)
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'created', value: false },
				{ root: true }
			)
			let url = `${GET_SINGLE_PROJECT}${data.id}`
			await axios
				.put(url, data.body)
				.then((response) => {
					commit(
						SET_ENTITY,
						{ module: 'Projects', entity: 'created', value: true },
						{ root: true }
					)
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{
							module: 'Projects',
							entity: 'errorProject',
							value: true,
						},
						{ root: true }
					)
				})
		},

		async uploadBackground({ commit }, data) {
			await axios
				.post(`${ADD_PROJECT_URL}/${data.id}/background`, data.data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					console.log(response)
				})
		},

		async deleteBackground({ commit }, id) {
			await axios
				.delete(`${ADD_PROJECT_URL}/${id}/background`)
				.then((response) => {
					console.log(response)
				})
		},

		async deleteProject({ commit }, id) {
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'errorProject', value: false },
				{ root: true }
			)
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'created', value: false },
				{ root: true }
			)
			let url = `${GET_SINGLE_PROJECT}${id}`
			await axios
				.delete(url)
				.then((response) => {
					commit(
						SET_ENTITY,
						{ module: 'Projects', entity: 'created', value: true },
						{ root: true }
					)
					let openedTabs =
						localStorage.getItem('openedTabs') !== null
							? JSON.parse(localStorage.getItem('openedTabs'))
							: []
					if (openedTabs.length > 0) {
						openedTabs = openedTabs.filter((el) => el.id != id)
						localStorage.setItem(
							'openedTabs',
							JSON.stringify(openedTabs)
						)
						commit(
							SET_ENTITY,
							{
								module: 'Projects',
								entity: 'openedTabs',
								value: openedTabs,
							},
							{ root: true }
						)
					}
					setTimeout(() => {
						router.push('/dashboard')
					}, 2000)
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{
							module: 'Projects',
							entity: 'errorProject',
							value: true,
						},
						{ root: true }
					)
				})
		},

		async deleteUser({ commit }, id) {
			let url = `${DELETE_USER_URL}${id}`
			await axios
				.delete(url)
				.then((response) => {
					console.log(response)
				})
				.catch((error) => {
					console.log(error)
				})
		},

		async duplicateProject({ commit }, id) {
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'errorProject', value: false },
				{ root: true }
			)
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'created', value: false },
				{ root: true }
			)
			let url = `${GET_SINGLE_PROJECT}${id}/duplicate`
			await axios
				.post(url)
				.then((response) => {
					commit(
						SET_ENTITY,
						{ module: 'Projects', entity: 'created', value: true },
						{ root: true }
					)
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{
							module: 'Projects',
							entity: 'errorProject',
							value: true,
						},
						{ root: true }
					)
				})
		},

		async getProjects({ commit }, query) {
			const params = query ? query : router?.currentRoute?._value?.query
			await axios
				.get(GET_PROJECTS_URL, { params: params })
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Projects',
							entity: 'projects',
							value: response.data.data,
						},
						{ root: true }
					)
				})
			await axios.get(GET_FAVORITES_URL).then((response) => {
				commit(
					SET_ENTITY,
					{
						module: 'Projects',
						entity: 'favorites',
						value: response.data.data,
					},
					{ root: true }
				)
			})
		},

		async getSearchList({ commit }, query) {
			await axios
				.get(GET_SEARCH_URL, { params: query })
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Projects',
							entity: 'searchList',
							value: response.data.data,
						},
						{ root: true }
					)
				})
		},

		async addToFavorites({ dispatch }, id) {
			let url = createFavoriteUrl(id)
			await axios.post(url).then((response) => {
				console.log(response)
			})
		},

		async deleteFromFavorites({ dispatch }, id) {
			let url = createFavoriteUrl(id)
			await axios.delete(url).then((response) => {
				console.log(response)
			})
		},

		async getSingleProject({ commit, dispatch }, id) {
			let url = `${GET_SINGLE_PROJECT}${id}`
			await axios.get(url).then((response) => {
				let data = response.data.data
				// commit(
				// 	SET_ENTITY,
				// 	{ module: 'Tasks', entity: 'filteredTasks', value: null },
				// 	{ root: true }
				// )

				commit(
					SET_ENTITY,
					{ module: 'Projects', entity: 'single', value: data },
					{ root: true }
				)

				let singleData = {
					title: data.name,
					id: data.id,
				}

				if (localStorage.getItem('openedTabs') !== null) {
					let openedTabs = JSON.parse(
						localStorage.getItem('openedTabs')
					).length
						? JSON.parse(localStorage.getItem('openedTabs'))
						: [JSON.parse(localStorage.getItem('openedTabs'))]
					if (
						!JSON.stringify(openedTabs).includes(
							JSON.stringify(singleData)
						)
					) {
						if (openedTabs.length >= 5) openedTabs.splice(0, 1)

						let updatedElement = openedTabs.find(
							(el) => el.id == singleData.id
						)
						if (updatedElement) {
							console.log(updatedElement)
							openedTabs.splice(
								openedTabs.indexOf(updatedElement),
								1
							)
						} else {
							openedTabs.push(singleData)
						}

						localStorage.setItem(
							'openedTabs',
							JSON.stringify(openedTabs)
						)
					}
				} else {
					localStorage.setItem('openedTabs', [
						JSON.stringify(singleData),
					])
				}

				dispatch('getOpenedTabs')
			})
		},

		async getToken({ commit }, id) {
			let url = `${GET_SINGLE_PROJECT}${id}/tokens`
			await axios.get(url).then((response) => {
				commit(
					SET_ENTITY,
					{
						module: 'Projects',
						entity: 'token',
						value: response.data.data[0],
					},
					{ root: true }
				)
			})
		},

		async editToken({ dispatch }, data) {
			let url = `${GET_SINGLE_PROJECT}tokens/${data.id}`
			await axios.put(url, data.body).then((response) => {
				dispatch('getToken', data.project_id)
			})
		},

		async refreshToken({ dispatch }, data) {
			let url = `${GET_SINGLE_PROJECT}tokens/${data.id}/refresh`
			await axios.post(url).then((response) => {
				dispatch('getToken', data.project_id)
			})
		},

		async sendInvite({ dispatch }, data) {
			let url = `${GET_SINGLE_PROJECT}${data.project_id}/users/invite`
			await axios
				.post(url, data.body)
				.then((response) => {
					console.log(response)
				})
				.catch((error) => {
					console.log(error)
				})
		},

		async updateAccess({ dispatch }, data) {
			let url = `${GET_SINGLE_PROJECT}${data.project_id}/users/`
			await axios
				.post(url, data.body)
				.then((response) => {
					console.log(response)
				})
				.catch((error) => {
					console.log(error)
				})
		},

		async getUsers({ commit }, id) {
			let url = createUsersUrl(id)
			await axios.get(url).then((response) => {
				commit(
					SET_ENTITY,
					{
						module: 'Projects',
						entity: 'users',
						value: response.data.data,
					},
					{ root: true }
				)
			})
		},

		async getProjectUsers({ commit }, id) {
			let url = getProjectUsersUrl(id)
			await axios.get(url).then((response) => {
				commit(
					SET_ENTITY,
					{
						module: 'Projects',
						entity: 'projectUsers',
						value: response.data.data,
					},
					{ root: true }
				)
			})
		},

		async getOpenedTabs({ commit }) {
			let openedTabs =
				localStorage.getItem('openedTabs') !== null
					? JSON.parse(localStorage.getItem('openedTabs'))
					: []
			commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedTabs', value: openedTabs },
				{ root: true }
			)
		},

		async getProjectByToken({ commit }) {
			await axios
				.get(GET_PROJECT_BY_TOKEN)
				.then((response) => {
					let data = response.data.data
					commit(
						SET_ENTITY,
						{ module: 'Projects', entity: 'single', value: data },
						{ root: true }
					)
				})
				.catch(() => {
					router.push('/dashboard')
				})
		},

		async getNotifications({ dispatch, commit }) {
			axios.get(NOTIFICATIONS_URL).then((response) => {
				commit(
					SET_ENTITY,
					{
						module: 'Projects',
						entity: 'notifications',
						value: response.data.data,
					},
					{ root: true }
				)
			})
		},

		async addUserToProject() {
			await axios.post(ADD_USER_TO_PROJECT_URL).then((response) => {
				console.log(response)
			})
		},

		async markNotifications({ dispatch, commit }) {
			axios.post(`${NOTIFICATIONS_URL}/mark-as-read`).then((response) => {
				dispatch('getNotifications')
			})
		},
	},
}
