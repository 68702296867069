/* eslint-disable */
import { EMAIL_REGEX } from '@/common/constants'

const rules = {
	isNotEmpty: {
		rule: (value) => !!value?.trim(),
		message: 'Поле не заполнено',
	},
	required: {
		rule: (value) => !!value?.trim(),
		message: 'Поле обязательно для заполнения',
	},
	email: {
		rule: (value) =>
			!!value
				? EMAIL_REGEX.test(String(value).trim().toLowerCase())
				: true,
		message: 'Неверный формат e-mail',
	},
	password: {
		rule: (value) => (value ? value.length >= 8 : true),
		message: 'Пароль должен быть длиннее 8 символов',
	},
	password_confirmation: {
		rule: (value) =>
			value ? value[0] == value[1] && value[0] !== '' : true,
		message: 'Пароли должны совпадать',
	},
}

/**
 *
 * @param {String|Array} value
 * @param {*} appliedRules
 * @returns {String} Error message
 */
const validator = (value, appliedRules) => {
	let error = ''
	appliedRules.forEach((appliedRule) => {
		if (!rules[appliedRule]) {
			return
		}
		const { rule, message } = rules[appliedRule]
		if (!rule(value)) {
			error = message
		}
	})
	return error
}

export default {
	methods: {
		validateFields(fields, validations) {
			let isValid = true
			Object.keys(fields).forEach((key) => {
				validations[key].error = validator(
					fields[key],
					validations[key].rules
				)
				if (validations[key].error) {
					isValid = false
				}
			})
			return isValid
		},
		clearValidationErrors() {
			if (!this.validations) {
				return
			}
			Object.values(this.validations).forEach((validator) => {
				validator['error'] = ''
			})
		},
	},
}
