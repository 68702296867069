<template>
	<header
		:class="user !== null && user?.background !== null ? 'white' : ''"
		class="main-header"
	>
		<nav class="container header-wrap">
			<ul class="site-nav">
				<li class="nav-item">
					<router-link to="/dashboard" class="logo">
						<img src="@/assets/img/logo.svg" />
					</router-link>
				</li>
				<li
					v-for="tab of openedTabs"
					v-show="tab.id && user"
					:key="tab.id"
					class="nav-item tab"
				>
					<router-link :to="`/projects/${getLink(tab?.id)}`">{{
						tab.title
					}}</router-link>
					<button
						class="close-tab"
						type="button"
						@click="closeTab(tab)"
					>
						<svg
							width="16"
							height="17"
							viewBox="0 0 16 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11 5.5L5 11.5"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M5 5.5L11 11.5"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>
				</li>
				<li class="nav-item">
					<button
						:class="!user ? 'disabled' : ''"
						class="plus"
						type="button"
						@click="openModal"
					>
						<img src="@/assets/img/icons/plus.svg" />
					</button>
				</li>
			</ul>
			<ul class="user-nav">
				<li class="notify">
					<button
						type="button"
						:class="!user ? 'disabled' : ''"
						class="button-icon"
						aria-label="Уведомления"
						@click="openNotify"
					>
						<svg
							width="23"
							height="23"
							viewBox="0 0 23 23"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 9.66675C15 8.34067 14.4732 7.0689 13.5355 6.13121C12.5979 5.19353 11.3261 4.66675 10 4.66675C8.67392 4.66675 7.40215 5.19353 6.46447 6.13121C5.52678 7.0689 5 8.34067 5 9.66675C5 15.5001 2.5 17.1667 2.5 17.1667H17.5C17.5 17.1667 15 15.5001 15 9.66675Z"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M11.4419 20.5C11.2954 20.7526 11.0851 20.9622 10.8321 21.1079C10.5791 21.2537 10.2922 21.3304 10.0003 21.3304C9.70828 21.3304 9.42142 21.2537 9.1684 21.1079C8.91539 20.9622 8.7051 20.7526 8.55859 20.5"
								stroke="#C4C4C4"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<circle
								v-if="notifications?.unread_count > 0"
								cx="19.5"
								cy="3.5"
								r="3"
								fill="#00D47D"
							/>
						</svg>
					</button>
				</li>
				<li class="search">
					<button
						aria-label="Поиск по проектам"
						:class="!user ? 'disabled' : ''"
						class="search-button"
						type="button"
						@click="
							;(openedSearch = !openedSearch),
								$refs.searchInput.focus()
						"
					>
						<img src="@/assets/img/icons/search.svg" alt="Поиск" />
					</button>

					<div
						:class="openedSearch ? 'open' : ''"
						class="search-container"
					>
						<input
							ref="searchInput"
							v-model="search"
							type="text"
							class="search-input"
							@input="
								$store.dispatch('Projects/getSearchList', {
									query: search,
								})
							"
						/>

						<div
							v-if="searchList.length && openedSearch"
							class="search-results"
						>
							<div class="results-wrapper">
								<a
									v-for="project of searchList"
									:key="project.id"
									href="#"
									@click="
										$router.push(
											`/projects/${getLink(project.id)}`
										),
											(openedSearch = false)
									"
									>{{ project.name }}</a
								>
							</div>
						</div>
					</div>
				</li>
				<li :class="openedSearch ? 'hide' : ''" class="user-data">
					<UserPart />
				</li>
			</ul>
		</nav>
	</header>
</template>

<script>
import UserPart from '@/common/components/parts/UserPart'
import { SET_ENTITY } from '@/store/mutation-types'
import { mapState } from 'vuex'
export default {
	components: {
		UserPart,
	},
	data: () => {
		return {
			openedSearch: false,
			search: '',
		}
	},

	computed: {
		...mapState('Projects', ['searchList', 'openedTabs', 'notifications']),
		...mapState('Auth', ['user']),
		hasAccess() {
			return !this.user == null
		},
	},

	watch: {
		openedSearch(newVal) {
			if (!newVal) {
				this.search = ''
				this.$store.dispatch('Projects/getSearchList', {})
			}
		},
	},

	created() {
		this.$store.dispatch('Projects/getNotifications')
	},

	mounted() {
		this.$store.dispatch('Projects/getOpenedTabs')
	},

	methods: {
		openModal() {
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: true },
				{ root: true }
			)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Projects',
					entity: 'activeModal',
					value: 'newProject',
				},
				{ root: true }
			)
		},
		openNotify() {
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: true },
				{ root: true }
			)
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'activeModal', value: 'notify' },
				{ root: true }
			)
		},
		closeTab(tab) {
			let tabs = this.openedTabs.filter((el) => el.id != tab.id)
			localStorage.setItem('openedTabs', JSON.stringify(tabs))
			this.$store.dispatch('Projects/getOpenedTabs')
		},
		getLink(id) {
			// id = id.toString()
			// return btoa(id)
			return id
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.main-header {
	background-color: $white;
	position: sticky;
	z-index: 2;
	min-height: 5.5rem;
	top: 0;
	left: 0;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.04);

	&.white {
		background-color: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(4px);

		.search-container {
			background-color: transparent;

			input {
				background-color: transparent;
			}
		}
	}
}

.tab {
	display: flex;
	align-items: center;
}

.disabled {
	pointer-events: none;
}

.close-tab {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 0.5rem;

	path {
		transition: stroke 0.1s ease-out;
	}

	&:hover {
		path {
			stroke: $dark-grey;
		}
	}
}

.logo {
	display: flex;
	max-width: 3.2rem;
	justify-content: center;
	align-items: center;
}

.plus {
	display: flex;
	width: 2rem;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.header-wrap {
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
	display: flex;
	justify-content: space-between;
}

.site-nav {
	padding: 0.2rem 0;
	display: flex;
	align-items: center;
}

.user-nav,
.user-data {
	display: flex;
	align-items: center;
}

.user-data {
	&.hide {
		opacity: 0;
		pointer-events: none;
	}
}

.nav-item {
	padding: 0 2.4rem;
	border-left: 1px solid #c4c4c4;
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: $black;

	&:first-of-type {
		padding-left: 0;
		border-left: none;
	}

	&:last-of-type {
		padding-right: 0;
	}
}

.notify {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 2rem;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.search {
	margin-right: 6rem;
	position: relative;
}

.search-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.4rem;
}

.search-container {
	position: absolute;
	left: calc(100% + 1rem);
	top: -1rem;
	height: calc(100% + 2rem);
	background-color: $white;
	z-index: 3;
	pointer-events: none;
	display: flex;
	align-items: center;

	input {
		width: 0;
		transition: 0.4s ease-out;
	}

	&.open {
		input {
			width: 20rem;
		}
		pointer-events: all;
	}
}

.search-results {
	position: absolute;
	top: 100%;
	width: 20rem;
	z-index: 2;
	a {
		padding: 1.2rem 1.6rem;
		font-size: 1.5rem;
		line-height: 2rem;
		background-color: $white;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			top: 50%;
			right: 1.6rem;
			width: 1.6rem;
			height: 1.6rem;
			transform: translateY(-50%);
			background: url('@/assets/img/icons/chevron-right.svg') center
				center no-repeat;
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid $grey;
		}

		&:hover {
			background-color: $grey;
		}

		&:active {
			background-color: $grey-hover2;
		}
	}

	&::before {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		left: 50%;
		bottom: 100%;
		border: 0.5rem solid transparent;
		border-bottom-color: $white;
		transform: translateX(-50%);
		z-index: 3;
	}
}

.results-wrapper {
	border-radius: 0.8rem;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	overflow: hidden;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);
}

.search-input {
	outline: none;
	border: none;
	border-bottom: 1px solid $grey-text;
	caret-color: $grey-text;
	padding-bottom: 0.2rem;
}
</style>
