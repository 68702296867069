<template>
	<transition>
		<PagePreloader v-if="preload" />
	</transition>
	<div class="main-layout">
		<AppHeaderMain />
		<slot />
	</div>
</template>

<script>
import PagePreloader from '@/common/components/PagePreloader'
import AppHeaderMain from '@/layouts/AppHeaderMain.vue'
export default {
	components: {
		AppHeaderMain,
		PagePreloader,
	},
	data() {
		return {
			preload: true,
		}
	},
	mounted() {
		setTimeout(() => {
			this.preload = false
		}, 1000)
	},
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
