<template>
	<div class="dropdown-wrapper">
		<div
			:class="showDropdown ? 'open' : ''"
			class="input dropdown-input"
			@click="showDropdown = !showDropdown"
			v-html="options[selectedOption]"
		></div>
		<ul v-if="showDropdown" class="dropdown-container">
			<li
				v-for="(value, name) in options"
				:key="value"
				:class="selectedOption == name ? 'active' : ''"
				class="option"
				@click="changeOption(name)"
				v-html="value"
			></li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		selectedOption: {
			type: String,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
	},
	emits: ['changeOption'],
	data: () => {
		return {
			showDropdown: false,
		}
	},
	methods: {
		changeOption(option) {
			this.showDropdown = false
			this.$emit('changeOption', option)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.dropdown-wrapper {
	position: relative;
}
.dropdown-container {
	position: absolute;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 0.8rem;
	background-color: $white;
	box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);
	z-index: 5;
}

.dropdown-input {
	position: relative;
	font-size: 1.2rem;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 1.2rem;
		width: 1.6rem;
		height: 1.6rem;
		background: url('~@/assets/img/icons/arrow-down.svg') center center
			no-repeat;
		transform: translateY(-50%);
		transition: 0.2s ease-out;
	}

	&.open::before {
		transform: translateY(-50%) rotate(180deg);
	}

	span {
		display: flex;
	}
}

.option {
	width: 100%;
	text-align: left;
	padding: 1.2rem 1.6rem;
	color: black;
	font-size: 1.4rem;
	line-height: 2rem;
	cursor: pointer;
	&:hover:not(.active) {
		background-color: $grey;
	}

	&:not(:last-of-type) {
		border-bottom: 1px solid $grey;
	}

	&.active {
		background-color: $grey-hover2;
	}

	span {
		display: flex;
	}

	svg {
		width: 1rem;
		height: 0.8rem;
	}
}
</style>
