<template>
	<Datepicker
		v-model="pickerDate"
		:min-date="min"
		:max-date="max"
		:month-change-on-scroll="false"
		:format-locale="ru"
		month-name-format="long"
		:clearable="false"
		auto-apply
		:format="format"
		locale="ru"
		:enable-time-picker="false"
		hide-offset-dates
	></Datepicker>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
import { ru } from 'date-fns/locale'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
	components: {
		Datepicker,
	},
	props: {
		date: {
			type: String,
			default: null,
		},
		min: {
			type: Date,
			default: null,
		},
		max: {
			type: Date,
			default: null,
		},
	},

	setup() {
		const months = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		]

		const format = (date) => {
			const day = date.getDate()
			const month = date.getMonth()
			const year = date.getFullYear()

			return `${day} ${months[month]} ${year}`
		}

		return {
			format,
		}
	},
	data: () => {
		return {
			ru,
			pickerDate: '',
		}
	},
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
//@import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

.dp__input {
	width: 100%;
	border: none;
	outline: none;
	border-radius: 0.8rem;
	padding: 1rem 1.2rem;
	background-color: $grey;
	color: $dark-grey;
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 400;
	border: 1px solid transparent;
	caret-color: $green;
	color: $black;
	font-family: 'SFProDisplay';

	&:hover {
		border: 1px solid $dark-grey;
	}
}
.dp__input_icon {
	left: unset;
	right: 1rem;
}
.dp__month_year_col_nav {
	order: 2;

	&:first-of-type {
		margin-left: auto;
	}
}

.dp__menu {
	outline: none;
	border: none;
	border-radius: 2rem;
	box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);

	&:focus {
		border: none;
	}
}

.dp__arrow_top {
	border: none;
	top: 0;
}

.dp__month_year_select {
	text-transform: capitalize;
	width: max-content;
	color: $green;
	font-weight: 500;
	font-size: 1.8rem;
	margin-right: 0.4rem;

	&:hover {
		background-color: transparent;
		color: $green-hover;
	}
}

.dp__month_year_row {
	padding: 0.8rem 1rem 0 1.6rem;
	box-sizing: content-box;
}

.dp__inner_nav {
	.dp__icon {
		fill: $green;
		stroke: $green;
	}
}
.dp__calendar_header_item {
	font: inherit;
	color: $dark-grey;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 1.4rem;
	line-height: 1.8rem;
	height: auto;
	padding-bottom: 0;
}

.dp__calendar_item {
	&:nth-child(6),
	&:nth-child(7) {
		.dp__cell_inner {
			color: $red;
		}
	}
}

.dp__cell_inner {
	font: inherit;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: $black;
	min-height: 25px;
	min-width: 35px;
	//height: max-content;
	border-radius: 100%;
	border: 1px solid transparent;
	transition: 0.2s ease-out;

	&:hover:not(.dp__active_date) {
		background-color: transparent;
		border-color: $green;
	}
}

.dp__active_date {
	color: $white !important;
	background-color: $green;
}

.dp__calendar_header_separator {
	display: none;
}

.dp__overlay_cell_active {
	background: $green;
}

.dp__input_icons {
	width: 1.6rem;
	height: 1.6rem;
}

.dp__overlay_cell_pad {
	font-size: 1.6rem;
}
</style>
