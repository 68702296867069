<template>
	<label class="input-row">
		<span class="label">{{ label }}</span>
		<div class="input-wrapper">
			<slot />
			<button
				v-if="type == 'password'"
				:class="buttonClose ? 'close' : ''"
				class="eye"
				type="button"
				@click.prevent="toggleInput"
			/>
		</div>
		<span v-if="info" class="info">{{ info }}</span>
		<span v-if="error" class="error">{{ error }}</span>
	</label>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: null,
		},
		info: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
	},
	emits: ['toggleInput'],
	data: () => {
		return {
			buttonClose: false,
		}
	},
	methods: {
		toggleInput() {
			this.buttonClose = !this.buttonClose
			this.$emit('toggleInput')
		},
	},
}
</script>

<style scoped>
.label {
	min-height: 1.8rem;
}
</style>
