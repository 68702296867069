<template>
	<swiper
		:modules="modules"
		:navigation="{ clickable: true }"
		:slides-per-view="4"
		:space-between="28"
	>
		<swiper-slide v-for="project of favorites" :key="project.id">
			<ProjectCard
				:project="project"
				:background="project?.background"
				@filter="$emit('filterArray', project.id)"
			/>
		</swiper-slide>
	</swiper>
</template>

<script>
import ProjectCard from '@/modules/dashboard/ProjectCard'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, A11y } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css'
export default {
	components: {
		ProjectCard,
		SwiperSlide,
		Swiper,
	},
	props: {
		favorites: {
			type: Array,
			default: null,
		},
	},
	emits: ['filterArray'],
	data: () => {
		return {
			modules: [Navigation, A11y],
		}
	},
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
// .mask{
//     position: absolute;
//     height: 100%;
//     width: 3rem;
//     top: 0;
//     left: 0;
//     z-index: 2;
//     background: linear-gradient(90deg, $grey 35%, rgba(248,248,248,0.022846638655462215) 96%);

//     &--right{
//         left: unset;
//         right: 0;
//         background: linear-gradient(90deg, rgba(248,248,248,0.022846638655462215) 4%, $grey 90%);
//     }
// }

.swiper {
	padding: 0 6rem;
	mask-image: linear-gradient(to left, transparent 0%, black 14%);
	-webkit-mask-image: linear-gradient(to left, transparent 0%, black 14%);

	// &::before{
	//     content: "";
	//     width: 100%;
	//     height: 100%;
	//     position: absolute;
	//     top: 0;
	//     left: 0;
	//     mask-image: linear-gradient(to left, transparent 0%, black 14%);
	//     -webkit-mask-image: linear-gradient(to left, transparent 0%, black 14%);
	// }
}

.swiper-button-next,
.swiper-button-prev {
	width: 2rem;
	height: 2rem;
	min-width: 2rem;
	background-color: $grey;
	border-radius: 0.6rem;

	&::after {
		width: 1rem;
		height: 1rem;
		content: '';
		background: url('@/assets/img/icons/arrow-dark.svg') center center
				no-repeat,
			$grey;
		background-size: 100%;
	}
}

.swiper-button-next::after {
	transform: rotate(-90deg);
}
.swiper-button-prev::after {
	transform: rotate(90deg);
}
</style>
