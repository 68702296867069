<template>
	<Head>
		<title>{{ single?.name }}</title>
	</Head>
	<div class="container">
		<transition name="fade" mode="out-in">
			<component :is="currentTab"></component>
		</transition>
	</div>
	<transition>
		<ModalOverlay v-if="openedModal" @close-modal="closeModal" />
	</transition>
	<transition name="slide-fade">
		<ModalSide
			v-if="openedModal && currentModal !== null"
			:title="currentModalTitle"
			@close-modal="closeModal"
		>
			<component
				:is="currentModal"
				:update="true"
				@close-modal="closeModal"
			></component>
		</ModalSide>
	</transition>
	<transition>
		<AccessModal
			v-if="openedModal && activeModal == 'access'"
			@close-modal="closeModal"
		/>
	</transition>
</template>

<script>
import ModalOverlay from '@/common/components/modals/ModalOverlay'
import ModalSide from '@/common/components/modals/ModalSide'
import AccessModal from '@/modules/single/AccessModal.vue'
import GanttSection from '@/modules/gantt/GanttSection'
import { SET_ENTITY } from '@/store/mutation-types'
import { SOCKET_URL } from '@/common/constants'
import TaskList from '@/views/TaskListPage'
import { Head } from '@vueuse/head'
import { defineAsyncComponent } from 'vue'
import { io } from 'socket.io-client'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
	components: {
		ModalOverlay,
		ModalSide,
		AccessModal,
		Head,
	},
	data: () => {
		return {
			socket: null,
			id: '',
		}
	},
	computed: {
		...mapState('Projects', [
			'activeTab',
			'activeModal',
			'openedModal',
			'single',
		]),
		...mapState('Auth', ['user']),
		currentTab() {
			return this.activeTab == 'gantt' ? GanttSection : TaskList
		},
		currentModalTitle() {
			return this.activeModal == 'settings'
				? 'Настройки проекта'
				: this.activeModal == 'pins'
				? 'Комментарии'
				: this.activeModal == 'notify'
				? 'Уведомления'
				: this.activeModal == 'profile'
				? 'Настройки профиля'
				: this.activeModal == 'password'
				? 'Сменить пароль'
				: ''
		},
		currentModal() {
			return this.activeModal == 'settings'
				? defineAsyncComponent(() =>
						import('@/modules/dashboard/ProjectForm.vue')
				  )
				: this.activeModal == 'access'
				? null
				: this.activeModal == 'notify'
				? defineAsyncComponent(() =>
						import('@/modules/profile/NotifyModal.vue')
				  )
				: this.activeModal == 'profile'
				? defineAsyncComponent(() =>
						import('@/modules/profile/EditForm.vue')
				  )
				: this.activeModal == 'password'
				? defineAsyncComponent(() =>
						import('@/modules/profile/ChangePasswordForm.vue')
				  )
				: ''
		},
	},
	watch: {
		user(val) {
			if (val && this.$route.params.token) {
				this.$store.dispatch('Projects/getProjectByToken').then(() => {
					this.$store
						.dispatch('Projects/getSingleProject', this.single?.id)
						.then(() => {
							console.log('success')
							this.$router.push(`/projects/${this.single.id}`)
						})
						.catch(() => {
							console.log('error')
							this.$store
								.dispatch('Projects/addUserToProject')
								.then(() => {
									this.single
										? this.$router.push(
												`/projects/${this.single.id}`
										  )
										: console.log(this.single)
								})
						})
				})
			}
		},
	},
	mounted() {
		let authObj = {}
		if (this.$route.params.token) {
			axios.defaults.headers.common['X-Project-Token'] =
				this.$route.params.token
			this.$store.dispatch('Projects/getProjectByToken')
			this.$store.dispatch('Tasks/getTasksByToken')

			authObj.projectToken = this.$route.params.token
		} else {
			this.id = this.$route.params.projectId
			this.$store
				.dispatch('Projects/getSingleProject', this.id)
				.catch(() => {
					this.$router.push('/dashboard')
				})
			this.$store.dispatch('Projects/getToken', this.id)
			this.$store.dispatch('Projects/getUsers', this.id)
			this.$store.dispatch('Tasks/getTasks', { projectId: this.id })

			authObj.token = localStorage.getItem('token')
		}

		this.socket = io(SOCKET_URL, {
			auth: authObj,
		})

		this.socket.on('connect_error', (error) => console.log(error))
		this.socket.on('connect', () => {
			console.log(this.socket.id)
			this.socket.on('NewNotification', () =>
				this.$route.params.token !== undefined
					? console.log('anonymus')
					: this.$store.dispatch('Projects/getNotifications')
			)
			this.socket.on('ProjectUpdated', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Projects/getProjectByToken')
					: this.$store.dispatch('Projects/getSingleProject', this.id)
			)
			this.socket.on('TaskCreated', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Tasks/getTasksByToken')
					: this.$store.dispatch('Tasks/getTasks', {
							projectId: this.id,
					  })
			)
			this.socket.on('TaskUpdated', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Tasks/getTasksByToken')
					: this.$store.dispatch('Tasks/getTasks', {
							projectId: this.id,
					  })
			)
			this.socket.on('TaskDeleted', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Tasks/getTasksByToken')
					: this.$store.dispatch('Tasks/getTasks', {
							projectId: this.id,
					  })
			)
			this.socket.on('TaskOrderUpdated', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Tasks/getTasksByToken')
					: this.$store.dispatch('Tasks/getTasks', {
							projectId: this.id,
					  })
			)
			this.socket.on('TaskUserAttached', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Tasks/getTasksByToken')
					: this.$store.dispatch('Tasks/getTasks', {
							projectId: this.id,
					  })
			)
			this.socket.on('TaskUserDetached', () =>
				this.$route.params.token !== undefined
					? this.$store.dispatch('Tasks/getTasksByToken')
					: this.$store.dispatch('Tasks/getTasks', {
							projectId: this.id,
					  })
			)
		})
	},

	updated() {
		if (!this.$route.params.token) {
			this.id = this.$route.params.projectId
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Tasks', entity: 'tasksList', value: [] },
				{ root: true }
			)
			this.$store
				.dispatch('Projects/getSingleProject', this.id)
				.catch(() => {
					this.$router.push('/dashboard')
				})
			this.$store.dispatch('Projects/getToken', this.id)
			this.$store.dispatch('Projects/getUsers', this.id)
			this.$store.dispatch('Tasks/getTasks', { projectId: this.id })
		}
	},

	unmounted() {
		if (this.socket !== null) {
			this.socket.disconnect()
		}
	},
	methods: {
		closeModal() {
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: false },
				{ root: true }
			)
		},
	},
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(100%);
	opacity: 0.5;
}

.fade-enter-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from {
	opacity: 0;
	transform: translateX(-30%);
}
.fade-leave-to {
	transform: translateX(20%);
	opacity: 0;
}

.bounce-enter-active {
	animation: bounce-in 0.5s;
}
.bounce-leave-active {
	animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
	0% {
		transform: translate(-50%, -50%) scale(0);
	}
	50% {
		transform: translate(-50%, -50%) scale(1.25);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
