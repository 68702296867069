<template>
	<div
		class="task-row"
		:style="{ 'margin-left': level !== 0 ? 2.2 + 'rem' : 0 }"
	>
		<div
			v-if="currentTask"
			class="task-row__main-task tasks-list-columns"
			:style="openedSub ? `min-width: ${57 - level * 2.2}rem;` : ''"
			:class="{
				'task-row__main-task--done': currentTask.status === 'done',
				'task-row__main-task--parent': currentTask?.nested?.length,
			}"
		>
			<div
				class="task-row__column task-row__column-first"
				:style="openedSub ? `max-width: ${30 - level * 2.2}rem;` : ''"
				@click="changeTask"
			>
				<div
					v-for="(item, index) in level"
					:key="index"
					class="task-row__level-space"
					:class="`task-row__level-space-${index + 1}`"
					:style="`left: -${index * 2.2 + 1}rem;`"
				></div>
				<div
					class="task-row__column-body"
					:style="
						!currentTask.nested.length ? 'padding-left: 1.3rem' : ''
					"
				>
					<slot name="handle" />
					<div
						v-if="currentTask?.nested?.length"
						class="task-row__list-opener"
						:class="{ 'task-row__list-opener--open': openSub }"
						@click.stop="showNested"
					>
						<img src="@/assets/img/icons/chevron-down.svg" alt="" />
					</div>
					<div
						class="task-row__status"
						:class="{
							'task-row__status--done':
								currentTask.status === 'done',
						}"
						@click.stop="changeStatus(currentTask)"
					>
						<svg
							v-if="currentTask.status === 'done'"
							width="12"
							height="13"
							viewBox="0 0 12 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10 3.5L4.5 9L2 6.5"
								stroke="#FFFFFF"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
					<div class="task-row__priority">
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3.5 11V1L8.5 3.5L3.5 6"
								:stroke="
									priorityColor(
										currentTask.status === 'done'
											? currentTask.status
											: currentTask.priority
									)
								"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
					<div class="task-row__title">{{ currentTask.title }}</div>
				</div>
			</div>
			<div class="task-row__column comments-column">
				<div class="task-row__column-body">
					<div v-if="numberComments">
						{{ numberComments }} комментариев
					</div>
					<div v-else>Нет комментариев</div>
				</div>
			</div>
			<div class="task-row__column dates-column">
				<div class="task-row__column-body">
					<ProjectDates
						:date-from="currentTask.date_from"
						:date-to="currentTask.date_to"
						:color="currentTask.status === 'done' ? 'grey' : null"
					/>
				</div>
			</div>
			<div class="task-row__column members-column">
				<div class="task-row__column-body">
					<ProjectMembers
						v-if="currentTask?.users.length > 0"
						:members="currentTask?.users"
					/>
					<div v-else>Нет исполнителей</div>
				</div>
			</div>
			<div class="task-row__column">
				<div class="task-row__column-body">
					<div class="percent-status">
						<div class="percent-status__number">
							{{ calcPercent }}%
						</div>
						<div class="percent-status__diagram">
							<div
								v-if="calcPercent !== 0"
								class="pie-diagramm pie-diagramm--animate"
								:style="`--p:${calcPercent};--c:${percentDiagramColor};`"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="task-row__column task-row__column-last">
				<div class="task-row__column-body">
					{{ calcTime(currentTask.minute_count) }}
				</div>
			</div>
		</div>
		<div
			v-if="currentTask && subtasks"
			v-show="openSub"
			class="task-row__sub-tasks"
			:style="`margin-left: ${0}rem;`"
		>
			<draggable
				v-model="subtasks"
				:animation="200"
				ghost-class="ghost"
				item-key="id"
				handle=".task-row__handle"
				:disabled="!hasAccess ? true : false"
				@start="drag = true"
				@end="changeOrder"
			>
				<template #item="{ element }">
					<TaskRow
						:data="element"
						:level="currentlevel + 1"
						:main-task-id="mainTaskId"
						:disabled="useSort || !hasAccess"
						:opened-sub="openedSub"
						:change-type="changeType"
						@change-task-status="changeStatus(element)"
					>
						<template #handle>
							<div class="task-row__handle">
								<img
									src="@/assets/img/icons/drag-handler.svg"
								/>
							</div>
						</template>
					</TaskRow>
				</template>
			</draggable>
		</div>
	</div>
</template>

<script>
import { SET_ENTITY } from '@/store/mutation-types'
import { declOfNum, priorityColor, changeOrders } from '@/common/helpers'
import TaskRow from '@/modules/taskList/TaskRow'
import ProjectMembers from '@/common/components/project/ProjectMembers'
import ProjectDates from '@/common/components/project/ProjectDates'
import moment from 'moment'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

export default {
	name: 'TaskListTableRow',
	components: {
		TaskRow,
		ProjectMembers,
		ProjectDates,
		draggable,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		level: {
			type: Number,
			default: 0,
		},
		mainTaskId: {
			type: Number,
			default: null,
		},
		useSort: {
			type: Boolean,
			default: false,
		},
		openedSub: {
			type: Boolean,
			default: false,
		},
		changeType: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			declOfNum,
			priorityColor,
			drag: false,
			commentsWords: ['комментарий', 'комментария', 'комментариев'],
			daysWords: ['день', 'дня', 'дней'],
			openSub: false,
			currentlevel: 0,
			subtasks: null,
			currentTask: null,
			parentTask: null,
			filtered: [],
			percentDiagramColors: [
				'#00D47D',
				'#EDC480',
				'#F2AD6B',
				'#FA7B58',
				'#EF4E5B',
				'#C4C4C4',
			],
		}
	},
	computed: {
		...mapState('Auth', ['user']),
		...mapState('Projects', ['users', 'single']),
		...mapState('Tasks', ['filteredTasks', 'openedTasks']),
		hasAccess() {
			if (this.user == null) return false
			//let currentUser = this.users.find((el) => el.user.id == this.user.id)

			return this.single?.role == 'owner' || this.single?.role == 'editor'
				? true
				: false
		},
		calcPercent() {
			let percent = 0
			let diffStart = moment(this.currentTask.date_from).diff(
				moment(),
				'days'
			)
			let globalDiff = moment(this.currentTask.date_from).diff(
				moment(this.currentTask.date_to),
				'days'
			)

			if (globalDiff == 0) {
				let end = moment().toDate()
				let start = moment().toDate()
				start.setHours(10)
				start.setMinutes(0)
				end.setHours(18)
				end.setMinutes(0)
				diffStart = moment
					.duration(moment().diff(moment(start)))
					.asHours()
				globalDiff = moment
					.duration(moment(end).diff(moment(start)))
					.asHours()
			}
			percent = (
				Math.abs((diffStart / globalDiff).toFixed(2)) * 100
			).toFixed(0)

			percent =
				(percent >= 100 &&
					moment(this.currentTask.date_from).isSameOrBefore(
						moment().toDate(),
						'day'
					)) ||
				moment(this.currentTask.date_to).isBefore(
					moment().toDate(),
					'day'
				)
					? 100
					: moment(this.currentTask.date_from).isSameOrBefore(
							moment().toDate(),
							'day'
					  )
					? percent
					: 0
			if (this.currentTask.status === 'done') {
				percent = 100
			}

			return Number(percent)
		},
		percentDiagramColor() {
			let color
			const percent = this.calcPercent
			if (percent <= 25) {
				color = this.percentDiagramColors[0]
			} else if (percent > 25 && percent <= 50) {
				color = this.percentDiagramColors[1]
			} else if (percent > 50 && percent <= 75) {
				color = this.percentDiagramColors[2]
			} else if (percent > 75 && percent < 100) {
				color = this.percentDiagramColors[3]
			} else if (percent === 100) {
				color = this.percentDiagramColors[5]
			}
			return color
		},
		tasksList() {
			return this.$store.state.Tasks.tasksList
		},
		numberComments() {
			let commentsCount = 0

			this.currentTask.pins.forEach((item) => {
				commentsCount += item.comments_count
			})

			return commentsCount
		},
	},
	watch: {
		changeType(val) {
			if (val) {
				this.openSub = false
				this.$store.commit(
					SET_ENTITY,
					{
						module: 'Tasks',
						entity: 'openedTasks',
						value: [],
					},
					{
						root: true,
					}
				)
			}
		},
		filteredTasks(val) {
			this.filtered = [...val]
		},
		data(val) {
			this.currentTask = { ...val }
			this.subtasks = [...val.nested]
		},
		parentTask(val) {
			if (val) {
				let doneTasks = 0
				val.nested.forEach((el) => {
					if (el.status === 'done') doneTasks++
				})

				val.status = doneTasks == val.nested.length ? 'done' : 'new'
				let taskData = { ...val }
				delete taskData['users']
				this.$store
					.dispatch('Tasks/updateTask', {
						taskId: val.id,
						data: taskData,
					})
					.then(() => {
						this.$store.dispatch('Tasks/getTasks', {
							projectId: this.$route.params.projectId,
						})
					})

				this.parentTask = null
				if (val.parent_id)
					this.getParentTask(val.parent_id, this.tasksList)
			}
		},
	},
	created() {
		this.currentlevel = this.level
		this.currentTask = { ...this.data }
		this.subtasks = [...this.data.nested]
		this.filtered = [...this.filteredTasks]
	},
	methods: {
		getParentTask(id, list) {
			this.parentTask = list.find((el) => el.id == id)
			if (!this.parentTask) {
				list.forEach((task) => {
					if (task.nested.length > 0) {
						this.getParentTask(id, task.nested)
					}
				})
			}
			return this.parentTask
		},
		showNested() {
			this.openSub = !this.openSub
			let openedTasks = [...this.openedTasks]
			let taskIndex = openedTasks.findIndex(
				(task) => task == this.currentTask.id
			)

			// if (taskIndex == -1){
			// 	openedTasks.push(this.currentTask.id)
			// 	this.openSub = true
			// } else{
			// 	openedTasks.splice(taskIndex, 1)
			// 	this.openSub = false
			// }
			taskIndex == -1
				? openedTasks.push(this.currentTask.id)
				: openedTasks.splice(taskIndex, 1)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'openedTasks',
					value: openedTasks,
				},
				{
					root: true,
				}
			)
			// let index = this.filtered.findIndex(
			// 	(el) => el.id == this.currentTask.id
			// )
			// this.currentTask.nested.forEach((el, i) => {
			// 	this.filtered.find((item) => item.id == el.id)
			// 		? this.filtered.splice(index + 1, 1)
			// 		: this.filtered.splice(index + i + 1, 0, el)
			// })
			// this.$store.commit(
			// 	SET_ENTITY,
			// 	{
			// 		module: 'Tasks',
			// 		entity: 'filteredTasks',
			// 		value: [...this.filtered],
			// 	},
			// 	{
			// 		root: true,
			// 	}
			// )
		},
		calcTime(time) {
			const days = Math.floor(time / 1440)
			const hours = Math.floor(time / 60)
			const minutes = time % 60
			if (days) {
				const word = declOfNum(days, this.daysWords)
				return `${days} ${word}`
			} else if (!minutes) {
				return `${hours} ч`
			} else if (!hours && minutes) {
				return `${minutes} мин`
			} else if (hours && minutes) {
				return `${hours} ч ${minutes} мин`
			} else {
				return `0 дней`
			}
		},
		changeStatus(task) {
			if (task.nested.length > 0 || !this.hasAccess) return
			task.status = task.status === 'new' ? 'done' : 'new'
			let taskData = { ...task }
			delete taskData['users']
			this.$store
				.dispatch('Tasks/updateTask', {
					taskId: task.id,
					data: taskData,
				})
				.then(() => {
					this.$store
						.dispatch('Tasks/getTasks', {
							projectId: this.$route.params.projectId,
						})
						.then(() => {
							if (task.parent_id)
								this.getParentTask(
									task.parent_id,
									this.tasksList
								)
						})
				})
		},
		changeOrder(e) {
			if (!this.hasAccess) return
			if (e.oldIndex !== e.newIndex) {
				const data = changeOrders(this.subtasks)
				this.$store.dispatch('Tasks/changeTaskOrder', data)
			}
			this.drag = false
		},
		changeTask() {
			if (!this.hasAccess) return
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'changedTask',
					value: this.currentTask,
				},
				{
					root: true,
				}
			)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'openModal',
					value: true,
				},
				{
					root: true,
				}
			)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'modalTitle',
					value: 'Настройки задачи',
				},
				{
					root: true,
				}
			)
		},
	},
}
</script>

<style lang="scss">
@import 'src/assets/scss/vars';

.gantt-list {
	.add-task-header,
	.task-list__header-sort-arrow,
	.comments-column,
	.dates-column {
		display: none;
	}

	.members-column {
		order: 2;
	}

	.tasks-list-columns {
		min-width: 70rem;
		grid-template-columns: 1fr 8rem 8rem 11rem;
	}

	.task-row__column-first {
		max-width: 30rem;
	}

	.task-row__column {
		height: 4.2rem;
	}

	.task-row__main-task {
		//height: 4.2rem;
	}
}

.task-row__column {
	display: flex;
	font-weight: 400;
	font-size: 1.2rem;
	flex-shrink: 0;
	height: 4.8rem;
}

.task-row__column-body {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	padding-right: 2rem;
	//overflow: hidden;
	white-space: nowrap;
}

.task-row__column-first {
	max-width: 64rem;
}

.task-row__column-first .task-row__column-body {
	padding-left: 1rem;
}

.task-row__level-space {
	position: absolute;
	top: 0;
	height: 100%;
	z-index: 1;
	//left: 1.1rem;
	padding-left: 2.5rem;
}

.task-row__main-task {
	position: relative;
	transition: 0.2s;
	cursor: pointer;
	// height: 4.8rem;
	//overflow: hidden;

	&--parent {
		&::before {
			width: calc(100% - 6rem);
			left: 7rem;
		}

		.task-row__title {
			font-weight: 500;
		}
	}

	&--done {
		.task-row__column-body::before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background: $light-grey;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 0;
		}

		.task-row__column-first .task-row__column-body::before {
			width: 84rem;
			left: 5rem;
		}

		&.task-row__main-task--parent
			.task-row__column-first
			.task-row__column-body::before {
			//width: calc(100% - 76.5rem);
			left: 7.5rem;
		}

		.task-row__column-body {
			color: $dark-grey !important;
		}
	}

	&:hover {
		background-color: $grey;
		border-radius: 0.8rem;
		.task-row__handle {
			opacity: 1;
			visibility: visible;
		}

		.task-row__level-space:first-child {
			opacity: 0;
		}
	}
}

.task-row__level-space {
	border-right: 1px solid $light-grey;
}

.task-row__handle {
	margin-right: 0.4rem;
	opacity: 0;
	visibility: hidden;

	img {
		user-select: none;
	}
}

.task-row__title {
	width: 100%;
	max-width: 37.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.task-row__list-opener {
	margin-right: 0.8rem;
	transition: 0.2s;
	transform: rotate(-90deg);
	user-select: none;

	&--open {
		transform: rotate(0);
	}
}

.task-row__status {
	width: 1.2rem;
	min-width: 1.2rem;
	height: 1.2rem;
	border: 1.5px solid $light-grey;
	border-radius: 100%;
	background: $grey;
	margin-right: 1.1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	&--done {
		background: $green;
		border-color: $green;
	}
}

.task-row__priority {
	margin-right: 1rem;
}
</style>
