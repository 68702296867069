/* eslint-disable */
import Holidays from 'date-holidays'
import 'moment-weekday-calc'
import moment from 'moment'

export function getCookie(name) {
	let matches = document.cookie.match(
		new RegExp(
			'(?:^|; )' +
				name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
				'=([^;]*)'
		)
	)
	return matches ? decodeURIComponent(matches[1]) : undefined
}

export function interpolation(value, min, max, newMin, newMax) {
	return ((value - min) / (max - min)) * (newMax - newMin) + newMin
}

export function declOfNum(number, words) {
	return words[
		number % 100 > 4 && number % 100 < 20
			? 2
			: [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
	]
}

export function priorityColor(data) {
	switch (data) {
		case 'done':
			return '#C4C4C4'
		case 2:
			return '#FA5858'
		case 1:
			return '#FFD359'
		default:
			return '#00D47D'
	}
}

export function changeOrders(arr) {
	let data = {
		order: [],
	}
	arr.forEach((el, index) => {
		data.order.push({
			task_id: el.id,
			order: index + 1,
		})
	})
	return data
}

export function getNewOrders(e, taskList) {
	let data
	if (e.newIndex - e.oldIndex === 1) {
		data = {
			order: [
				{
					task_id: taskList[e.oldIndex].id,
					order: taskList[e.newIndex].order,
				},
				{
					task_id: taskList[e.newIndex].id,
					order: taskList[e.oldIndex].order,
				},
			],
		}
	} else if (e.oldIndex < e.newIndex) {
		data = {
			order: [
				{
					task_id: taskList[e.oldIndex].id,
					order: taskList[e.newIndex].order,
				},
				{
					task_id: taskList[e.newIndex].id,
					order: taskList[e.newIndex - 1].order,
				},
			],
		}
		for (let i = e.oldIndex + 1; i < e.newIndex; i++) {
			data.order.push({
				task_id: taskList[i].id,
				order: taskList[i - 1].order,
			})
		}
	} else {
		data = {
			order: [
				{
					task_id: taskList[e.oldIndex].id,
					order: taskList[e.newIndex].order,
				},
			],
		}
		for (let i = e.newIndex; i < e.oldIndex; i++) {
			data.order.push({
				task_id: taskList[i].id,
				order: taskList[i + 1].order,
			})
		}
	}
	return data
}

export function getHolidays(year, array) {
	let hd = new Holidays('RU')
	let holidays = hd.getHolidays(year)
	holidays = holidays.concat(hd.getHolidays(Number(year) + 1))
	Array.from(holidays).forEach((holiday) => {
		for (
			let m = moment(holiday.start);
			m.isBefore(holiday.end);
			m.add(1, 'days')
		) {
			if (!array.includes(m.format('DD MMMM YYYY'))) {
				array.push(m.format('DD MMMM YYYY'))
				if (m.day() == 6) {
					array.push(m.add(1, 'days').format('DD MMMM YYYY'))
				}
				// if (m.day() == 0) {
				// 	array.push(m.add(1, 'days').format('DD MMMM YYYY'))
				// }
			}
		}
	})
}

export function dateClass(from, to, format) {
	let dateFrom = moment(from, format)
	let dateTo = moment(to, format)
	if (!moment().isBefore(dateTo)) {
		return 'grey'
	}
	if (moment().isBefore(dateFrom)) {
		return 'green'
	}

	let diffStart = moment().diff(dateFrom)
	let globalDiff = dateFrom.diff(dateTo)
	let diff = Math.abs((diffStart / globalDiff).toFixed(2))

	return diff < 0.5 ? 'green' : diff < 0.7 ? 'yellow' : 'red'
}

export function getRandomEmoji(email, id) {
	let newArray = [...email]

	const emojis = [
		'😀',
		'😃',
		'😄',
		'😁',
		'😆',
		'😅',
		'🤣',
		'😂',
		'🙂',
		'🙃',
		'😉',
		'😊',
		'😇',
		'🥰',
		'😍',
		'🤩',
		'😘',
		'😗',
		'☺',
		'😚',
		'😙',
		'😋',
		'😛',
		'😜',
		'🤪',
		'😝',
		'🤑',
		'🤗',
		'🤭',
		'🤫',
		'🤔',
		'🤐',
		'🤨',
		'😐',
		'😑',
		'😶',
		'😶‍🌫️',
		'😏',
		'😒',
		'🙄',
		'😬',
		'😮‍💨',
		'🤥',
		'😌',
		'😔',
		'😪',
		'🤤',
		'😴',
		'😷',
		'🤒',
		'🤕',
		'🤢',
		'🤮',
		'🤧',
		'🥵',
		'🥶',
		'🥴',
		'😵',
		'😵‍💫',
		'🤯',
		'🤠',
		'🥳',
		'😎',
		'🤓',
		'🧐',
		'😕',
		'😟',
		'🙁',
		'☹',
		'😮',
		'😯',
		'😲',
		'😳',
		'🥺',
		'😦',
		'😧',
		'😨',
		'😰',
		'😥',
		'😢',
		'😭',
		'😱',
		'😖',
		'😣',
		'😞',
		'😓',
		'😩',
		'😫',
		'🥱',
		'😤',
		'😡',
		'😠',
		'🤬',
		'😈',
		'👿',
		'💀',
		'☠',
		'💩',
		'🤡',
		'👹',
		'👺',
		'👻',
		'👽',
		'👾',
		'🤖',
		'😺',
		'😸',
		'😹',
		'😻',
		'😼',
		'😽',
		'🙀',
		'😿',
		'😾',
		'🙈',
		'🙉',
		'🙊',
		'💋',
		'💌',
		'💘',
		'💝',
		'💖',
		'💗',
		'💓',
		'💞',
		'💕',
		'💟',
		'❣',
		'💔',
		'❤️‍🔥',
		'❤️‍🩹',
		'❤',
		'🧡',
		'💛',
		'💚',
		'💙',
		'💜',
		'🤎',
		'🖤',
		'🤍',
		'💯',
		'💢',
		'💥',
		'💫',
		'💦',
		'💨',
		'🕳',
		'💣',
		'💬',
		'👁️‍🗨️',
		'🗨',
		'🗯',
		'💭',
		'💤',
		'👋',
		'🤚',
		'🖐',
		'✋',
		'🖖',
		'👌',
		'🤏',
		'✌',
		'🤞',
		'🤟',
		'🤘',
		'🤙',
		'👈',
		'👉',
		'👆',
		'🖕',
		'👇',
		'☝',
		'👍',
		'👎',
		'✊',
		'👊',
		'🤛',
		'🤜',
		'👏',
		'🙌',
		'👐',
		'🤲',
		'🤝',
		'🙏',
		'✍',
		'💅',
		'🤳',
		'💪',
		'🦾',
		'🦿',
		'🦵',
		'🦶',
		'👂',
		'🦻',
		'👃',
		'🧠',
		'🦷',
		'🦴',
		'👀',
		'👁',
		'👅',
		'👄',
		'👶',
		'🧒',
		'👦',
		'👧',
		'🧑',
		'👱',
		'👨',
		'🧔',
		'🧔‍♂️',
		'🧔‍♀️',
		'👨‍🦰',
		'👨‍🦱',
		'👨‍🦳',
		'👨‍🦲',
		'👩',
		'👩‍🦰',
		'🧑‍🦰',
		'👩‍🦱',
		'🧑‍🦱',
		'👩‍🦳',
		'🧑‍🦳',
		'👩‍🦲',
		'🧑‍🦲',
		'👱‍♀️',
		'👱‍♂️',
		'🧓',
		'👴',
		'👵',
		'🙍',
		'🙍‍♂️',
		'🙍‍♀️',
		'🙎',
		'🙎‍♂️',
		'🙎‍♀️',
		'🙅',
		'🙅‍♂️',
		'🙅‍♀️',
		'🙆',
		'🙆‍♂️',
		'🙆‍♀️',
		'💁',
		'💁‍♂️',
		'💁‍♀️',
		'🙋',
		'🙋‍♂️',
		'🙋‍♀️',
		'🧏',
		'🧏‍♂️',
		'🧏‍♀️',
		'🙇',
		'🙇‍♂️',
		'🙇‍♀️',
		'🤦',
		'🤦‍♂️',
		'🤦‍♀️',
		'🤷',
		'🤷‍♂️',
		'🤷‍♀️',
		'🧑‍⚕️',
		'👨‍⚕️',
		'👩‍⚕️',
		'🧑‍🎓',
		'👨‍🎓',
		'👩‍🎓',
		'🧑‍🏫',
		'👨‍🏫',
		'👩‍🏫',
		'🧑‍⚖️',
		'👨‍⚖️',
		'👩‍⚖️',
		'🧑‍🌾',
		'👨‍🌾',
		'👩‍🌾',
		'🧑‍🍳',
		'👨‍🍳',
		'👩‍🍳',
		'🧑‍🔧',
		'👨‍🔧',
		'👩‍🔧',
		'🧑‍🏭',
		'👨‍🏭',
		'👩‍🏭',
		'🧑‍💼',
		'👨‍💼',
		'👩‍💼',
		'🧑‍🔬',
		'👨‍🔬',
		'👩‍🔬',
		'🧑‍💻',
		'👨‍💻',
		'👩‍💻',
		'🧑‍🎤',
		'👨‍🎤',
		'👩‍🎤',
		'🧑‍🎨',
		'👨‍🎨',
		'👩‍🎨',
		'🧑‍✈️',
		'👨‍✈️',
		'👩‍✈️',
		'🧑‍🚀',
		'👨‍🚀',
		'👩‍🚀',
		'🧑‍🚒',
		'👨‍🚒',
		'👩‍🚒',
		'👮',
		'👮‍♂️',
		'👮‍♀️',
		'🕵',
		'🕵️‍♂️',
		'🕵️‍♀️',
		'💂',
		'💂‍♂️',
		'💂‍♀️',
		'👷',
		'👷‍♂️',
		'👷‍♀️',
		'🤴',
		'👸',
		'👳',
		'👳‍♂️',
		'👳‍♀️',
		'👲',
		'🧕',
		'🤵',
		'🤵‍♂️',
		'🤵‍♀️',
		'👰',
		'👰‍♂️',
		'👰‍♀️',
		'🤰',
		'🤱',
		'👩‍🍼',
		'👨‍🍼',
		'🧑‍🍼',
		'👼',
		'🎅',
		'🤶',
		'🧑‍🎄',
		'🦸',
		'🦸‍♂️',
		'🦸‍♀️',
		'🦹',
		'🦹‍♂️',
		'🦹‍♀️',
		'🧙',
		'🧙‍♂️',
		'🧙‍♀️',
		'🧚',
		'🧚‍♂️',
		'🧚‍♀️',
		'🧛',
		'🧛‍♂️',
		'🧛‍♀️',
		'🧜',
		'🧜‍♂️',
		'🧜‍♀️',
		'🧝',
		'🧝‍♂️',
		'🧝‍♀️',
		'🧞',
		'🧞‍♂️',
		'🧞‍♀️',
		'🧟',
		'🧟‍♂️',
		'🧟‍♀️',
		'💆',
		'💆‍♂️',
		'💆‍♀️',
		'💇',
		'💇‍♂️',
		'💇‍♀️',
		'🚶',
		'🚶‍♂️',
		'🚶‍♀️',
		'🧍',
		'🧍‍♂️',
		'🧍‍♀️',
		'🧎',
		'🧎‍♂️',
		'🧎‍♀️',
		'🧑‍🦯',
		'👨‍🦯',
		'👩‍🦯',
		'🧑‍🦼',
		'👨‍🦼',
		'👩‍🦼',
		'🧑‍🦽',
		'👨‍🦽',
		'👩‍🦽',
		'🏃',
		'🏃‍♂️',
		'🏃‍♀️',
		'💃',
		'🕺',
		'🕴',
		'👯',
		'👯‍♂️',
		'👯‍♀️',
		'🧖',
		'🧖‍♂️',
		'🧖‍♀️',
		'🧗',
		'🧗‍♂️',
		'🧗‍♀️',
		'🤺',
		'🏇',
		'⛷',
		'🏂',
		'🏌',
		'🏌️‍♂️',
		'🏌️‍♀️',
		'🏄',
		'🏄‍♂️',
		'🏄‍♀️',
		'🚣',
		'🚣‍♂️',
		'🚣‍♀️',
		'🏊',
		'🏊‍♂️',
		'🏊‍♀️',
		'⛹',
		'⛹️‍♂️',
		'⛹️‍♀️',
		'🏋',
		'🏋️‍♂️',
		'🏋️‍♀️',
		'🚴',
		'🚴‍♂️',
		'🚴‍♀️',
		'🚵',
		'🚵‍♂️',
		'🚵‍♀️',
		'🤸',
		'🤸‍♂️',
		'🤸‍♀️',
		'🤼',
		'🤼‍♂️',
		'🤼‍♀️',
		'🤽',
		'🤽‍♂️',
		'🤽‍♀️',
		'🤾',
		'🤾‍♂️',
		'🤾‍♀️',
		'🤹',
		'🤹‍♂️',
		'🤹‍♀️',
		'🧘',
		'🧘‍♂️',
		'🧘‍♀️',
		'🛀',
		'🛌',
		'🧑‍🤝‍🧑',
		'👭',
		'👫',
		'👬',
		'💏',
		'👩‍❤️‍💋‍👨',
		'👨‍❤️‍💋‍👨',
		'👩‍❤️‍💋‍👩',
		'💑',
		'👩‍❤️‍👨',
		'👨‍❤️‍👨',
		'👩‍❤️‍👩',
		'👪',
		'👨‍👩‍👦',
		'👨‍👩‍👧',
		'👨‍👩‍👧‍👦',
		'👨‍👩‍👦‍👦',
		'👨‍👩‍👧‍👧',
		'👨‍👨‍👦',
		'👨‍👨‍👧',
		'👨‍👨‍👧‍👦',
		'👨‍👨‍👦‍👦',
		'👨‍👨‍👧‍👧',
		'👩‍👩‍👦',
		'👩‍👩‍👧',
		'👩‍👩‍👧‍👦',
		'👩‍👩‍👦‍👦',
		'👩‍👩‍👧‍👧',
		'👨‍👦',
		'👨‍👦‍👦',
		'👨‍👧',
		'👨‍👧‍👦',
		'👨‍👧‍👧',
		'👩‍👦',
		'👩‍👦‍👦',
		'👩‍👧',
		'👩‍👧‍👦',
		'👩‍👧‍👧',
		'🗣',
		'👤',
		'👥',
		'👣',
		'🦰',
		'🦱',
		'🦳',
		'🦲',
		'🐵',
		'🐒',
		'🦍',
		'🦧',
		'🐶',
		'🐕',
		'🦮',
		'🐕‍🦺',
		'🐩',
		'🐺',
		'🦊',
		'🦝',
		'🐱',
		'🐈',
		'🐈‍⬛',
		'🦁',
		'🐯',
		'🐅',
		'🐆',
		'🐴',
		'🐎',
		'🦄',
		'🦓',
		'🦌',
		'🐮',
		'🐂',
		'🐃',
		'🐄',
		'🐷',
		'🐖',
		'🐗',
		'🐽',
		'🐏',
		'🐑',
		'🐐',
		'🐪',
		'🐫',
		'🦙',
		'🦒',
		'🐘',
		'🦏',
		'🦛',
		'🐭',
		'🐁',
		'🐀',
		'🐹',
		'🐰',
		'🐇',
		'🐿',
		'🦔',
		'🦇',
		'🐻',
		'🐻‍❄️',
		'🐨',
		'🐼',
		'🦥',
		'🦦',
		'🦨',
		'🦘',
		'🦡',
		'🐾',
		'🦃',
		'🐔',
		'🐓',
		'🐣',
		'🐤',
		'🐥',
		'🐦',
		'🐧',
		'🕊',
		'🦅',
		'🦆',
		'🦢',
		'🦉',
		'🦩',
		'🦚',
		'🦜',
		'🐸',
		'🐊',
		'🐢',
		'🦎',
		'🐍',
		'🐲',
		'🐉',
		'🦕',
		'🦖',
		'🐳',
		'🐋',
		'🐬',
		'🐟',
		'🐠',
		'🐡',
		'🦈',
		'🐙',
		'🐚',
		'🐌',
		'🦋',
		'🐛',
		'🐜',
		'🐝',
		'🐞',
		'🦗',
		'🕷',
		'🕸',
		'🦂',
		'🦟',
		'🦠',
		'💐',
		'🌸',
		'💮',
		'🏵',
		'🌹',
		'🥀',
		'🌺',
		'🌻',
		'🌼',
		'🌷',
		'🌱',
		'🌲',
		'🌳',
		'🌴',
		'🌵',
		'🌾',
		'🌿',
		'☘',
		'🍀',
		'🍁',
		'🍂',
		'🍃',
		'🍇',
		'🍈',
		'🍉',
		'🍊',
		'🍋',
		'🍌',
		'🍍',
		'🥭',
		'🍎',
		'🍏',
		'🍐',
		'🍑',
		'🍒',
		'🍓',
		'🥝',
		'🍅',
		'🥥',
		'🥑',
		'🍆',
		'🥔',
		'🥕',
		'🌽',
		'🌶',
		'🥒',
		'🥬',
		'🥦',
		'🧄',
		'🧅',
		'🍄',
		'🥜',
		'🌰',
		'🍞',
		'🥐',
		'🥖',
		'🥨',
		'🥯',
		'🥞',
		'🧇',
		'🧀',
		'🍖',
		'🍗',
		'🥩',
		'🥓',
		'🍔',
		'🍟',
		'🍕',
		'🌭',
		'🥪',
		'🌮',
		'🌯',
		'🥙',
		'🧆',
		'🥚',
		'🍳',
		'🥘',
		'🍲',
		'🥣',
		'🥗',
		'🍿',
		'🧈',
		'🧂',
		'🥫',
		'🍱',
		'🍘',
		'🍙',
		'🍚',
		'🍛',
		'🍜',
		'🍝',
		'🍠',
		'🍢',
		'🍣',
		'🍤',
		'🍥',
		'🥮',
		'🍡',
		'🥟',
		'🥠',
		'🥡',
		'🦀',
		'🦞',
		'🦐',
		'🦑',
		'🦪',
		'🍦',
		'🍧',
		'🍨',
		'🍩',
		'🍪',
		'🎂',
		'🍰',
		'🧁',
		'🥧',
		'🍫',
		'🍬',
		'🍭',
		'🍮',
		'🍯',
		'🍼',
		'🥛',
		'☕',
		'🍵',
		'🍶',
		'🍾',
		'🍷',
		'🍸',
		'🍹',
		'🍺',
		'🍻',
		'🥂',
		'🥃',
		'🥤',
		'🧃',
		'🧉',
		'🧊',
		'🥢',
		'🍽',
		'🍴',
		'🥄',
		'🔪',
		'🏺',
		'🌍',
		'🌎',
		'🌏',
		'🌐',
		'🗺',
		'🗾',
		'🧭',
		'🏔',
		'⛰',
		'🌋',
		'🗻',
		'🏕',
		'🏖',
		'🏜',
		'🏝',
		'🏞',
		'🏟',
		'🏛',
		'🏗',
		'🧱',
		'🏘',
		'🏚',
		'🏠',
		'🏡',
		'🏢',
		'🏣',
		'🏤',
		'🏥',
		'🏦',
		'🏨',
		'🏩',
		'🏪',
		'🏫',
		'🏬',
		'🏭',
		'🏯',
		'🏰',
		'💒',
		'🗼',
		'🗽',
		'⛪',
		'🕌',
		'🛕',
		'🕍',
		'⛩',
		'🕋',
		'⛲',
		'⛺',
		'🌁',
		'🌃',
		'🏙',
		'🌄',
		'🌅',
		'🌆',
		'🌇',
		'🌉',
		'♨',
		'🎠',
		'🎡',
		'🎢',
		'💈',
		'🎪',
		'🚂',
		'🚃',
		'🚄',
		'🚅',
		'🚆',
		'🚇',
		'🚈',
		'🚉',
		'🚊',
		'🚝',
		'🚞',
		'🚋',
		'🚌',
		'🚍',
		'🚎',
		'🚐',
		'🚑',
		'🚒',
		'🚓',
		'🚔',
		'🚕',
		'🚖',
		'🚗',
		'🚘',
		'🚙',
		'🚚',
		'🚛',
		'🚜',
		'🏎',
		'🏍',
		'🛵',
		'🦽',
		'🦼',
		'🛺',
		'🚲',
		'🛴',
		'🛹',
		'🚏',
		'🛣',
		'🛤',
		'🛢',
		'⛽',
		'🚨',
		'🚥',
		'🚦',
		'🛑',
		'🚧',
		'⚓',
		'⛵',
		'🛶',
		'🚤',
		'🛳',
		'⛴',
		'🛥',
		'🚢',
		'✈',
		'🛩',
		'🛫',
		'🛬',
		'🪂',
		'💺',
		'🚁',
		'🚟',
		'🚠',
		'🚡',
		'🛰',
		'🚀',
		'🛸',
		'🛎',
		'🧳',
		'⌛',
		'⏳',
		'⌚',
		'⏰',
		'⏱',
		'⏲',
		'🕰',
		'🕛',
		'🕧',
		'🕐',
		'🕜',
		'🕑',
		'🕝',
		'🕒',
		'🕞',
		'🕓',
		'🕟',
		'🕔',
		'🕠',
		'🕕',
		'🕡',
		'🕖',
		'🕢',
		'🕗',
		'🕣',
		'🕘',
		'🕤',
		'🕙',
		'🕥',
		'🕚',
		'🕦',
		'🌑',
		'🌒',
		'🌓',
		'🌔',
		'🌕',
		'🌖',
		'🌗',
		'🌘',
		'🌙',
		'🌚',
		'🌛',
		'🌜',
		'🌡',
		'☀',
		'🌝',
		'🌞',
		'🪐',
		'⭐',
		'🌟',
		'🌠',
		'🌌',
		'☁',
		'⛅',
		'⛈',
		'🌤',
		'🌥',
		'🌦',
		'🌧',
		'🌨',
		'🌩',
		'🌪',
		'🌫',
		'🌬',
		'🌀',
		'🌈',
		'🌂',
		'☂',
		'☔',
		'⛱',
		'⚡',
		'❄',
		'☃',
		'⛄',
		'☄',
		'🔥',
		'💧',
		'🌊',
		'🎃',
		'🎄',
		'🎆',
		'🎇',
		'🧨',
		'✨',
		'🎈',
		'🎉',
		'🎊',
		'🎋',
		'🎍',
		'🎎',
		'🎏',
		'🎐',
		'🎑',
		'🧧',
		'🎀',
		'🎁',
		'🎗',
		'🎟',
		'🎫',
		'🎖',
		'🏆',
		'🏅',
		'🥇',
		'🥈',
		'🥉',
		'⚽',
		'⚾',
		'🥎',
		'🏀',
		'🏐',
		'🏈',
		'🏉',
		'🎾',
		'🥏',
		'🎳',
		'🏏',
		'🏑',
		'🏒',
		'🥍',
		'🏓',
		'🏸',
		'🥊',
		'🥋',
		'🥅',
		'⛳',
		'⛸',
		'🎣',
		'🤿',
		'🎽',
		'🎿',
		'🛷',
		'🥌',
		'🎯',
		'🪀',
		'🪁',
		'🎱',
		'🔮',
		'🪄',
		'🧿',
		'🪬',
		'🎮',
		'🕹',
		'🎰',
		'🎲',
		'🧩',
		'🧸',
		'♠',
		'♥',
		'♦',
		'♣',
		'♟',
		'🃏',
		'🀄',
		'🎴',
		'🎭',
		'🖼',
		'🎨',
		'🧵',
		'🧶',
		'👓',
		'🕶',
		'🥽',
		'🥼',
		'🦺',
		'👔',
		'👕',
		'👖',
		'🧣',
		'🧤',
		'🧥',
		'🧦',
		'👗',
		'👘',
		'🥻',
		'🩱',
		'🩲',
		'🩳',
		'👙',
		'👚',
		'👛',
		'👜',
		'👝',
		'🛍',
		'🎒',
		'👞',
		'👟',
		'🥾',
		'🥿',
		'👠',
		'👡',
		'🩰',
		'👢',
		'👑',
		'👒',
		'🎩',
		'🎓',
		'🧢',
		'⛑',
		'📿',
		'💄',
		'💍',
		'💎',
		'🔇',
		'🔈',
		'🔉',
		'🔊',
		'📢',
		'📣',
		'📯',
		'🔔',
		'🔕',
		'🎼',
		'🎵',
		'🎶',
		'🎙',
		'🎚',
		'🎛',
		'🎤',
		'🎧',
		'📻',
		'🎷',
		'🎸',
		'🎹',
		'🎺',
		'🎻',
		'🪕',
		'🥁',
		'📱',
		'📲',
		'☎',
		'📞',
		'🔶',
		'🔷',
		'🔸',
		'🔹',
		'🔺',
		'🔻',
		'💠',
		'🔘',
		'🔳',
		'🔲',
		'🏁',
		'🚩',
		'🎌',
		'🏴',
		'🏳',
		'🏳️‍🌈',
		'🏳️‍⚧️',
		'🏴‍☠️',
	]
	const symbols = [
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		0,
		'@',
		'.',
		'-',
	]

	const colors = [
		'#5C54A5',
		'#9F59A2',
		'#CE6694',
		'#E97F86',
		'#F79F7E',
		'#FAC484',
		'#F7B58C',
		'#D39D84',
		'#A5DBC3',
		'#EFC47E',
	]

	let myNumber = 0
	for (let i = 0; i < 3; i++) {
		if (
			symbols.findIndex((el) => {
				let myEl =
					typeof el == 'string' ? el.toLowerCase() : el.toString()
				return myEl == newArray[i]
			}) > 0
		) {
			myNumber += symbols.findIndex((el) => {
				let myEl =
					typeof el == 'string' ? el.toLowerCase() : el.toString()
				return myEl == newArray[i]
			})
		}
	}
	myNumber += id
	let numberArray = [...myNumber.toString()]
	let myColors = [colors[[...numberArray][0]], colors[[...numberArray][1]]]

	return {
		emoji: emojis[myNumber],
		gradient: `linear-gradient(${myColors[0]}, ${myColors[1]})`,
	}
}
