<template>
	<Head>
		<title>Мои проекты</title>
	</Head>
	<!-- <transition>
		<PagePreloader v-if="preload" />
	</transition> -->
	<div>
		<section
			:style="
				user !== null && user?.background !== null
					? `margin-top: -6.4rem;`
					: ''
			"
			class="dashboard"
		>
			<div class="favorite-wrapper">
				<div
					:style="
						user !== null && user?.background !== null
							? `background: url(${user.background}) center center no-repeat; background-size: cover; padding-top: 6.3rem; padding-bottom: 6.3rem;`
							: ''
					"
					class="projects container"
				>
					<div class="projects_head">
						<h2 class="title">Избранные</h2>
					</div>
					<div v-if="favorites.length == 0" class="no-projects">
						Здесь будут отображаться проекты,<br />
						добавленные в избранное 🙂
					</div>
					<div v-else class="">
						<FavoritesList
							:favorites="favorites"
							@filter-array="filterArray"
						/>
					</div>
				</div>
			</div>
			<div class="projects container">
				<div class="projects_head">
					<h2 class="title">Все проекты</h2>
					<div class="sorting">
						<button
							:class="openedSort ? 'active' : ''"
							class="sort-button"
							type="button"
							@click="openedSort = !openedSort"
						>
							{{ activeSort?.name }}
						</button>
						<div
							:class="openedSort ? 'open' : ''"
							class="sort-container"
						>
							<div class="sort-wrapper">
								<button
									v-for="button of sortQuery"
									:key="button.slug"
									type="button"
									:class="
										button?.slug == activeSort?.slug
											? 'active'
											: ''
									"
									@click="setParams(button?.slug)"
								>
									{{ button.name }}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="projects_list">
					<a
						href="#"
						class="card add-project"
						@click.prevent="openModal"
					>
						<div class="plus">
							<img src="@/assets/img/icons/plus-big.svg" />
						</div>
						<p class="desc">Добавить проект</p>
					</a>
					<ProjectCard
						v-for="project of projects"
						:key="project"
						:project="project"
						:background="project.background"
						@filter="filterArray"
					/>
				</div>
			</div>
		</section>
		<AppFooterMain />
	</div>
	<transition>
		<ModalOverlay v-if="openedModal" @close-modal="closeModal" />
	</transition>
	<transition name="slide-fade">
		<ModalSide
			v-if="openedModal && currentModal !== null"
			:title="currentModalTitle"
			@close-modal="closeModal"
		>
			<component :is="currentModal" @close-modal="closeModal"></component>
		</ModalSide>
	</transition>
</template>

<script>
import ModalOverlay from '@/common/components/modals/ModalOverlay'
import FavoritesList from '@/modules/dashboard/FavoritesList'
import PagePreloader from '@/common/components/PagePreloader'
import ModalSide from '@/common/components/modals/ModalSide'
import ProjectCard from '@/modules/dashboard/ProjectCard'
import ProjectForm from '@/modules/dashboard/ProjectForm'
import AppFooterMain from '@/layouts/AppFooterMain'
import { defineAsyncComponent } from 'vue'
import { Head } from '@vueuse/head'
import { SET_ENTITY } from '@/store/mutation-types'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
	components: {
		FavoritesList,
		ModalOverlay,
		ModalSide,
		ProjectCard,
		ProjectForm,
		AppFooterMain,
		PagePreloader,
		Head,
	},
	data: () => {
		return {
			members: [1, 2, 3, 4, 5],
			modalStatus: false,
			moment: moment,
			preload: true,
			openedSort: false,
			sortQuery: [
				{
					name: 'По дате',
					slug: '',
				},
				{
					name: 'По алфавиту',
					slug: 'name',
				},
			],
			activeSort: null,
		}
	},
	computed: {
		...mapState('Projects', [
			'projects',
			'favorites',
			'openedModal',
			'activeModal',
		]),
		...mapState('Auth', ['user']),
		currentModalTitle() {
			return this.activeModal == 'settings'
				? 'Настройки проекта'
				: this.activeModal == 'newProject'
				? 'Новый проект'
				: this.activeModal == 'notify'
				? 'Уведомления'
				: this.activeModal == 'profile'
				? 'Настройки профиля'
				: this.activeModal == 'password'
				? 'Сменить пароль'
				: ''
		},
		currentModal() {
			return this.activeModal == 'settings' ||
				this.activeModal == 'newProject'
				? defineAsyncComponent(() =>
						import('@/modules/dashboard/ProjectForm.vue')
				  )
				: this.activeModal == 'notify'
				? defineAsyncComponent(() =>
						import('@/modules/profile/NotifyModal.vue')
				  )
				: this.activeModal == 'profile'
				? defineAsyncComponent(() =>
						import('@/modules/profile/EditForm.vue')
				  )
				: this.activeModal == 'password'
				? defineAsyncComponent(() =>
						import('@/modules/profile/ChangePasswordForm.vue')
				  )
				: ''
		},
	},
	mounted() {
		this.$store.dispatch('Projects/getOpenedTabs')
		this.$store.dispatch('Projects/getProjects').then(() => {
			setTimeout(() => {
				this.preload = false
			}, 1000)
		})
		let params = this.$route.query
		this.activeSort = params.sort_by
			? this.sortQuery.find((el) => el.slug == params.sort_by)
			: this.sortQuery[0]
	},
	methods: {
		openModal() {
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: true },
				{ root: true }
			)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Projects',
					entity: 'activeModal',
					value: 'newProject',
				},
				{ root: true }
			)
		},
		closeModal() {
			this.modalStatus = false
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: false },
				{ root: true }
			)
		},
		filterArray(id) {
			let current = this.projects.find((item) => item.id == id)
			current.is_favorite
				? this.$store.dispatch('Projects/deleteFromFavorites', id)
				: this.$store.dispatch('Projects/addToFavorites', id)
			this.$store.dispatch('Projects/getProjects')
		},
		setParams(slug) {
			this.activeSort = this.sortQuery.find((el) => el.slug == slug)
			let sortObj = slug !== '' ? { sort_by: slug } : {}
			this.$router.push({ query: sortObj })
			this.$store.dispatch('Projects/getProjects', sortObj)
			this.openedSort = false
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';

.dashboard {
	min-height: calc(100vh - 32rem);
}

.projects {
	padding-bottom: 3.6rem;
	background-color: $grey;
	min-height: 50vh;
}

.favorite-wrapper {
	background-color: $grey;

	.projects {
		padding-right: 0;
		padding-left: 0;
		background-color: $grey;
		min-height: auto;
	}

	.no-projects {
		font-size: 1.8rem;
		line-height: 2.5rem;
		color: $grey-text;
		padding: 10rem 2rem;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.projects_head {
		padding-right: 6rem;
		padding-left: 6rem;
	}
}

.projects_head {
	padding: 3.6rem 0 1.6rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $dark-grey;
}

.title {
	font-size: 1.6rem;
	line-height: 2rem;
	font-weight: 600;
}

.sorting {
	font-size: 1.4rem;
	line-height: 1.6rem;
	font-weight: 500;
	position: relative;
}

.projects_list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 2.8rem;
}

.card {
	overflow: hidden;
	border-radius: 1.6rem;
	border: 1px solid $grey-border;
	padding: 1.2rem;
	min-height: 25.6rem;
	display: flex;
	flex-direction: column;
	background: $white;
	position: relative;
	z-index: 1;
}

.add-project {
	justify-content: center;
	align-items: center;
	text-align: center;

	.desc {
		color: $dark-grey;
		font-size: 1.6rem;
		line-height: 2rem;
	}
}

.sort-button {
	color: inherit;
	padding-right: 1.6rem;
	position: relative;
	font: inherit;

	&::before {
		position: absolute;
		content: '';
		width: 1rem;
		height: 1rem;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		background: url('@/assets/img/icons/arrow-down.svg') center center
			no-repeat;
	}

	&.active::before {
		transform: translateY(-50%) rotate(180deg);
	}
}

.sort-wrapper {
	border-radius: 0.8rem;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	overflow: hidden;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);
}

.sort-container {
	position: absolute;
	top: calc(100% + 1rem);
	right: -2rem;
	width: 18rem;
	text-align: center;
	z-index: 2;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease-out;

	&.open {
		opacity: 1;
		pointer-events: all;
	}

	button {
		padding: 1rem;
		font-size: 1.5rem;
		line-height: 2rem;
		background-color: $white;

		&:not(:last-of-type) {
			border-bottom: 1px solid $grey;
		}

		&.active {
			color: $green;
		}

		&:hover {
			background-color: $grey;
		}

		&:active {
			background-color: $grey-hover2;
		}
	}

	&::before {
		position: absolute;
		content: '';
		width: 1rem;
		height: 1rem;
		right: 2rem;
		bottom: calc(100% - 0.5rem);
		background-color: $white;
		transform: rotate(45deg);
		z-index: 3;
	}
}

.slide-fade-enter-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(100%);
	opacity: 0.5;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
