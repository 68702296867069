<template>
	<div class="modal-side">
		<div class="modal-content">
			<slot />
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.modal-side {
	height: 100vh;
	width: 80rem;
	position: fixed;
	right: 0;
	top: 0;
	background-color: $grey;
	z-index: 25;
	//overflow: auto;
	display: flex;
	flex-direction: column;
	//min-height: 100%;

	&::-webkit-scrollbar {
		width: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		border-color: transparent;
		background-color: $dark-grey;
	}

	&::-webkit-scrollbar-thumb:hover {
		border-color: transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-width: 0;
	}

	&::-moz-scrollbar {
		width: 0.2rem;
	}
}

.modal-content {
	padding: 0;
}

.title {
	font-size: 1.8rem;
	line-height: 2.3rem;
	font-weight: 500;
	color: $black;
}
</style>
