<template>
	<div class="comment-card">
		<div class="comment-card-user">
			<div
				class="comment-card-image"
				:class="{ 'comment-card-image_empty': !comment.user.image }"
			>
				<img
					v-if="comment.user.image"
					:src="comment.user.image"
					:alt="comment.user.name"
				/>
				<img
					v-else
					src="@/assets/img/icons/avatar.svg"
					:alt="comment.user.name"
				/>
			</div>
			<div v-if="comment.user.name" class="comment-card-name">
				{{ comment.user.name }}
			</div>
			<div v-else class="comment-card-name">{{ comment.user.email }}</div>
		</div>
		<div class="comment-card-comment">{{ comment.body }}</div>
	</div>
</template>

<script>
export default {
	props: {
		comment: Object,
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';
.comment-card {
}

.comment-card-user {
	display: flex;
	align-items: center;
}

.comment-card-image {
	position: relative;
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	background-color: #333333;
	border: 0.2rem solid $green;

	img {
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		margin: 0 auto;
		object-fit: cover;
	}

	&_empty {
		background-color: #fff;
	}
}

.comment-card-name {
	margin-left: 0.4rem;
	font-size: 14px;
	font-weight: 500;
}

.comment-card-comment {
	width: 100%;
	padding: 1rem 1.2rem;
	margin-top: 0.8rem;
	border-radius: 0.8rem;
	background-color: #fff;
	color: #9d9d9d;
	font-size: 14px;
	line-height: 18px;
}
</style>
