<template>
	<label class="check-label">
		<input v-model="checkModel" type="checkbox" hidden />
		<div class="checkbox">
			<div class="check"></div>
		</div>
		<div class="check-label">
			<slot />
		</div>
	</label>
</template>

<script>
export default {
	props: {
		checked: Boolean,
	},
	emits: ['changeCheck'],
	data: () => {
		return {
			checkModel: false,
		}
	},
	watch: {
		checkModel(val) {
			this.$emit('changeCheck', val)
		},
	},
	created() {
		this.checkModel = this.checked
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.check-label {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #afafaf;
}

input[type='checkbox']:checked ~ .checkbox {
	background-color: $green;

	.check {
		left: calc(100% - 2.4rem);
	}
}

.checkbox {
	width: 4.2rem;
	min-width: 4.2rem;
	height: 2.6rem;
	border-radius: 10rem;
	background-color: $grey;
	position: relative;
	margin-right: 1.2rem;
	transition: 0.3s ease-out;
}

.check {
	position: absolute;
	top: 50%;
	left: 0.2rem;
	width: 2.2rem;
	min-width: 2.2rem;
	height: 2.2rem;
	border-radius: 100%;
	background-color: $white;
	transform: translateY(-50%);
	transition: 0.3s ease-out;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}
</style>
