<template>
	<div class="gantt" @mousedown="mouseDownHandler" @click="close">
		<div class="button-wrapper">
			<button
				type="button"
				class="scroll-button"
				@click="scroll('left')"
			></button>
		</div>
		<div class="timeline">
			<div
				ref="gantt"
				:style="`grid-template-columns: repeat(${days.length}, 1fr)`"
				class="flex"
			>
				<div
					v-for="year of years"
					v-show="ganttScale <= 25"
					:key="year"
					class="cell parent-row"
					style="grid-row: 1"
					:style="`grid-column: span ${year.days}`"
				>
					<span>{{ year.year }}</span>
				</div>
				<div
					v-for="month of months"
					v-show="ganttScale > 25"
					:key="month"
					class="cell parent-row"
					style="grid-row: 2"
					:style="`grid-column: span ${month.days}`"
				>
					<span>{{ month.month }}</span>
				</div>

				<div
					v-for="month of months"
					v-show="ganttScale < 50"
					:key="month.month"
					class="cell child-row"
					style="grid-row: 2"
					:class="{ current: ganttScale < 50 && month.isCurrent }"
					:style="`grid-column: span ${month.days}; ${pinColor}`"
				>
					<span
						>{{ ganttScale < 25 ? month.short : month.month }}
					</span>
				</div>

				<div
					v-for="day of days"
					v-show="ganttScale < 50"
					:ref="ganttScale < 50 ? 'cell' : ''"
					:key="day.day + Math.random()"
					class="cell"
					:class="{
						current: ganttScale < 50 && day.isCurrent,
						holiday: day.isHoliday,
						weekend: day.isWeekend && ganttScale > 0,
					}"
					style="grid-row: 3"
					:style="`width: ${cellWidth}`"
				>
					<span></span>
				</div>

				<div
					v-for="day of days"
					v-show="ganttScale >= 50"
					:ref="ganttScale >= 50 ? 'cell' : ''"
					:key="day.day + Math.random()"
					class="cell child-row"
					:class="{
						current: ganttScale >= 50 && day.isCurrent,
						holiday: day.isHoliday,
					}"
					style="grid-row: 3"
					:style="`width: ${cellWidth}; max-width: ${cellWidth}; ${pinColor}`"
				>
					<span
						>{{ ganttScale > 75 ? day.weekDay : '' }}
						{{ day.day }}</span
					>
				</div>

				<div
					v-for="(day, index) of days"
					:key="day.day + Math.random()"
					class="hidden-row"
					:class="{ holiday: day.isHoliday }"
					:style="`grid-row: 4 / span ${tasks.length}; grid-column: ${
						index + 1
					};`"
				></div>

				<div
					v-for="(task, index) of tasks"
					:key="index"
					:class="{
						done: task.status == 'done',
						task_active:
							activeCommentListWindow === task.id ||
							activeAddCommentWindow === task.id,
					}"
					class="task"
					:style="`grid-area: ${index + 4} / ${
						calculateTaskWidth(task).string
					}; background-color: ${
						colorScheme[single?.color || 'pink'][
							index < 6 ? index : index % 6
						]
					}; position: relative; max-width: ${
						calculateTaskWidth(task).duration == 1
							? cellWidth
							: 'auto'
					};`"
				>
					<div v-if="hasAccess" class="comment-pins">
						<div
							v-for="pin in pinGrouping(task.pins)"
							:id="task.period"
							:key="pin.period"
							:style="{ left: `${pinPosition(task, pin.date)}%` }"
							class="comment-pin"
							@click="openListComment(task.id, pin)"
						>
							<div class="comment-pin-cricle">
								<div class="comment-pin-content">
									<span>{{ pin.pins.length }}</span>
								</div>
							</div>
							<div class="comment-pin-line"></div>
						</div>
					</div>
					<CommentGroupingList
						v-if="activeCommentListWindow === task.id && hasAccess"
						class="list-comment comment"
						:class="[`list-comment_${commentPosition}`]"
						:comments="taskComments"
						:pin-grouping="activePin.pins"
						@add="addTaskComments"
					/>
					<AddComment
						v-if="activeAddCommentWindow === task.id && hasAccess"
						class="add-comment comment"
						:class="[`add-comment_${commentPosition}`]"
						@add="addTaskPinComments(task.id, $event)"
					/>
					<div
						class="resize-wrapper left"
						@mousedown="
							moveHandler(task, $event, resize, index + 4, 'left')
						"
					>
						<div class="resize-task">
							<svg
								style="transform: rotate(90deg)"
								width="10"
								height="11"
								viewBox="0 0 10 11"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.5 4.25L5 6.75L7.5 4.25"
									stroke="#ffffff"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
					</div>
					<div
						class="task-title"
						@mousedown="
							moveHandler(task, $event, translate, index + 4)
						"
					>
						{{ task.title }}
					</div>
					<div
						class="resize-wrapper"
						@mousedown="
							moveHandler(
								task,
								$event,
								resize,
								index + 4,
								'right'
							)
						"
					>
						<div class="resize-task">
							<svg
								style="transform: rotate(-90deg)"
								width="10"
								height="11"
								viewBox="0 0 10 11"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.5 4.25L5 6.75L7.5 4.25"
									stroke="#ffffff"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
					</div>
					<div
						v-if="hasAccess"
						class="task-add-comment"
						@click="openAddComment(task.id)"
					>
						<img
							src="@/assets/img/icons/plus-green.svg"
							alt="plus"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="button-wrapper right">
			<button
				type="button"
				class="scroll-button"
				@click="scroll('right')"
			></button>
		</div>
	</div>
</template>
<script>
import { getHolidays } from '@/common/helpers'
import colorScheme from '@/static/color-scheme'
import moment from 'moment'
import { mapState } from 'vuex'
import AddComment from '@/common/components/gantt/AddComment'
import CommentGroupingList from '@/common/components/gantt/CommentGroupingList'

export default {
	components: {
		AddComment,
		CommentGroupingList,
	},
	data: () => {
		return {
			drag: false,
			start: 0,
			end: 0,
			taskRect: null,
			gridRect: null,
			scheme: 'green',
			list: null,
			colorScheme,
			moment,
			pos: {
				left: 0,
				x: 0,
			},
			commentsPosition: {
				x: 0,
				y: 0,
			},
			pinId: null,
			activeCommentListWindow: null,
			activeAddCommentWindow: null,
			selectedPin: null,
			activePin: null,
			commentPosition: '',
		}
	},
	computed: {
		...mapState('Projects', ['single', 'ganttScale', 'users']),
		...mapState('Tasks', ['filteredTasks']),
		...mapState('Tasks', ['taskComments']),
		...mapState('Auth', ['user']),
		hasAccess() {
			if (this.user == null) return false
			//let currentUser = this.users.find((el) => el.user.id == this.user.id)
			return this.single?.role == 'owner' || this.single?.role == 'editor'
				? true
				: false
		},
		cellWidth() {
			let width = ''
			switch (this.ganttScale) {
				case 100:
					width = '8rem'
					break
				case 75:
					width = '4.5rem'
					break
				case 25:
					width = '1rem'
					break
				case 0:
					width = '.2rem'
					break
				default:
					width = '2.5rem'
					break
			}
			return width
		},
		pinColor() {
			return `--pin-color: ${
				this.colorScheme[this.single?.color || 'pink'][1]
			}`
			//return `--pin-color: red`
		},
		tasks() {
			let tasks = []
			if (this.filteredTasks) tasks = [...this.filteredTasks]
			return tasks
		},
		projectDates() {
			return {
				start: moment(this.single?.date_from).add(-30, 'days'),
				end: moment(this.single?.date_to).add(30, 'days'),
			}
		},
		holidays() {
			let array = []
			getHolidays(moment(this.projectDates.start).year(), array)
			return array
		},
		years() {
			let start = moment(this.projectDates.start)
			let end = moment(this.projectDates.end)
			let years = []
			for (
				let m = moment(start);
				m.isSameOrBefore(end);
				m.add(1, 'years')
			) {
				let year = {
					year: m.format('YYYY'),
					days: this.daysInYear(m.format('YYYY')),
				}
				years.push(year)
			}
			return years
		},
		months() {
			moment.locale('ru')
			let start = moment(this.projectDates.start)
			let end = moment(this.projectDates.end)
			let months = []
			for (
				let m = moment(start);
				m.isSameOrBefore(moment(end), 'month');
				m.add(1, 'months')
			) {
				let isCurrentMonth = m.isSame(new Date(), 'month')
				let month = {
					month: m.format('MMMM YYYY'),
					short: m.format('MMM'),
					isCurrent: isCurrentMonth,
					days: this.daysInMonth(m.format('MM'), m.format('YYYY')),
				}
				months.push(month)
			}
			return months
		},
		days() {
			moment.locale('ru')
			let start = moment(this.projectDates.start)
			let end = moment(this.projectDates.end)
			let holidaysArray = this.holidays
			let days = []
			for (
				let m = moment(start);
				m.isSameOrBefore(moment(end));
				m.add(1, 'days')
			) {
				let isCurrentDate = m.isSame(new Date(), 'day')
				let day = {
					weekDay: m.format('dd'),
					day: m.format('D'),
					isCurrent: isCurrentDate,
					isWeekend: m.day() === 0,
					isHoliday:
						m.day() == 0 ||
						m.day() == 6 ||
						holidaysArray.includes(m.format('DD MMMM YYYY')),
				}
				days.push(day)
			}

			return days
		},
		period() {
			let result = ''

			if (this.ganttScale < 50) {
				result = 'month'
			} else {
				result = 'day'
			}

			return result
		},
		pinGrouping() {
			return (pins) => {
				const result = []

				pins.forEach((pin) => {
					const period =
						this.period === 'day'
							? moment(pin.date).date()
							: moment(pin.date).month()

					const indexElem = result.findIndex(
						(item) => item.period === period
					)
					if (indexElem === -1) {
						result.push({
							period: period,
							date: pin.date,
							pins: [pin],
						})
					} else {
						result[indexElem].pins.push(pin)
					}
				})

				return result
			}
		},
		pinPosition() {
			return (task, pinDate) => {
				let from = moment(task.date_from).format('MM.DD.YYYY')
				let to = moment(task.date_to).format('MM.DD.YYYY')

				let duration = moment(to).diff(moment(from), 'days')
				let pinDuration = moment(pinDate).diff(moment(from), 'days')
				const result = (pinDuration / duration) * 100
				let positon = 0

				if (result > 0 && result <= 100) {
					positon = result
				} else if (result > 100) {
					positon = 100
				}

				return positon
			}
		},
	},
	watch: {
		cellWidth() {
			setTimeout(() => {
				this.scrollTo()
			}, 50)
		},
	},
	mounted() {
		setTimeout(() => {
			this.scrollTo()
		}, 500)
		// setTimeout(() => {
		// 	this.pinPosition(this.tasks[0])
		// }, 2000)
	},
	methods: {
		scroll(direction) {
			let gantt = document.querySelector('.gantt')
			direction == 'left'
				? (gantt.scrollLeft -= 400)
				: (gantt.scrollLeft += 400)
		},
		calculatePosition() {
			setTimeout(() => {
				const sidebar = document.querySelector('.gantt-list')
				const listComment = document.querySelector('.comment')

				const sidebarWidth =
					sidebar.offsetWidth + sidebar.getBoundingClientRect().left

				const sidebarNumber = sidebarWidth + sidebarWidth / 5
				const listCommentNumberLeft =
					listComment.getBoundingClientRect().left
				const listCommentNumberRight =
					listComment.getBoundingClientRect().right

				if (listCommentNumberLeft < sidebarNumber) {
					this.commentPosition = 'right'
				}
				if (listCommentNumberRight > window.innerWidth) {
					this.commentPosition = 'left'
				}
			}, 0)
		},
		openListComment(taskId, pin) {
			if (this.selectedPin !== pin.period)
				this.$store.state.Tasks.taskComments = []
			this.commentPosition = ''
			this.activePin = pin
			this.selectedPin = pin.period
			this.pinId = pin.period
			this.activeCommentListWindow = taskId
			this.activeAddCommentWindow = null

			this.calculatePosition()
		},
		openAddComment(id) {
			this.activeAddCommentWindow = id
			this.activeCommentListWindow = null
			this.commentPosition = ''
			this.calculatePosition()
		},
		addTaskPinComments(id, comment) {
			if (!comment) return
			this.$store
				.dispatch('Tasks/addTaskPins', {
					task_id: id,
					date: new Date(),
					comment: comment,
				})
				.then(() => {
					this.$store.dispatch('Tasks/getTasks', {
						projectId: this.$route.params.projectId,
					})
				})
			this.activeAddCommentWindow = true
		},
		addTaskComments(comment, pinId) {
			this.$store
				.dispatch('Tasks/addTaskComments', {
					pin_id: pinId,
					body: comment,
				})
				.then(() => {
					this.$store.dispatch('Tasks/getTasks', {
						projectId: this.$route.params.projectId,
					})

					this.$store.dispatch('Tasks/getTaskComments', pinId)
				})
		},
		closeListComments(e) {
			if (
				e.target.closest('.comment-pin') ||
				e.target.closest('.task-add-comment') ||
				e.target.closest('.comment') ||
				e.target.closest('.comment-grouping')
			)
				return

			this.activeCommentListWindow = false
			this.activeAddCommentWindow = false
			// this.selectedPin = null
			this.commentPosition = ''
			this.$store.state.Tasks.taskComments = []
		},
		close(e) {
			this.closeListComments(e)
		},
		mouseDownHandler(evt) {
			if (this.drag) return
			let gantt = document.querySelector('.gantt')
			gantt.style.cursor = 'grabbing'
			this.pos = {
				left: gantt.scrollLeft,
				x: evt.clientX,
			}
			document.addEventListener('mousemove', this.mouseMoveHandler)
			document.addEventListener('mouseup', this.mouseUpHandler)
		},
		mouseMoveHandler(evt) {
			let gantt = document.querySelector('.gantt')
			const dx = evt.clientX - this.pos.x
			gantt.scrollLeft = this.pos.left - dx
		},
		mouseUpHandler() {
			let gantt = document.querySelector('.gantt')
			document.removeEventListener('mousemove', this.mouseMoveHandler)
			document.removeEventListener('mouseup', this.mouseUpHandler)
			gantt.style.cursor = 'grab'
		},
		translate(evt, el) {
			if (!this.drag) return
			el.closest('.task').style.transform = `translate3d(${
				evt.clientX - this.start
			}px, 0, 0)`
		},
		resize(evt, el, direction) {
			if (!this.drag) return
			let move = evt.x
			if (direction == 'left') {
				el.closest('.task').style.transform = `translate3d(${
					evt.clientX - this.start
				}px, 0, 0)`
			}
			el.closest('.task').style.width =
				direction == 'left'
					? `${this.taskRect.width + this.start - move}px`
					: `${this.taskRect.width + move - this.start}px`
		},
		moveHandler(task, event, move, index, direction) {
			if (!this.hasAccess) return
			this.drag = true
			let target = event.target.closest('.task')
			this.start = event.clientX
			this.taskRect = target.getBoundingClientRect()
			this.gridRect = this.$refs.gantt.getBoundingClientRect()
			let background = target.style.backgroundColor
			target.style.visibility = 'hidden'
			const clone = target.cloneNode(true)
			clone.removeAttribute('style')
			clone.style.width = `${target.clientWidth}px`
			clone.style.height = `${target.clientHeight}px`
			clone.style.position = 'absolute'
			clone.style.top = `${this.taskRect.top - this.gridRect.top}px`
			clone.style.left = `${this.taskRect.left - this.gridRect.left}px`
			clone.style.backgroundColor = background
			this.$refs.gantt.append(clone)
			this.$refs.gantt.addEventListener('mousemove', (evt) => {
				move(evt, clone, direction)
			})
			clone.addEventListener('mouseup', (evt) => {
				this.drag = false
				this.end = evt.clientX
				let cellWidth = this.$refs.cell[0].getBoundingClientRect().width
				let cells = Math.round((this.end - this.start) / cellWidth)
				let gridData = this.calculateTaskWidth(task)
				if (direction == 'left') {
					task.date_from = moment(task.date_from).add(cells, 'days')
					target.style.gridArea = `${index} / ${
						gridData.diff + cells
					} / auto / ${gridData.diff + gridData.duration}`
				} else if (direction == 'right') {
					task.date_to = moment(task.date_to).add(cells, 'days')
					target.style.gridArea = `${index} / ${
						gridData.diff
					} / auto / ${gridData.diff + gridData.duration + cells}`
				} else {
					task.date_from = moment(task.date_from).add(cells, 'days')
					task.date_to = moment(task.date_to).add(cells, 'days')
					target.style.gridArea = `${index} / ${
						gridData.diff + cells
					} / auto / ${gridData.diff + gridData.duration + cells}`
				}
				target.style.visibility = 'visible'
				target.style.maxWidth =
					gridData.duration == 1 ? this.cellWidth : 'auto'
				evt.target.closest('.task').remove()
				if (cells !== 0) {
					let taskData = {
						taskId: task.id,
					}

					task.minute_count =
						moment().isoWeekdayCalc(
							task.date_from,
							task.date_to,
							[1, 2, 3, 4, 5, 6, 7]
						) * 1440

					taskData['data'] = { ...task }
					delete taskData['data'].id
					delete taskData['data'].nested
					delete taskData['data'].users
					this.$store
						.dispatch('Tasks/updateTask', taskData)
						.catch(() => {
							this.$toast.error(
								'Что-то пошло не так, попробуйте еще раз'
							)
						})
				}
			})
		},
		daysInYear(year) {
			let days =
				(year % 4 === 0 && year % 100 > 0) || year % 400 == 0
					? 366
					: 365
			if (year == moment(this.projectDates.start).format('YYYY')) {
				return (
					days -
					moment(this.projectDates.start).diff(moment(year), 'days')
				)
			}
			if (year == moment(this.projectDates.end).format('YYYY')) {
				return moment(this.projectDates.end).diff(moment(year), 'days')
			}
			return days
		},
		daysInMonth(month, year) {
			if (month == moment(this.projectDates.start).format('MM') && year == moment(this.projectDates.start).format('YYYY')) {
				return (
					new Date(year, month, 0).getDate() -
					moment(this.projectDates.start).format('D') +
					1
				)
			}
			if (month == moment(this.projectDates.end).format('MM') && year == moment(this.projectDates.end).format('YYYY')) {
				return moment(this.projectDates.end).format('D')
			}
			return new Date(year, month, 0).getDate()
		},
		calculateTaskWidth(task) {
			let from = moment(task.date_from).format('MM.DD.YYYY')
			let to = moment(task.date_to).format('MM.DD.YYYY')
			let diff =
				moment(from, 'MM.DD.YYYY').diff(
					moment(this.projectDates.start),
					'days'
				) + 2
			let duration =
				moment(to, 'MM.DD.YYYY').diff(
					moment(from, 'MM.DD.YYYY'),
					'days'
				) + 1
			return {
				diff: diff,
				duration: duration,
				string: `${diff} / auto / ${
					duration == 1 ? 'auto' : diff + duration
				}`,
			}
		},
		scrollTo() {
			let current = document.querySelector('.cell.current')
			if (current) {
				current.scrollIntoView({ inline: 'center', block: 'end' })
			}
		},
		getRandomInt(min, max) {
			min = Math.ceil(min)
			max = Math.floor(max)
			return Math.floor(Math.random() * (max - min)) + min
		},
	},
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/vars';

.gantt {
	max-width: 100%;
	overflow: hidden;
	//overflow-x: auto;
	min-height: 100%;
	margin-top: 2.2rem;
	scroll-behavior: smooth;
	cursor: grab;
}

.timeline {
	min-height: 100%;
	position: relative;
}

.button-wrapper {
	position: absolute;
	top: 5.5rem;
	left: 0;
	padding: 0.5rem 1.5rem;
	padding-bottom: 1.2rem;
	background-color: $white;
	z-index: 5;

	&.right {
		left: unset;
		right: 0;

		.scroll-button {
			transform: rotate(-90deg);
		}
	}
}

.scroll-button {
	background-color: $grey;
	border-radius: 0.6rem;
	padding: 1rem;
	transform: rotate(90deg);
	background: url('@/assets/img/icons/arrow-dark.svg') center center no-repeat,
		$grey;
	cursor: pointer;
}

.flex {
	display: grid;
	width: 100%;
	grid-gap: 1.5rem 0;
	position: relative;
	//overflow: hidden;
	user-select: none;
	transition: all 5s;
}

.cell {
	//min-width: 8.5rem;
	box-sizing: border-box;
	text-align: center;
	//padding: 0 1rem;
	text-transform: capitalize;
	position: sticky;
	left: auto;
	background-color: $white;
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 1s ease-out;

	b {
		font-weight: 700;
		color: $black;
	}

	&.weekend {
		&::before {
			content: '';
			position: absolute;
			right: 0;
			top: 0rem;
			height: 1000vh;
			width: 100%;
			border-right: 1px dotted $light-grey;
			pointer-events: none;
		}
	}
}

.child-row {
	top: 3rem;
	padding: 1rem 0;
	//width: max-content;
	z-index: 2;
	color: $dark-grey;
	font-size: 1.4rem;
	line-height: 1.8rem;
	white-space: nowrap;
	//margin-bottom: 1.3rem;
	&::before {
		content: '';
		position: absolute;
		right: 0;
		top: 100%;
		height: 1000vh;
		width: 100%;
		border-right: 1px solid rgba($light-grey, 0.6);
		pointer-events: none;
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 100%;
		height: 1px;
		width: 100%;
		background-color: $dark-grey;
		pointer-events: none;
	}

	span {
		position: relative;
		padding: 0.25rem 0;
		z-index: 3;
	}
}

.parent-row {
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	top: 0;
	color: $dark-grey;
	font-size: 1.2rem;
	line-height: 1.5rem;
	text-align: left;
	margin-bottom: -1.8rem;

	span {
		position: sticky;
		top: auto;
		left: 0;
		background-color: $white;
		padding: 0 1rem;
	}

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: 1px;
		width: 100%;
		background-color: $dark-grey;
	}
}

.current {
	b {
		color: $white;
	}

	span {
		border-radius: 0.6rem;
		color: $white;
		width: 100%;
		background-color: var(--pin-color);

		&::before {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			width: 2px;
			height: 1000vh;
			z-index: 3;
			background-color: var(--pin-color);
		}
	}
}

.holiday {
	color: $red;

	b {
		color: $red;
	}
}

.task {
	display: flex;
	cursor: move;
	background-color: violet;
	z-index: 2;
	border-radius: 10rem;
	//overflow: hidden;
	&:hover {
		.resize-task {
			opacity: 1;
		}
	}

	&.done {
		background-color: #e4e4e4 !important;
	}

	&_active {
		z-index: 99;
	}
}

.task-add-comment {
	position: absolute;
	right: -2.4rem;
	top: 0.2rem;
	cursor: pointer;
	opacity: 0;

	img {
		width: 2rem;
	}

	&:hover {
		opacity: 1;
	}
}

.task:hover {
	.task-add-comment {
		opacity: 1;
	}
}

.task-title {
	overflow: hidden;
	flex-grow: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: $white;
	font-size: 1.2rem;
	line-height: 1.5rem;
	padding: 0.6rem 0.4rem;
	max-width: calc(100% - 1.6rem);
}

.add-comment {
	right: 0;

	&_right {
		right: -26rem;
	}

	&_left {
		left: -30rem;
	}
}

.list-comment {
	right: 0;
	top: 30px;

	&_right {
		right: -32rem;
	}

	&_left {
		left: -30rem;
	}
}

.add-comment {
	top: 30px;
}

.comment-pins {
	width: calc(100% - 3.4rem);
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
}

.comment-pin {
	position: absolute;
	margin-top: -4.9rem;

	&:not(:first-child) {
		margin-left: 1rem;
	}
}

.comment-pin-cricle {
	position: relative;
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 100%;
	margin-top: 1rem;
	background-color: $green;
	border: 0.2rem solid #fff;
	cursor: pointer;
}

.comment-pin-content {
	position: absolute;
	left: 0;
	top: 0;
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(220, 255, 241, 1);
	color: $green;
	font-size: 1.4rem;
	font-weight: 500;
	border: 2px solid $green;
	z-index: 99;

	img {
		width: 100%;
		height: 100%;
		border-radius: 100%;
		object-fit: cover;
	}
}

.comment-pin-line {
	width: 0.6rem;
	height: 2.9rem;
	margin-top: -0.5rem;
	margin-left: 50%;
	transform: translateX(-50%);
	border-radius: 2rem;
	background-color: $green;
	border: 0.2rem solid #fff;
}

.resize-wrapper {
	display: flex;
	align-items: center;
	width: calc(3rem + 0.8rem);
	margin-right: -3rem;
	cursor: col-resize;

	&.left {
		margin-right: 0;
		margin-left: -3rem;
		justify-content: flex-end;
	}
}

.resize-task {
	cursor: col-resize;
	width: 0.8rem;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(252, 252, 252, 0.4);
	transition: 0.2s ease-out;
	opacity: 0;
}

.hidden-row {
	pointer-events: none;
	position: relative;
	z-index: 3;
	overflow: hidden;

	&.holiday {
		background-image: url('@/assets/img/task-back.svg');
	}
}
</style>
