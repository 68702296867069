<template>
	<div class="modal">
		<div class="modal-head">
			<p class="modal-title">Восстановление пароля</p>
		</div>
		<div class="modal-content">
			<p v-if="modalError" class="error-message">
				{{ errorMessage }}
			</p>
			<InputRow :label="'Email'" :class="emailClass" :info="emailInfo">
				<input
					v-model="email"
					class="input"
					type="text"
					placeholder="E-mail"
					@change="validateFields({ email: email }, validations)"
				/>
			</InputRow>
			<InputRow
				:label="'Новый пароль'"
				:class="passClass"
				:info="passInfo"
				:type="'password'"
				@toggle-input="inputToggle = !inputToggle"
			>
				<input
					v-model="password"
					class="input password"
					:type="inputToggle ? 'text' : 'password'"
					placeholder="Пароль"
					@change="
						validateFields(
							{
								password: password,
								password_confirmation: [password, confirm],
							},
							validations
						)
					"
				/>
			</InputRow>
			<InputRow
				:label="'Подтвердите пароль'"
				:class="confirmClass"
				:info="confirmInfo"
				:type="'password'"
				@toggle-input="inputConfirmToggle = !inputConfirmToggle"
			>
				<input
					v-model="confirm"
					class="input password"
					:type="inputConfirmToggle ? 'text' : 'password'"
					placeholder="Подтвердите пароль"
					@input="
						validateFields(
							{ password_confirmation: [password, confirm] },
							validations
						)
					"
				/>
			</InputRow>

			<button
				:class="!buttonCheck ? 'disabled' : loading ? 'loading' : ''"
				type="button"
				class="button green"
				@click="recovery"
			>
				Восстановить пароль
			</button>
		</div>
	</div>
</template>

<script>
import InputRow from '@/common/components/inputs/InputRow'
import validator from '@/common/mixins/validation/auth'

export default {
	name: 'RegPart',
	components: {
		InputRow,
	},
	mixins: [validator],
	data: () => {
		return {
			email: '',
			password: '',
			confirm: '',
			loading: false,
			modalError: false,
			inputToggle: false,
			inputConfirmToggle: false,
			errorMessage: '',
			validations: {
				email: {
					error: '',
					rules: ['required', 'email'],
				},
				password: {
					error: '',
					rules: ['required', 'password'],
				},
				password_confirmation: {
					error: '',
					rules: ['password_confirmation'],
				},
			},
		}
	},
	computed: {
		emailClass() {
			return this.validations.email.error !== ''
				? 'error'
				: this.email !== '' && this.validations.email.error == ''
				? 'success'
				: ''
		},
		emailInfo() {
			return this.validations.email.error == ''
				? 'Поле обязательно для заполнения'
				: this.validations.email.error
		},
		passClass() {
			return this.validations.password.error !== ''
				? 'error'
				: this.password !== '' && this.validations.password.error == ''
				? 'success'
				: ''
		},
		passInfo() {
			return this.validations.password.error == ''
				? 'Пароль должен быть длиннее 8 символов'
				: this.validations.password.error
		},
		confirmClass() {
			return this.password == '' || this.validations.password.error !== ''
				? 'disabled'
				: this.validations.password_confirmation.error !== ''
				? 'error'
				: this.confirm !== '' &&
				  this.validations.password_confirmation.error == ''
				? 'success'
				: ''
		},
		confirmInfo() {
			return this.validations.password_confirmation.error == ''
				? 'Пароли должны совпадать'
				: this.validations.password_confirmation.error
		},
		buttonCheck() {
			return (
				this.email !== '' &&
				this.validations.email.error == '' &&
				this.password !== '' &&
				this.validations.password.error == '' &&
				this.validations.password_confirmation.error == '' &&
				this.confirm !== ''
			)
		},
	},
	methods: {
		recovery() {
			if (
				!this.validateFields(
					{
						email: this.email,
						password: this.password,
						password_confirmation: [this.password, this.confirm],
					},
					this.validations
				)
			) {
				return
			}
			this.loading = true

			let body = {
				email: this.email,
				password: this.password,
				password_confirmation: this.confirm,
				token: this.$route.query.token,
			}

			console.log(body)
			this.$store
				.dispatch('Auth/recovery', body)
				.then(() => {
					this.modalError = false
					this.loading = false
					this.$toast.success(
						`Смена пароля прошла успешно! Войдите с новыми данными`
					)

					setTimeout(() => {
						this.$router.push('/')
					}, 3000)
				})
				.catch((error) => {
					this.errorMessage = error.response.data.errors
						? 'Пользователь с таким логином не найден'
						: 'Ссылка для восстановления пароля устарела'
					this.loading = false
					this.modalError = true
				})
		},
	},
}
</script>
