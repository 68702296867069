<template>
	<footer class="footer">
		<div class="footer-wrapper container">
			<div class="footer-content">
				<img
					src="@/assets/img/full-logo.svg"
					class="footer-logo"
					alt="Учетка"
				/>
				<p class="footer-text footer-copyright">
					ИП Шилов Сергей Алексеевич
				</p>
			</div>

			<div class="footer-content">
				<div class="footer-nav">
					<a href="#" class="footer-text footer-link">Телеграм ↗</a>
					<a
						href="mailto:info@uchetka.app"
						class="footer-text footer-link"
						>info@uchetka.app ↗</a
					>
					<a href="#" class="footer-text footer-link"
						>Реквизиты.pdf ↗</a
					>
					<button class="footer-button">Сообщить о проблеме</button>
				</div>
				<a href="privacy" class="footer-text footer-privacy-policy"
					>Политика конфиденциальности</a
				>
			</div>
		</div>
	</footer>
</template>

<script></script>

<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
.footer {
	padding: 5.8rem 0 5.9rem 0;
	background-color: $black;
	font-size: 1.4rem;
}

.footer-wrapper {
	display: flex;
	justify-content: space-between;
}

.footer-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.footer-logo {
	width: 14.6rem;
}

.footer-text {
	color: #fff;
	font-size: 1.4rem;
	opacity: 0.5;
}

.footer-copyright {
	margin-top: 5.9rem;
}

.footer-privacy-policy {
	line-height: 150%;
	border-bottom: 0.1rem solid #939393;
}

.footer-link {
	&:not(:first-child) {
		margin-left: 4.5rem;
	}
}

.footer-button {
	margin-left: 4.5rem;
	padding: 1.2rem 2rem;
	border-radius: 10rem;
	background-color: #3e3e3e;
	color: #fff;
	font-size: 1.6rem;
	font-weight: 500;
}
</style>
