<template>
	<div class="comments">
		<div v-if="!showComments" class="comment-grouping-wrapper">
			<div ref="list" class="comment-grouping">
				<CommentGroup
					v-for="(pin, index) in pinGrouping"
					:key="index"
					:pin="pin"
					@open-comments="openComments(pin)"
				/>
			</div>
		</div>

		<div v-if="showComments" class="comments-list-wrapper">
			<div ref="list" class="comments-list">
				<div
					v-for="commentt in taskComments"
					:key="commentt.id"
					class="comments-item"
				>
					<div class="comments-item-header">
						<div class="comments-item-user">
							<div class="comments-user-image">
								<img
									v-if="commentt.user.image"
									:src="commentt.user.image"
									alt="people"
								/>
								<img
									v-else
									src="@/assets/img/icons/avatar.svg"
									class="comments-user-image-empty"
									alt="people"
								/>
							</div>

							<div
								v-if="commentt.user.name"
								class="comments-item-name"
							>
								{{ commentt.user.name }}
							</div>
							<div v-else class="comments-item-name">
								{{ commentt.user.email }}
							</div>
						</div>

						<div class="comments-item-time">
							{{ calculateTime(commentt.created_at) }} назад
						</div>
					</div>
					<div
						class="comments-item-description"
						v-html="findAndReplaceLink(commentt.body)"
					></div>
				</div>
			</div>
			<div class="comment-create">
				<div class="comments-user-image">
					<img v-if="userAvatar" :src="userAvatar" alt="people" />

					<img
						v-else
						src="@/assets/img/icons/avatar.svg"
						class="comments-user-image-empty"
						alt="people"
					/>
				</div>
				<div class="comment-create-inp">
					<form class="comment-create-row" @submit.prevent="add">
						<input
							v-model="comment"
							class="input"
							type="text"
							placeholder="Ваш комментарий"
						/>
						<button class="comment-create-send">
							<img src="@/assets/img/icons/send.svg" alt="send" />
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import CommentGroup from './CommentGroup'

export default {
	components: {
		CommentGroup,
	},
	props: {
		pinGrouping: {
			type: Array,
			required: true,
		},
	},
	emits: ['add'],
	data() {
		return {
			comment: '',
			showComments: false,
			selectedPin: null,
		}
	},
	computed: {
		...mapState('Auth', ['user']),
		...mapState('Tasks', ['taskComments']),
		userAvatar() {
			return this.user?.image ? this.user.image : false
		},
		calculateTime() {
			return (date) => {
				const time = moment.duration(moment().diff(date))
				let result = '0 сек'
				if (time.days()) {
					result = `${time.days()} д`
				} else if (!time.days() && time.hours()) {
					result = `${time.hours()} ч`
				} else if (!time.hours() && time.minutes()) {
					result = `${time.minutes()} мин`
				} else if (!time.minutes() && time.seconds()) {
					result = `${time.seconds()} сек`
				}

				return result
			}
		},
		findAndReplaceLink() {
			return (inputText) => {
				let res = ``
				inputText.replace(
					/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
					(m, link, text) => {
						res += link
							? `<a href='${
									(link[0] === 'w' ? '//' : '') + link
							  }' target='_blank'>${link}</a>`
							: text
					}
				)

				return res
			}
		},
		dateFormat() {
			return (date) => {
				return `${moment(date).date()} ${moment(date).format(
					'MMMM'
				)} ${moment(date).year()}`
			}
		},
	},
	watch: {
		taskComments() {
			if (!this.taskComments.length) this.showComments = false
			setTimeout(() => {
				this.$refs.list.scrollTop = 9999
			}, 0)
		},
	},
	mounted() {
		this.$refs.list.scrollTop = 9999

		console.log(this.pinGrouping)
	},
	methods: {
		add() {
			this.$emit('add', this.comment, this.selectedPin)
			this.comment = ''
			setTimeout(() => {
				this.$refs.list.scrollTop = 9999
			}, 4000)
		},
		openComments(pin) {
			console.log(pin)
			this.selectedPin = pin.id
			this.showComments = true
			this.$store.dispatch('Tasks/getTaskComments', pin.id)
		},
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';

.comments {
	position: absolute;
	user-select: text;
	cursor: default;
	z-index: 9;
}

.comment-grouping-wrapper {
	width: 36.8rem;
	padding: 2rem;
	border-radius: 2rem;
	background-color: #fff;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);
}

.comment-grouping {
	max-height: 26rem;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #fff;
		margin-left: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $dark-grey;
		border: 3px solid #fff;
		border-radius: 1rem;

		&:hover {
			background-color: $dark-grey2;
		}
	}
}

.comment-group {
	padding: 1rem 1.2rem;
	border-radius: 1.2rem;
	transition: 0.3s;
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 2rem;
	}

	&:hover {
		background-color: $grey;
	}
}

.comment-group-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment-group-author {
	font-size: 1.4rem;
	font-weight: 500;
}

.comment-group-author-link {
	color: $green;
	text-decoration: underline;
}

.comment-group-date {
	color: $dark-grey;
	font-size: 1.4rem;
	font-weight: 500;
}

.comment-group-users {
	margin-top: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comment-group-users-list {
	display: flex;
}

.comment-group-user {
	width: 2.8rem;
	height: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background-color: #333333;
	border: 0.2rem solid #04b1f4;

	&:not(:first-child) {
		margin-left: 0.4rem;
	}

	img {
		width: calc(100% - 0.2rem);
		height: calc(100% - 0.2rem);
		border-radius: 100%;
		object-fit: cover;
	}
}

.comments-list-wrapper {
	width: 34.5rem;
	padding: 2rem 0rem 2rem 1rem;
	border-radius: 2rem;
	background-color: #fff;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);
}

.comments-list {
	max-height: 14.4rem;
	padding-right: 2rem;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #fff;
		margin-left: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $dark-grey;
		border: 3px solid #fff;
		border-radius: 1rem;

		&:hover {
			background-color: $dark-grey2;
		}
	}
}

.comments-item {
	&:not(:first-child) {
		margin-top: 2rem;
	}
}

.comments-item-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comments-item-user {
	display: flex;
	align-items: center;
}

.comments-user-image {
	position: relative;
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border: 0.2rem solid $green;

	img {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		object-fit: cover;
	}
}

.comment-grouping-wrapper .comments-user-image-empty {
	background-color: #fff;
}

.comments-user-image .comments-user-image-empty {
	width: 70%;
	height: 70%;
}

.comments-item-name {
	width: 18rem;
	margin-left: 1rem;
	font-size: 1.4rem;
	font-weight: 500;
	font-size: 1.4rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.comments-item-time {
	color: $dark-grey;
	font-size: 1.4rem;
	font-weight: 500;
}

.comments-item-description {
	margin-top: 0.2rem;
	margin-left: 3.4rem;
	overflow: hidden;
	word-wrap: break-word;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.8rem;

	&::v-deep(a) {
		text-decoration: underline;
	}
}

.comment-create {
	margin-top: 2rem;
	padding-right: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comment-create-row {
	position: relative;
}

.comment-create-send {
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
}

.comment-create-inp {
	width: calc(100% - 3.4rem);
}

.comment-create-inp input {
	padding-right: 32px;
}
</style>
