<template>
	<keep-alive>
		<component :is="currentModal" @change-modal="changeModal" />
	</keep-alive>
</template>

<script>
import { shallowRef } from 'vue'
import { SET_ENTITY } from '@/store/mutation-types'
import LoginPart from '@/modules/auth/LoginPart'
import RegPart from '@/modules/auth/RegPart'
import ResetPart from '@/modules/auth/ResetPart'
import RecoveryPart from '@/modules/auth/RecoveryPart'
import ModalOverlay from '@/common/components/modals/ModalOverlay'
import SuccessModal from '@/common/components/modals/SuccessModal'

export default {
	components: {
		SuccessModal,
		ModalOverlay,
	},
	emits: ['changeModal'],
	data: () => {
		return {
			state: 'login',
			currentModal: shallowRef(LoginPart),
		}
	},
	computed: {},
	watch: {
		state(newVal) {
			this.currentModal =
				newVal == 'register'
					? shallowRef(RegPart)
					: newVal == 'reset'
					? shallowRef(ResetPart)
					: newVal == 'recovery'
					? shallowRef(RecoveryPart)
					: shallowRef(LoginPart)
		},
	},
	created() {
		if (this.$route.name == 'Reset') this.state = 'recovery'
	},
	methods: {
		changeModal(name) {
			this.state = name
		},

		buttonEvent() {
			if (this.successReg) {
				this.state = 'login'
				this.$store.commit(
					SET_ENTITY,
					{
						module: 'Auth',
						entity: 'successReg',
						value: false,
					},
					{
						root: true,
					}
				)
			}
			if (this.successReset) {
				this.$store.commit(
					SET_ENTITY,
					{
						module: 'Auth',
						entity: 'successReset',
						value: false,
					},
					{
						root: true,
					}
				)
			}
		},
	},
}
</script>
