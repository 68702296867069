<template>
	<div class="input-title">
		<button class="emoji-button" type="button" @click="show = !show">
			{{ emoji }}
		</button>
		<EmojiPicker
			v-show="show"
			:native="true"
			:disable-skin-tones="true"
			:disable-sticky-group-names="true"
			:hide-search="true"
			@select="onSelectEmoji"
		/>
		<input
			:value="replaceEmoji(modelValue)"
			placeholder="Название проекта"
			type="text"
			name="project-title"
			@input="
				$emit(
					'update:modelValue',
					`${emoji} ${replaceEmoji($event.target.value)}`
				)
			"
		/>
	</div>
</template>

<script>
import '/node_modules/vue3-emoji-picker/dist/style.css'
import EmojiPicker from 'vue3-emoji-picker'

export default {
	components: {
		EmojiPicker,
	},
	props: {
		modelValue: {
			type: String,
			default: null,
		},
	},
	emits: ['update:modelValue'],
	data: () => {
		return {
			show: false,
			emoji: '🔥',
		}
	},

	mounted() {
		// let re =
		// 	/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
		//let re = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF])/g
		let re =
			/(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu
		if (this.modelValue.match(re)) this.emoji = this.modelValue.match(re)[0]
	},

	methods: {
		onSelectEmoji(emoji) {
			this.emoji = emoji.i
			this.show = false
			this.$emit(
				'update:modelValue',
				`${this.emoji} ${this.replaceEmoji(this.modelValue)}`
			)
		},
		replaceEmoji(str) {
			//let re = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})\s/gu
			// let re =
			// 	/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
			//let re = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF](?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}])[^\p{L}\p{N}\p{P}\p{Z}^$\n]\s/gu
			let re =
				/(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu
			return str.replace(re, '').trim()
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
.emoji-button {
	padding-right: 0.6rem;
}

.v3-footer {
	display: none !important;
}

.v3-emoji-picker {
	position: absolute;
	top: calc(100% + 1rem);
	z-index: 3;
}
.input-title {
	padding-right: 3.2rem;
	position: relative;
	margin-bottom: 2.4rem;
	display: flex;
	align-items: center;
	&::before {
		position: absolute;
		content: '';
		right: 0;
		top: 0.2rem;
		width: 2rem;
		height: 2rem;
		background: url('~@/assets/img/icons/edit.svg') center center no-repeat;
		background-size: 100%;
	}

	input {
		border: none;
		outline: none;
		color: $black;
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 2.2rem;
		width: 100%;

		&::placeholder {
			font: inherit;
			color: $dark-grey;
		}
	}
}
</style>
