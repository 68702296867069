<template>
	<div class="add-comment">
		<form class="add-comment-row" @submit.prevent="$emit('add', comment)">
			<input
				v-model="comment"
				class="add-comment-inp input"
				type="text"
				placeholder="Ваш комментарий"
			/>
			<button class="add-comment-send">
				<img src="@/assets/img/icons/send.svg" alt="send" />
			</button>
		</form>
	</div>
</template>

<script>
export default {
	emits: ['add'],
	data() {
		return {
			comment: '',
		}
	},
}
</script>

<style scoped lang="scss">
.add-comment {
	position: absolute;
	width: 26.1rem;
	padding: 0.8rem;
	border-radius: 12px;
	background-color: #fff;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);
	z-index: 9;
}

.add-comment-row {
	position: relative;
}

.add-comment-inp {
	padding-right: 32px;
}

.add-comment-send {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
}
</style>
