import axios from 'axios'
import App from './App.vue'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import store from '@/store/index'
import router from '@/router/index'
import { createHead } from '@vueuse/head'
import Toaster from '@meforma/vue-toaster'
import vue3GoogleLogin from 'vue3-google-login'
import { GOOGLE_CLIENT_ID } from '@/common/constants'

axios.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
}

const app = createApp(App)
const head = createHead()
app.use(head)
app.use(store)
app.use(router)
app.use(vue3GoogleLogin, { clientId: GOOGLE_CLIENT_ID })
app.use(VueAxios, axios)
app.use(Toaster)
app.mount('#app')
