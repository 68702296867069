<template>
	<div class="modal">
		<div class="modal-head">
			<p class="modal-title">Забыли пароль?</p>
			<button
				type="button"
				class="link-arrow"
				@click="$emit('changeModal', 'login')"
			>
				Я вспомнил
			</button>
		</div>
		<div class="modal-content">
			<p v-if="errorReset && modalError" class="error-message">
				Пользователь с таким e-mail не зарегистрирован
			</p>
			<InputRow :label="'Email'" :class="emailClass" :info="emailInfo">
				<input
					v-model="email"
					class="input"
					type="text"
					placeholder="E-mail"
					@input="errorReset ? (modalError = false) : ''"
					@change="validateFields({ email: email }, validations)"
				/>
			</InputRow>
			<button
				:class="!buttonCheck ? 'disabled' : loading ? 'loading' : ''"
				type="button"
				class="button green"
				@click="reset"
			>
				Отправить инструкцию на почту
			</button>
		</div>
	</div>
</template>

<script>
import InputRow from '@/common/components/inputs/InputRow'
import validator from '@/common/mixins/validation/auth'
import { mapState } from 'vuex'

export default {
	components: {
		InputRow,
	},
	mixins: [validator],
	emits: ['changeModal'],
	data: () => {
		return {
			email: '',
			loading: false,
			modalError: true,
			validations: {
				email: {
					error: '',
					rules: ['required', 'email'],
				},
			},
		}
	},
	computed: {
		...mapState('Auth', ['successReset', 'errorReset']),
		emailClass() {
			return this.validations.email.error !== ''
				? 'error'
				: this.email !== '' && this.validations.email.error == ''
				? 'success'
				: ''
		},
		emailInfo() {
			return this.validations.email.error == ''
				? 'Поле обязательно для заполнения'
				: this.validations.email.error
		},
		buttonCheck() {
			return this.email !== '' && this.validations.email.error == ''
		},
	},
	watch: {
		errorReset(newVal) {
			newVal
				? () => ((this.loading = false), (this.modalError = true))
				: ''
		},
		successReset(newVal) {
			newVal ? (this.loading = false) : ''
			this.$toast.success(
				`На Вашу почту отправлено письмо с инструкцией для сброса пароля`
			)
			this.$emit('changeModal', 'login')
		},
	},
	methods: {
		reset() {
			if (
				!this.validateFields(
					{
						email: this.email,
					},
					this.validations
				)
			) {
				return
			}

			this.loading = true

			let body = {
				email: this.email,
			}
			this.$store.dispatch('Auth/reset', body)
		},
	},
}
</script>

<style scoped>
.button:not(:last-of-type) {
	margin-bottom: 1.6rem;
}
</style>
