<template>
	<div class="modal">
		<div class="modal-head">
			<p class="modal-title">Регистрация</p>
			<button
				type="button"
				class="link-arrow"
				@click="$emit('changeModal', 'login')"
			>
				Войти в Учётку
			</button>
		</div>
		<div class="modal-content">
			<p v-if="errorReg && modalError" class="error-message">
				Этот e-mail уже зарегистрирован
			</p>
			<InputRow :label="'Email'" :class="emailClass" :info="emailInfo">
				<input
					v-model="email"
					class="input"
					type="text"
					placeholder="E-mail"
					@input="errorReg ? (modalError = false) : ''"
					@change="validateFields({ email: email }, validations)"
				/>
			</InputRow>
			<InputRow
				:label="'Пароль'"
				:class="passClass"
				:info="passInfo"
				:type="'password'"
				@toggle-input="inputToggle = !inputToggle"
			>
				<input
					v-model="password"
					class="input password"
					:type="inputToggle ? 'text' : 'password'"
					placeholder="Пароль"
					@change="
						validateFields(
							{
								password: password,
								password_confirmation: [password, confirm],
							},
							validations
						)
					"
				/>
			</InputRow>
			<InputRow
				:label="'Пароль'"
				:class="confirmClass"
				:info="confirmInfo"
				:type="'password'"
				@toggle-input="inputConfirmToggle = !inputConfirmToggle"
			>
				<input
					v-model="confirm"
					class="input password"
					:type="inputConfirmToggle ? 'text' : 'password'"
					placeholder="Подтвердите пароль"
					@input="
						validateFields(
							{ password_confirmation: [password, confirm] },
							validations
						)
					"
				/>
			</InputRow>

			<div class="policy">
				Нажимая кнопку «Зарегистрироваться», Вы соглашаетесь с условиями
				<a href="/privacy">политики конфиденциальности</a>.
			</div>

			<vue-recaptcha
				ref="recaptchaReg"
				style="display: none"
				size="invisible"
				:sitekey="siteKey"
				@verify="register"
				@expired="onCaptchaExpired"
				@error="onCaptchaError"
			/>

			<button
				:class="!buttonCheck ? 'disabled' : loading ? 'loading' : ''"
				type="button"
				class="button green"
				@click.prevent="validate"
			>
				Зарегистрироваться
			</button>
		</div>
		<LoginGoogle />
	</div>
</template>

<script>
import InputRow from '@/common/components/inputs/InputRow'
import validator from '@/common/mixins/validation/auth'
import LoginGoogle from '@/modules/auth/LoginGoogle'
import { RECAPTCHA_KEY } from '@/common/constants'
import { VueRecaptcha } from 'vue-recaptcha'
import { mapState } from 'vuex'

export default {
	name: 'RegPart',
	components: {
		InputRow,
		LoginGoogle,
		VueRecaptcha,
	},
	mixins: [validator],
	emits: ['changeModal'],
	data: () => {
		return {
			email: '',
			password: '',
			confirm: '',
			loading: false,
			modalError: true,
			inputToggle: false,
			inputConfirmToggle: false,
			siteKey: RECAPTCHA_KEY,
			validations: {
				email: {
					error: '',
					rules: ['required', 'email'],
				},
				password: {
					error: '',
					rules: ['required', 'password'],
				},
				password_confirmation: {
					error: '',
					rules: ['password_confirmation'],
				},
			},
		}
	},
	computed: {
		...mapState('Auth', ['errorReg', 'successReg']),
		emailClass() {
			return this.validations.email.error !== ''
				? 'error'
				: this.email !== '' && this.validations.email.error == ''
				? 'success'
				: ''
		},
		emailInfo() {
			return this.validations.email.error == ''
				? 'Поле обязательно для заполнения'
				: this.validations.email.error
		},
		passClass() {
			return this.validations.password.error !== ''
				? 'error'
				: this.password !== '' && this.validations.password.error == ''
				? 'success'
				: ''
		},
		passInfo() {
			return this.validations.password.error == ''
				? 'Пароль должен быть длиннее 8 символов'
				: this.validations.password.error
		},
		confirmClass() {
			return this.password == '' || this.validations.password.error !== ''
				? 'disabled'
				: this.validations.password_confirmation.error !== ''
				? 'error'
				: this.confirm !== '' &&
				  this.validations.password_confirmation.error == ''
				? 'success'
				: ''
		},
		confirmInfo() {
			return this.validations.password_confirmation.error == ''
				? 'Пароли должны совпадать'
				: this.validations.password_confirmation.error
		},
		buttonCheck() {
			return (
				this.email !== '' &&
				this.validations.email.error == '' &&
				this.password !== '' &&
				this.validations.password.error == '' &&
				this.validations.password_confirmation.error == '' &&
				this.confirm !== ''
			)
		},
	},
	watch: {
		errorReg(newVal) {
			newVal
				? () => ((this.loading = false), (this.modalError = true))
				: ''
		},
		successReg(newVal) {
			newVal ? (this.loading = false) : ''
			this.$toast.success(
				`Регистрация прошла успешно! Войдите с новыми данными`
			)
		},
	},
	methods: {
		validate() {
			if (
				!this.validateFields(
					{
						email: this.email,
						password: this.password,
						password_confirmation: [this.password, this.confirm],
					},
					this.validations
				)
			) {
				return
			}

			this.$refs.recaptchaReg.execute()
		},

		onCaptchaError() {
			console.log('captcha error')
		},

		onCaptchaExpired() {
			this.$refs.recaptchaReg.reset()
		},

		register(recaptchaToken) {
			this.loading = true

			let body = {
				email: this.email,
				password: this.password,
				password_confirmation: this.confirm,
				recaptcha_token: recaptchaToken,
			}
			this.$store.dispatch('Auth/register', body)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.policy {
	margin-bottom: 1.2rem;
	color: $dark-grey;
	font-size: 1.2rem;
	line-height: 1.6rem;

	a {
		color: $green;
	}
}
.button:not(:last-of-type) {
	margin-bottom: 1.6rem;
}
</style>
