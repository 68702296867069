/* eslint-disable */
export const CLIENT_ID = 2
export const CLIENT_SECRET = 'P9JvlLSgIB0cTOtf3l31rudTnHFajmjHgJFbegJI'
export const RECAPTCHA_KEY = '6LdQjXMjAAAAAL5EInuUfrWgu139FZ9jdkeabcxZ'
export const GOOGLE_CLIENT_ID =
	'963873091484-in9j4frr2fqjs3c2gncjkd6inclpemob.apps.googleusercontent.com'
export const EMAIL_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const globalApiUrl = process.env.VUE_APP_API_URL

export const SOCKET_URL = process.env.VUE_APP_SOCKET_URL

// Auth
export const AUTH_URL = `${globalApiUrl}oauth/token`
export const REG_URL = `${globalApiUrl}users/registration`
export const REFRESH_URL = `${globalApiUrl}oauth/token?refresh`
export const RESET_URL = `${globalApiUrl}users/password/forgot`
export const RECOVERY_URL = `${globalApiUrl}users/password/reset`
export const SOCIAL_LOGIN_URL = `${globalApiUrl}oauth/token?social`

// Projects
export const ADD_PROJECT_URL = `${globalApiUrl}projects`
export const ADD_USER_TO_PROJECT_URL = `${globalApiUrl}guest/projects/users/self`
export const GET_PROJECTS_URL = `${globalApiUrl}projects/dashboard`
export const GET_FAVORITES_URL = `${globalApiUrl}projects/favorites`
export const GET_SEARCH_URL = `${globalApiUrl}projects/list`
export const GET_SINGLE_PROJECT = `${globalApiUrl}projects/`
export const DELETE_USER_URL = `${globalApiUrl}projects/users/`
export const GET_PROJECT_BY_TOKEN = `${globalApiUrl}guest/projects`
export const NOTIFICATIONS_URL = `${globalApiUrl}user-notifications`
export function createFavoriteUrl(id) {
	return `${globalApiUrl}projects/${id}/favorites`
}
export function createUsersUrl(id) {
	return `${globalApiUrl}projects/${id}/users`
}
export function getProjectUsersUrl(id) {
	return `${globalApiUrl}projects/${id}/users`
}

// Tasks
export const CHANGE_TASK_ORDER_URL = `${globalApiUrl}tasks/order`
export const ADD_TASK_URL = `${globalApiUrl}tasks`
export const TASKS_BY_TOKEN_URL = `${globalApiUrl}guest/tasks`
export const ADD_TASK_COMMENTS_URL = `${globalApiUrl}tasks/pins/comments`
export const ADD_TASK_PINS_URL = `${globalApiUrl}tasks/pins`
export function getTasksUrl(id) {
	return `${globalApiUrl}projects/${id}/tasks`
}
export function getTasksForSelectUrl(id) {
	return `${globalApiUrl}projects/${id}/tasks/search`
}
export function updateTaskUrl(id) {
	return `${globalApiUrl}tasks/${id}`
}
export function duplicateTaskUrl(id) {
	return `${globalApiUrl}tasks/${id}/duplicate`
}
export function deleteTaskUrl(id) {
	return `${globalApiUrl}tasks/${id}`
}
export function getTaskCommentsUrl(id) {
	return `${globalApiUrl}tasks/pins/${id}/comments`
}
export function getPins(id) {
	return `${globalApiUrl}tasks/${id}/pins`
}

// User

export const GET_ME_URL = `${globalApiUrl}users/profiles`
export const UPDATE_PASSWORD_URL = `${globalApiUrl}users/profiles/passwords`
export const AVATAR_URL = `${globalApiUrl}users/profiles/image`
export const BACKGROUND_URL = `${globalApiUrl}users/profiles/background`
