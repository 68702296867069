<template>
	<div class="preload-container">
		<div class="squares">
			<div v-for="i in 4" :key="i" class="square"></div>
		</div>
	</div>
</template>

<script>
export default {
	created() {
		document.querySelector('body').style.overflow = 'hidden'
	},
	unmounted() {
		document.querySelector('body').style.overflow = 'unset'
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
$squares-size: 16rem;
$square-size: $squares-size / 2;
$animation: 1.5s linear infinite;

.preload-container {
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	background-color: $black;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
}

.squares {
	position: relative;
	display: inline-block;
	width: $square-size * 5;
	height: $square-size * 4;
	overflow: hidden;
	transform-origin: bottom left;
	animation: squares $animation;
}

.square {
	position: absolute;
	//   width: $square-size;
	height: 8rem;
	background-color: white;
	left: 0;
	top: 0;

	&:first-child {
		top: $square-size * 2;
		background-color: #04b1f4;
		width: $square-size * 3;
		left: $square-size;
		animation: square1 $animation;
	}
	&:nth-child(2) {
		left: $square-size * 4;
		top: $square-size * 3;
		animation: square2 $animation;
		background-color: #af4cfd;
		width: $square-size;
	}
	&:nth-child(3) {
		animation: square3 $animation;
		background-color: #ffd359;
		width: $square-size * 3;
	}
	&:last-child {
		top: $square-size;
		left: $square-size * 3;
		animation: square4 $animation;
		background-color: #00d47d;
		width: $square-size * 2;
	}
}

// @keyframes squares {
//   from {
//     transform: scale(1);
//   }
//   95% {
//     transform: scale(1);
//   }
//   to {
//     transform: scale(.5);
//   }

// }
@keyframes square1 {
	from {
		transform: translateX(-5rem);
	}
	40% {
		transform: translate(0);
	}
	to {
		transform: translate(0);
	}
}
@keyframes square2 {
	from {
		transform: translateX(5rem);
	}
	20% {
		transform: translate(0);
	}
	to {
		transform: translate(0);
	}
}
@keyframes square3 {
	from {
		transform: translateX(-10rem);
	}
	80% {
		transform: translate(0);
	}
	to {
		transform: translate(0);
	}
}
@keyframes square4 {
	from {
		transform: translateX(10rem);
	}
	60% {
		transform: translate(0);
	}
	to {
		transform: translate(0);
	}
}
</style>
