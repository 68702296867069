<template>
	<div class="task-list-page">
		<TaskList :gantt="gantt" />
		<transition>
			<ModalOverlay v-if="openModal" @close-modal="closeModal" />
		</transition>
		<transition name="slide-fade">
			<HalfModalSide v-if="openModal" class="task-modal-side">
				<div class="task-menu-wrapper">
					<TaskForm />
					<TaskPinMenu @close-modal="closeModal" />
				</div>
			</HalfModalSide>
		</transition>
		<div v-if="hasAccess" class="new-task-frame">
			<div class="container">
				<div
					class="new-task-frame__button"
					@click.prevent="addTaskModal"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8 3.33325V12.6666"
							stroke="#AEAEAE"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M3.33301 8H12.6663"
							stroke="#AEAEAE"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					Новая задача
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_ENTITY } from '@/store/mutation-types'
import { mapState } from 'vuex'
import TaskList from '@/modules/taskList/TaskListTable'
import TaskForm from '@/modules/taskList/TaskForm'
import TaskPinMenu from '@/modules/taskList/TaskPinMenu'
import ModalOverlay from '@/common/components/modals/ModalOverlay'
import HalfModalSide from '@/common/components/modals/HalfModalSide'

export default {
	name: 'TaskListPage',
	components: {
		TaskList,
		ModalOverlay,
		HalfModalSide,
		TaskForm,
		TaskPinMenu,
	},
	props: {
		gantt: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {}
	},
	computed: {
		...mapState('Auth', ['user']),
		...mapState('Tasks', ['openModal', 'modalTitle']),
		...mapState('Projects', ['users', 'single']),
		hasAccess() {
			if (this.user == null) return false
			//let currentUser = this.users.find((el) => el.user.id == this.user.id)

			return this.single?.role == 'owner' || this.single?.role == 'editor'
				? true
				: false
		},
	},
	methods: {
		addTaskModal() {
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'openModal',
					value: true,
				},
				{
					root: true,
				}
			)
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'modalTitle',
					value: 'Новая задача',
				},
				{
					root: true,
				}
			)
		},
		closeModal() {
			this.$store.commit(
				SET_ENTITY,
				{
					module: 'Tasks',
					entity: 'openModal',
					value: false,
				},
				{
					root: true,
				}
			)
		},
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';

.task-list-page {
	padding: 2rem 0;
}

.new-task-frame {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1.8rem 0;
	box-shadow: 0px -17px 25px -21px rgba(0, 0, 0, 0.1);
	background: $white;
	z-index: 5;
}

.new-task-frame__button {
	height: 100%;
	display: flex;
	align-items: center;
	color: $grey-text;
	cursor: pointer;

	svg {
		margin-right: 1rem;
	}
}

.task-modal-side {
	&::v-deep {
		width: 80rem;
	}
}

.task-menu-wrapper {
	display: flex;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.slide-fade-enter-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(100%);
	opacity: 0.5;
}
</style>
