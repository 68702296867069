<template>
	<div class="pin-menu">
		<div class="pin-menu-header">
			<div
				v-if="!showCommentCreation && !showPins"
				class="pin-menu-title"
			>
				Комментарии
			</div>
			<div
				v-if="showCommentCreation || showPins"
				class="pin-menu-title"
				@click="back"
			>
				<img
					src="@/assets/img/icons/arrow-left-green.svg"
					alt="arrow"
				/>
				Пин от
				<div class="pin-menu-title-link">{{ author }}</div>
			</div>
			<button class="close" type="button" @click="closeModal">
				<img src="@/assets/img/icons/plus.svg" />
			</button>
		</div>
		<div class="pin-menu-wrapper">
			<div
				v-if="!showCommentCreation && !showPins && !pinGrouping.length"
				class="pin-menu-empty"
			>
				<p class="pin-menu-empty-text">
					Здесь пока нет комментариев, вы можете оставить первый
				</p>
				<button
					type="button"
					class="button green"
					@click="showPins = true"
				>
					Новый пин
				</button>
			</div>
			<div v-if="showCommentCreation" class="pin-menu-content">
				<div class="pin-menu-comments">
					<CommentCard
						v-for="taskComment in taskComments"
						:key="taskComment.id"
						:comment="taskComment"
						class="pin-menu-comment"
					/>
				</div>
				<form class="pin-menu-form" @submit.prevent="createComment">
					<div class="pin-menu-form-row">
						<div class="pin-menu-form-comment">
							<p class="pin-menu-form-label">Ваш комментарий</p>
							<textarea
								v-model="comment"
								class="pin-menu-form-textarea"
								placeholder="Комментарий"
							></textarea>
						</div>
						<button class="pin-menu-form-send">
							<img src="@/assets/img/icons/send.svg" alt="send" />
						</button>
					</div>
				</form>
			</div>

			<div
				v-if="(showPins || pinGrouping.length) && !showCommentCreation"
				class="pin-menu-content"
			>
				<div class="pin-menu-comments">
					<CommentGroup
						v-for="(pin, index) in pinGrouping"
						:key="index"
						:pin="pin"
						class="pin-menu-group"
						@click="openPin(pin)"
					/>
				</div>
				<form class="pin-menu-form" @submit.prevent="createPin">
					<p class="pin-menu-form-label">Выберите дату пина</p>
					<InputDate
						v-model="pinForm.date"
						class="pin-menu-form-date"
					/>
					<div class="pin-menu-form-row">
						<div class="pin-menu-form-comment">
							<p class="pin-menu-form-label">Ваш комментарий</p>
							<textarea
								v-model="pinForm.comment"
								class="pin-menu-form-textarea"
								placeholder="Комментарий"
							></textarea>
						</div>
						<button class="pin-menu-form-send">
							<img src="@/assets/img/icons/send.svg" alt="send" />
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import CommentCard from './CommentCard.vue'
import InputDate from '@/common/components/inputs/InputDate'
import CommentGroup from '@/common/components/gantt/CommentGroup'

export default {
	components: {
		CommentCard,
		InputDate,
		CommentGroup,
	},
	emits: ['closeModal'],
	data() {
		return {
			showCommentCreation: false,
			showPins: false,
			author: '',
			pinForm: {
				date: null,
				comment: '',
			},
			comment: '',
			selectedPin: null,
		}
	},
	computed: {
		...mapState('Tasks', ['taskPins']),
		...mapState('Tasks', ['taskComments']),
		taskData() {
			return this.$store.state.Tasks.changedTask
		},
		pinGrouping() {
			const result = []

			this.taskPins.forEach((pin) => {
				const period = moment(pin.date).date()

				const indexElem = result.findIndex(
					(item) => item.period === period
				)
				if (indexElem === -1) {
					result.push(pin)
				} else {
					result[indexElem].pins.push(pin)
				}
			})

			return result
		},
	},
	mounted() {
		this.getPins()
	},
	methods: {
		closeModal() {
			this.$emit('closeModal', true)
			document.querySelector('body').style.overflow = 'unset'
		},
		back() {
			this.showCommentCreation = false
			this.showPins = false
		},
		createPin() {
			if (!this.pinForm.comment) return
			this.$store
				.dispatch('Tasks/addTaskPins', {
					task_id: this.taskData.id,
					date: this.pinForm.date ? this.pinForm.date : new Date(),
					comment: this.pinForm.comment,
				})
				.then(() => {
					this.pinForm.date = null
					this.pinForm.comment = ''
					this.getPins()
				})
		},
		getPins() {
			if (!this.taskData?.id) return
			this.$store.dispatch('Tasks/getPins', this.taskData.id)
		},
		openPin(pin) {
			this.showCommentCreation = true
			this.author = pin.user.name
			this.selectedPin = pin.id

			this.$store.dispatch('Tasks/getTaskComments', pin.id)
		},
		createComment() {
			this.$store
				.dispatch('Tasks/addTaskComments', {
					pin_id: this.selectedPin,
					body: this.comment,
				})
				.then(() => {
					this.$store.dispatch('Tasks/getTasks', {
						projectId: this.$route.params.projectId,
					})

					this.$store.dispatch(
						'Tasks/getTaskComments',
						this.selectedPin
					)
				})
			this.comment = ''
		},
	},
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';

.pin-menu {
	width: 50%;
	height: 100%;

	.pin-menu-header {
		width: 100%;
		padding: 1.7rem 0 1.4rem 3.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: $grey;
		color: #000;
	}

	.pin-menu-title {
		display: flex;
		align-items: center;
		font-size: 1.8rem;
		font-weight: 500;
		cursor: pointer;

		img {
			margin-right: 6px;
		}

		.pin-menu-title-link {
			margin-left: 5px;
			color: $green;
			text-decoration: underline;
		}
	}

	.close {
		width: 3.2rem;
		height: 3.2rem;
		margin-right: 1.2rem;
		transform: rotate(45deg);

		img {
			width: 100%;
			height: 100%;
		}
	}

	.pin-menu-wrapper {
		padding: 2.6rem 0;
		height: calc(100vh - 6rem);
	}

	.pin-menu-empty {
		padding: 0 3.6rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.pin-menu-empty-text {
		color: $dark-grey;
		text-align: center;
		font-size: 1.8rem;
	}

	.pin-menu-content {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.pin-menu-form {
		padding: 2.3rem 3.6rem 0 3.6rem;
	}

	.pin-menu-form-row {
		display: flex;
		align-items: flex-start;
	}

	.pin-menu-form-date {
		margin-top: 0.4rem;
		margin-bottom: 1.8rem;

		&::v-deep(input) {
			background-color: #fff !important;
		}
	}

	.pin-menu-form-comment {
		width: calc(100% - 3.2rem);
	}

	.pin-menu-form-label {
		color: $dark-grey;
		font-size: 1.4rem;
		font-weight: 500;
	}

	.pin-menu-form-textarea {
		width: 100%;
		height: 7.4rem;
		padding: 1rem 0 0 1.2rem;
		margin-top: 0.4rem;
		border-radius: 0.9rem;
		background-color: #fff;
		font-size: 1.4rem;
		border: none;

		&::placeholder {
			color: $dark-grey;
			font-size: 14px;
			font-weight: 400;
		}
	}

	.pin-menu-form-send {
		width: 1.6rem;
		margin-top: 3.2rem;
		margin-left: 1.6rem;
	}

	.pin-menu-comments {
		padding: 0 3.6rem;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0.6rem;
			margin-right: 2.2rem;
		}

		&::-webkit-scrollbar-thumb {
			border-color: transparent;
			background-color: $dark-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			border-color: transparent;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			border-width: 0;
		}

		&::-moz-scrollbar {
			width: 0.2rem;
		}
	}

	.pin-menu-group {
		background-color: #fff;
	}

	.pin-menu-comment {
		&:not(:first-child) {
			margin-top: 1.8rem;
		}
	}
}
</style>
