<template>
	<div class="modal-overlay" @click="closeModals">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'ModalOverlay',
	emits: ['closeModal'],
	mounted() {
		let body = document.querySelector('body')
		body.style.overflow = 'hidden'
	},
	methods: {
		closeModals() {
			let body = document.querySelector('body')
			body.style.overflow = 'unset'
			this.$emit('closeModal')
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 20;
}
</style>
