<template>
	<div class="dates" :class="datesColor">
		{{ from }} <span class="arrow">→</span> {{ to }}
	</div>
</template>

<script>
import { dateClass } from '@/common/helpers'
import moment from 'moment'
export default {
	props: {
		dateFrom: {
			type: String,
			default: null,
		},
		dateTo: {
			type: String,
			default: null,
		},
		color: {
			type: String,
			default: null,
		},
	},
	data: () => {
		return {
			format: 'DD.MM.YYYY',
		}
	},

	computed: {
		from() {
			return moment(this.dateFrom).format(this.format)
		},
		to() {
			return moment(this.dateTo).format(this.format)
		},
		datesColor() {
			return dateClass(this.from, this.to, this.format)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.dates {
	padding: 0.6rem 1rem;
	border-radius: 0.8rem;
	font-size: 1rem;
	line-height: 1.2rem;
	width: max-content;
	max-width: 100%;

	.arrow {
		margin: 0 0.5rem;
	}

	&.green {
		color: $green;
		background-color: $light-green;
	}
	&.yellow {
		color: $yellow;
		background-color: $light-yellow;
	}
	&.red {
		color: $red;
		background-color: $light-red;
	}
	&.grey {
		color: $dark-grey;
		background-color: $grey;
	}
}
</style>
