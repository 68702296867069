<template>
	<div
		class="gantt-container"
		@mousemove="moveHandler"
		@mouseup="drag = false"
	>
		<div ref="tasks" class="tasks gantt-list">
			<TaskList :gantt="true" />
		</div>
		<div
			ref="resizer"
			class="resizer"
			:class="chartWidth == 70 ? 'narrow' : ''"
			@click="resize"
		></div>
		<div :style="`width: ${chartWidth}%;`" class="chart">
			<div v-if="showPreload" class="preload"></div>
			<GanttChart />
		</div>
	</div>
</template>

<script>
import GanttChart from '@/modules/gantt/GanttChart'
import TaskList from '@/views/TaskListPage'
import { mapState } from 'vuex'
export default {
	components: {
		GanttChart,
		TaskList,
	},
	data: () => {
		return {
			drag: false,
			showPreload: false,
			chartWidth: 70,
		}
	},

	computed: {
		...mapState('Projects', ['ganttScale']),
	},

	watch: {
		showPreload(val) {
			if (val) {
				setTimeout(() => {
					this.showPreload = false
				}, 1000)
			}
		},
		ganttScale() {
			this.showPreload = true
		},
	},

	mounted() {
		this.showPreload = true
	},

	methods: {
		resize() {
			this.chartWidth = this.chartWidth == 70 ? 57 : 70
		},
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vars';
.gantt-container {
	display: flex;
	position: relative;
}
.gantt-list {
	margin-right: 5.7rem;
}
.tasks {
	min-width: 38rem;
}

.chart {
	flex-grow: 1;
	background-color: $white;
	position: relative;
	z-index: 1;
	max-width: 70%;
	width: 70%;
	transition: 0.2s ease-out;
	&::before {
		position: absolute;
		top: 9.55rem;
		content: '';
		right: 0;
		width: 1px;
		height: calc(100% - 7.6rem);
		background-color: $dark-grey;
	}

	.preload {
		position: absolute;
		width: calc(100% + 20px);
		height: 100%;
		top: 0;
		left: -10px;
		background-color: rgba(255, 255, 255, 0.95);
		backdrop-filter: blur(10px);
		z-index: 999;

		&::before {
			content: '';
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 5rem;
			height: 5rem;
			background: url('~@/assets/img/icons/loader-green.svg') center
				center no-repeat;
			background-size: 100%;
			animation: rotate 1s linear infinite;
		}
	}
}

@keyframes rotate {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.resizer {
	cursor: col-resize;
	min-width: 3rem;
	position: sticky;
	top: -4rem;
	left: 0;
	background-color: $white;
	height: 100vh;
	z-index: 2;

	&::before {
		position: absolute;
		top: 9.55rem;
		content: '';
		right: 0;
		width: 1px;
		height: calc(100% - 7.6rem);
		background-color: $dark-grey;
	}

	// &:hover{
	//     background: $light-grey;
	// }

	&::after {
		position: absolute;
		top: 50%;
		content: '';
		right: -1rem;
		transform: translateX(-50%);
		background-color: $grey;
		transition: 0.2s ease-out;
		border-radius: 0.6rem;
		padding: 1rem;
		transform: rotate(90deg);
		background: url('@/assets/img/icons/arrow-dark.svg') center center
				no-repeat,
			$grey;
	}

	&.narrow::after {
		transform: rotate(270deg);
	}
}
</style>
