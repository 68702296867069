<template>
	<div class="modal">
		<div class="modal-head">
			<p class="modal-title">Войти в Учётку</p>
			<button
				type="button"
				class="link-arrow"
				@click.prevent="$emit('changeModal', 'register')"
			>
				Регистрация
			</button>
		</div>
		<div class="modal-content">
			<p v-if="errorLogin && modalError" class="error-message">
				Неверный логин или пароль
			</p>
			<InputRow :label="'Email'" :class="emailClass" :info="emailInfo">
				<input
					v-model="email"
					class="input"
					type="text"
					placeholder="E-mail"
					@input="errorLogin ? (modalError = false) : ''"
					@change="validateFields({ email: email }, validations)"
				/>
			</InputRow>
			<InputRow
				:label="'Пароль'"
				:class="passClass"
				:info="passInfo"
				:type="'password'"
				@toggle-input="inputToggle = !inputToggle"
			>
				<input
					v-model="password"
					class="input password"
					:type="inputToggle ? 'text' : 'password'"
					placeholder="Пароль"
					@input="validateFields({ password: password }, validations)"
				/>
			</InputRow>

			<div class="policy">
				Нажимая кнопку «Войти», Вы соглашаетесь с условиями
				<a href="/privacy">политики конфиденциальности</a>.
			</div>

			<vue-recaptcha
				ref="recaptcha"
				style="display: none"
				size="invisible"
				:sitekey="siteKey"
				@verify="login"
				@expired="onCaptchaExpired"
				@error="onCaptchaError"
			/>

			<button
				:class="!buttonCheck ? 'disabled' : loading ? 'loading' : ''"
				type="button"
				class="button green"
				@click="validate"
			>
				Войти
			</button>
			<button
				type="button"
				class="button"
				@click="$emit('changeModal', 'reset')"
			>
				Забыли пароль?
			</button>
		</div>
		<LoginGoogle />
	</div>
</template>

<script>
import { CLIENT_ID, CLIENT_SECRET, RECAPTCHA_KEY } from '@/common/constants'
import InputRow from '@/common/components/inputs/InputRow'
import validator from '@/common/mixins/validation/auth'
import LoginGoogle from '@/modules/auth/LoginGoogle'
import { VueRecaptcha } from 'vue-recaptcha'
import { mapState } from 'vuex'

export default {
	components: {
		InputRow,
		LoginGoogle,
		VueRecaptcha,
	},
	mixins: [validator],
	emits: ['changeModal'],
	data: () => {
		return {
			email: '',
			password: '',
			modalError: true,
			inputToggle: false,
			loading: false,
			siteKey: RECAPTCHA_KEY,
			validations: {
				email: {
					error: '',
					rules: ['required', 'email'],
				},
				password: {
					error: '',
					rules: ['required', 'password'],
				},
			},
		}
	},
	computed: {
		...mapState('Auth', ['errorLogin']),
		emailClass() {
			return this.validations.email.error !== ''
				? 'error'
				: this.email !== '' && this.validations.email.error == ''
				? 'success'
				: ''
		},
		emailInfo() {
			return this.validations.email.error == ''
				? 'Поле обязательно для заполнения'
				: this.validations.email.error
		},
		passClass() {
			return this.validations.password.error !== ''
				? 'error'
				: this.password !== '' && this.validations.password.error == ''
				? 'success'
				: ''
		},
		passInfo() {
			return this.validations.password.error == ''
				? 'Пароль должен быть длиннее 8 символов'
				: this.validations.password.error
		},
		buttonCheck() {
			return (
				this.email !== '' &&
				this.validations.email.error == '' &&
				this.password !== '' &&
				this.validations.password.error == ''
			)
		},
	},
	watch: {
		errorLogin(newVal) {
			newVal ? ((this.loading = false), (this.modalError = true)) : ''
		},
	},
	methods: {
		validate() {
			if (
				!this.validateFields(
					{
						email: this.email,
						password: this.password,
					},
					this.validations
				)
			) {
				return
			}
			this.$refs.recaptcha.execute()
		},

		onCaptchaError() {
			console.log('captcha error')
		},

		onCaptchaExpired() {
			this.$refs.recaptcha.reset()
		},
		login(recaptchaToken) {
			this.loading = true

			let body = {
				grant_type: 'password',
				client_id: CLIENT_ID,
				client_secret: CLIENT_SECRET,
				username: this.email,
				password: this.password,
				scope: null,
				recaptcha_token: recaptchaToken,
			}
			this.$store.dispatch('Auth/login', body)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.policy {
	margin-bottom: 1.2rem;
	color: $dark-grey;
	font-size: 1.2rem;
	line-height: 1.6rem;

	a {
		color: $green;
	}
}
.button:not(:last-of-type) {
	margin-bottom: 1.6rem;
}
</style>
