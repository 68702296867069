<template>
	<div class="modal success">
		<div class="success-icon">
			<img src="@/assets/img/icons/check-circle.svg" class="icon" />
		</div>
		<div class="success-description">
			<p v-if="title" class="title">
				{{ title }}
			</p>
			<p v-if="description" class="desc">
				{{ description }}
			</p>
		</div>
		<button
			v-if="buttonText"
			type="button"
			class="button white"
			@click="$emit('buttonClick')"
		>
			{{ buttonText }}
		</button>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		buttonText: {
			type: String,
			default: null,
		},
	},
	emits: ['buttonClick'],
}
</script>

<style lang="scss" scoped>
.success {
	width: 32.8rem;
	box-shadow: 2.63415px 3.5122px 16.6829px rgba(0, 0, 0, 0.12);
	border-radius: 1.6rem;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 0;
	z-index: 4;
}

.success-icon {
	padding: 2.4rem 4rem 1.6rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon {
	width: 6.8rem;
	height: 6.8rem;
}

.success-description {
	padding-bottom: 1.6rem;
	border-bottom: 1px solid #e4e4e4;
}

.title,
.desc {
	padding: 0 4.5rem;
	text-align: center;
}

.title {
	padding-bottom: 0.8rem;
	font-size: 1.8rem;
	line-height: 2.2rem;
	font-weight: 500;
}

.desc {
	font-size: 1.4rem;
	line-height: 1.8rem;
}
</style>
