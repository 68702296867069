<template>
	<div class="google-login">
		<div class="title"><p>Или войти через</p></div>
		<div class="button-wrapper">
			<GoogleLogin :callback="callback">
				<button type="button" class="login-button">
					<img src="@/assets/img/icons/google-login.svg" />
				</button>
			</GoogleLogin>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		callback(response) {
			console.log(response)
			this.$store.dispatch('Auth/socialLogin', response.code)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
.google-login {
	padding: 0 3.6rem 3.6rem;
}
.title {
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 500;
	color: $dark-grey;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		padding: 0 1.6rem;
		background-color: $white;
		position: relative;
		z-index: 2;
	}

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: 1px;
		background: $dark-grey;
		left: 0;
		z-index: 1;
	}
}

.login-button {
	display: block;
	width: 5.8rem;
	height: 5.8rem;
	min-width: 5.8rem;

	img {
		max-width: 100%;
		width: 100%;
	}
}

.button-wrapper {
	margin-top: 2.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
