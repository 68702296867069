<template>
	<div class="main-layout">
		<AppHeaderMain />
		<slot />
		<AppFooterMain />
		<transition>
			<ModalOverlay v-if="openedModal" @close-modal="closeModal" />
		</transition>
		<transition name="slide-fade">
			<ModalSide
				v-if="openedModal && currentModal !== null"
				:title="currentModalTitle"
				@close-modal="closeModal"
			>
				<component
					:is="currentModal"
					@close-modal="closeModal"
				></component>
			</ModalSide>
		</transition>
	</div>
</template>

<script>
import ModalOverlay from '@/common/components/modals/ModalOverlay'
import ModalSide from '@/common/components/modals/ModalSide'
import ProjectForm from '@/modules/dashboard/ProjectForm'
import AppFooterMain from '@/layouts/AppFooterMain'
import AppHeaderMain from '@/layouts/AppHeaderMain'
import { SET_ENTITY } from '@/store/mutation-types'
import { defineAsyncComponent } from 'vue'
import { Head } from '@vueuse/head'
import { mapState } from 'vuex'

export default {
	components: {
		AppHeaderMain,
		AppFooterMain,
		ModalOverlay,
		ModalSide,
		ProjectForm,
		Head,
	},
	data: () => {
		return {
			modalStatus: false,
		}
	},
	computed: {
		...mapState('Projects', [
			'projects',
			'favorites',
			'openedModal',
			'activeModal',
		]),
		...mapState('Auth', ['user']),
		currentModalTitle() {
			return this.activeModal == 'settings'
				? 'Настройки проекта'
				: this.activeModal == 'newProject'
				? 'Новый проект'
				: this.activeModal == 'notify'
				? 'Уведомления'
				: this.activeModal == 'profile'
				? 'Настройки профиля'
				: this.activeModal == 'password'
				? 'Сменить пароль'
				: ''
		},
		currentModal() {
			return this.activeModal == 'settings' ||
				this.activeModal == 'newProject'
				? defineAsyncComponent(() =>
						import('@/modules/dashboard/ProjectForm.vue')
				  )
				: this.activeModal == 'notify'
				? defineAsyncComponent(() =>
						import('@/modules/profile/NotifyModal.vue')
				  )
				: this.activeModal == 'profile'
				? defineAsyncComponent(() =>
						import('@/modules/profile/EditForm.vue')
				  )
				: this.activeModal == 'password'
				? defineAsyncComponent(() =>
						import('@/modules/profile/ChangePasswordForm.vue')
				  )
				: ''
		},
	},
	methods: {
		closeModal() {
			this.modalStatus = false
			this.$store.commit(
				SET_ENTITY,
				{ module: 'Projects', entity: 'openedModal', value: false },
				{ root: true }
			)
		},
	},
}
</script>
