/* eslint-disable */

import {
	AUTH_URL,
	REG_URL,
	RESET_URL,
	RECOVERY_URL,
	REFRESH_URL,
	GET_ME_URL,
	CLIENT_ID,
	CLIENT_SECRET,
	SOCIAL_LOGIN_URL,
} from '@/common/constants'
import { getCookie } from '@/common/helpers'
import { SET_ENTITY } from '@/store/mutation-types'
import axios from 'axios'
import router from '@/router/index'

export default {
	namespaced: true,
	state: {
		isAuthenticated: false,
		successReg: false,
		errorReg: false,
		errorLogin: false,
		errorReset: false,
		successReset: false,
		user: null,
	},

	actions: {
		async reset({ commit }, credentials) {
			commit(
				SET_ENTITY,
				{ module: 'Auth', entity: 'errorReset', value: false },
				{ root: true }
			)
			await axios
				.post(RESET_URL, credentials)
				.then((response) => {
					commit(
						SET_ENTITY,
						{ module: 'Auth', entity: 'successReset', value: true },
						{ root: true }
					)
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{ module: 'Auth', entity: 'errorReset', value: true },
						{ root: true }
					)
				})
		},
		async login({ commit, dispatch }, credentials) {
			commit(
				SET_ENTITY,
				{ module: 'Auth', entity: 'errorLogin', value: false },
				{ root: true }
			)
			await axios
				.post(AUTH_URL, credentials)
				.then((response) => {
					dispatch('setAuth', response.data)
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{ module: 'Auth', entity: 'errorLogin', value: true },
						{ root: true }
					)
				})
		},

		async recovery({ commit }, credentials) {
			await axios.post(RECOVERY_URL, credentials)
		},

		async register({ commit }, credentials) {
			commit(
				SET_ENTITY,
				{ module: 'Auth', entity: 'errorReg', value: false },
				{ root: true }
			)
			commit(
				SET_ENTITY,
				{ module: 'Auth', entity: 'successReg', value: false },
				{ root: true }
			)
			await axios
				.post(REG_URL, credentials)
				.then((response) => {
					commit(
						SET_ENTITY,
						{ module: 'Auth', entity: 'successReg', value: true },
						{ root: true }
					)
				})
				.catch((error) => {
					commit(
						SET_ENTITY,
						{ module: 'Auth', entity: 'errorReg', value: true },
						{ root: true }
					)
				})
		},

		async init({ commit, dispatch }) {
			let accessData = getCookie('access_data')
			if (accessData == undefined || accessData == '""') {
				//router.push('/')
				localStorage.clear()
				return
			}
			accessData = JSON.parse(accessData)

			let body = {
				grant_type: 'refresh_token',
				client_id: CLIENT_ID,
				client_secret: CLIENT_SECRET,
				refresh_token: accessData.refresh_token,
				scope: '',
			}
			axios.defaults.headers.common[
				'Authorization'
			] = `${accessData.token_type} ${accessData.access_token}`
			localStorage.setItem('token', accessData.access_token)
			dispatch('setUser')
			// await axios.post(REFRESH_URL, body)
			// .then(response => {
			//     if (response.access_token){
			//         dispatch('setAuth', response)
			//     }
			// })
		},

		async setAuth({ commit, dispatch }, data) {
			document.cookie = `access_data=${JSON.stringify(data)}`
			axios.defaults.headers.common[
				'Authorization'
			] = `${data.token_type} ${data.access_token}`
			commit(
				SET_ENTITY,
				{ module: 'Auth', entity: 'isAuthenticated', value: true },
				{ root: true }
			)
			localStorage.setItem('token', data.access_token)
			dispatch('setUser')
			router.push('/dashboard')
		},

		async setUser({ commit }) {
			await axios
				.get(GET_ME_URL)
				.then((response) => {
					commit(
						SET_ENTITY,
						{
							module: 'Auth',
							entity: 'user',
							value: response.data.data,
						},
						{ root: true }
					)
					if (router.currentRoute.value.path == '/')
						router.push('/dashboard')
				})
				.catch(() => {
					document.cookie = `access_data=""`
					//router.push('/')
				})
		},

		async socialLogin({ dispatch }, code) {
			let data = {
				grant_type: 'social',
				client_id: CLIENT_ID,
				client_secret: CLIENT_SECRET,
				provider: 'google',
				code: code,
			}
			await axios.post(SOCIAL_LOGIN_URL, data).then((response) => {
				dispatch('setAuth', response.data)
			})
		},
	},
}
