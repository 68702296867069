<template>
	<transition>
		<PagePreloader v-if="preload" />
	</transition>
	<div class="default-layout">
		<div class="head">
			<a href="/" class="logo">
				<img src="@/assets/img/full-logo.svg" />
			</a>
		</div>
		<slot />
	</div>
	<AppFooterMain />
</template>

<script>
import PagePreloader from '@/common/components/PagePreloader'
import AppFooterMain from '@/layouts/AppFooterMain'

export default {
	components: {
		AppFooterMain,
		PagePreloader,
	},
	data() {
		return {
			preload: true,
		}
	},
	mounted() {
		setTimeout(() => {
			this.preload = false
		}, 1000)
	},
}
</script>

<style scoped>
.default-layout {
	min-height: 100vh;
	background: url('~@/assets/img/login-background.svg') center center;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: repeat-x;
	animation: bg 15s linear infinite;
	padding: 3.6rem 6rem;
	position: relative;
}

.logo {
	max-width: 12.8rem;
}

@keyframes bg {
	0% {
		background-position: 0%;
	}

	100% {
		background-position: -100vw;
	}
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
