<template>
	<AppLayout>
		<router-view />
	</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
export default {
	name: 'App',
	components: {
		AppLayout,
	},
	created() {
		this.$store.dispatch('Auth/init')
	},
	updated() {
		this.$store.dispatch('Auth/init')
	},
}
</script>

<style src="@/assets/scss/styles.scss" lang="scss"></style>
